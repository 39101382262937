import { Button, Typography, TextField, ClickAwayListener, LinearProgress } from "@material-ui/core";
import React from "react";
import SubscriptionDialogStyles from "./SubscriptionDialogStyle";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import { STRIPE_PK } from "../../../../config/config";
import { AppColors } from "../../../../utils/enums";

const CARD_OPTIONS = {
  style: {
    base: {
      "::placeholder": {
        color: AppColors["grey-dark"],
      },
    },
    invalid: {
      color: AppColors["red-light"],
    },
  },
};

interface DisplayMessage {
  message: string;
  display: boolean;
}

export interface PaymentDialogProps {
  userName: string;
  userEmail: string;
  clientSecret: string;
  displayPrice: number;
  handleClose: () => void;
  provisionUserSubscription: () => void;
}

let stripePromise: any;

if (STRIPE_PK) {
  stripePromise = loadStripe(STRIPE_PK);
}

const PaymentDialog: React.FunctionComponent<PaymentDialogProps> = ({
  handleClose,
  userName,
  userEmail,
  clientSecret,
  displayPrice,
  provisionUserSubscription,
}) => {
  const classes = SubscriptionDialogStyles();

  const [enableStripeCheckout, setEnableStripeCheckout] = React.useState(true);

  let stripe: Stripe | null, elements: StripeElements | null;

  const unMountStripeAndElements = () => {
    setEnableStripeCheckout(false);
    handleClose();
  };

  const CheckoutForm = () => {
    stripe = useStripe();
    elements = useElements();

    const [displayMessage, setDisplayMessage] = React.useState<DisplayMessage>({ message: "", display: false });

    const [displayLoader, setDisplayLoader] = React.useState(false);

    const handleSubmit = async (event: any) => {
      setDisplayMessage({ message: "", display: false });
      setDisplayLoader(true);
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);

      if (cardElement) {
        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: userName,
              email: userEmail,
            },
          },
        });

        if (error) {
          setDisplayLoader(false);
          setDisplayMessage({
            message: error?.message || "An error occurred while processing your request, please try again",
            display: true,
          });
        } else if (paymentIntent?.status === "succeeded") {
          setDisplayLoader(false);
          unMountStripeAndElements();
          provisionUserSubscription();
        } else {
          // TODO: Alternate Scenarios
          setDisplayLoader(false);
          setDisplayMessage({
            message: "An error occurred while processing your request, please try again",
            display: true,
          });
        }
      }
    };

    return (
      <>
        <Typography className={classes.dialogHeaderText} variant="body1">
          {`Subscribe to Daily Schema Pro for £${(displayPrice / 100).toFixed(2)} per month`}
        </Typography>

        <TextField
          className={classes.inputField}
          disabled
          id="name"
          label="Name"
          defaultValue={userName}
          variant="outlined"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className={classes.inputField}
          disabled
          id="email"
          label="Email"
          defaultValue={userEmail}
          variant="outlined"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Typography className={classes.cardDetails} variant="subtitle2">
          Card Details
        </Typography>

        <CardElement
          className={classes.cardElementInput}
          options={CARD_OPTIONS}
          onChange={(e) => {
            setDisplayMessage({
              ...displayMessage,
              message: e.error?.message || "",
              display: e.complete,
            });
          }}
        />
        {displayLoader && <LinearProgress className={classes.loader} />}

        <div className={classes.feedbackContainer}>
          {displayMessage.display && (
            <Typography variant="caption" className={classes.paymentErrorMessage}>
              {displayMessage.message}
            </Typography>
          )}
        </div>

        <div className={classes.actionButtons}>
          <div className={classes.actionButtonsRight}>
            <Button
              variant="contained"
              size="small"
              className={classes.cancelButton}
              onClick={unMountStripeAndElements}
            >
              Cancel
            </Button>
            <Button
              disabled={!stripe}
              variant="contained"
              color="primary"
              size="small"
              className={classes.saveButton}
              onClick={handleSubmit}
            >
              Subscribe
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <ClickAwayListener onClickAway={unMountStripeAndElements}>
      <div className={classes.paymentDialogWrap}>
        <Elements stripe={stripePromise}>{enableStripeCheckout && <CheckoutForm />}</Elements>
      </div>
    </ClickAwayListener>
  );
};

export default PaymentDialog;

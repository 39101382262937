import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { RootState } from "../../../../store/rootReducer";
import Quote, { QuoteProps } from "./Quote";

const mapStateToProps = (state: RootState): Partial<QuoteProps> => ({
  quote: state.Overview.quote,
});

const mapDispatchToProps = (dispatch: any) => ({});

const QuoteContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Quote as React.ComponentType<any>);

export default QuoteContainer;

import { put, takeLatest, call } from "redux-saga/effects";
import { verifyCustomerSubscriptionFailure, verifyCustomerSubscriptionSuccess } from "./actions";
import { VERIFY_CUSTOMER_SUBSCRIPTION } from "./actionTypes";
import { fetchData } from "../../../utils/httpUtils";
import { ApiResponseStatus, Endpoints, httpMethod } from "../../../utils/enums";
import { API_URL, STRIPE_PRO_PRICE_ID } from "../../../config/config";

export function* verifyCustomerSubscriptionSaga(action: any) {
  try {
    const priceId = STRIPE_PRO_PRICE_ID;

    const url = API_URL + Endpoints.verifyCustomerSubscription;

    const body = { priceId };

    const { status, data } = yield call(fetchData, url, httpMethod.post, body);

    if (status === ApiResponseStatus.success) {
      const clientSecret = data.clientSecret;
      const displayPrice = data.displayPrice;
      yield put(verifyCustomerSubscriptionSuccess(clientSecret, displayPrice));
    } else {
      yield put(verifyCustomerSubscriptionFailure());
    }
  } catch (error) {
    yield put(verifyCustomerSubscriptionFailure());
  }
}

export function* settingsWatcher() {
  yield takeLatest(VERIFY_CUSTOMER_SUBSCRIPTION, verifyCustomerSubscriptionSaga);
}

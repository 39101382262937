import { AnyAction } from "@reduxjs/toolkit";
import { Month, Target, TargetInfo } from "../../../utils/types";
import {
  GET_TARGETS,
  GET_TARGETS_FAILURE,
  GET_TARGETS_SUCCESS,
  CREATE_TARGET,
  CREATE_TARGET_FAILURE,
  CREATE_TARGET_SUCCESS,
  UPDATE_TARGET,
  UPDATE_TARGET_FAILURE,
  UPDATE_TARGET_SUCCESS,
  DELETE_TARGET,
  DELETE_TARGET_FAILURE,
  DELETE_TARGET_SUCCESS,
  GET_MONTH_BY_MONTH_AND_YEAR,
  GET_MONTH_BY_MONTH_AND_YEAR_SUCCESS,
  GET_MONTH_BY_MONTH_AND_YEAR_FAILURE,
} from "./actionTypes";

// GET_MONTH_BY_MONTH_AND_YEAR

export const getMonthByMonthAndYear = (monthId: number, year: number): AnyAction => {
  return {
    type: GET_MONTH_BY_MONTH_AND_YEAR,
    monthId,
    year,
  };
};

export const getMonthByMonthAndYearSuccess = (month: Month): AnyAction => {
  return {
    type: GET_MONTH_BY_MONTH_AND_YEAR_SUCCESS,
    month,
  };
};

export const getMonthByMonthAndYearFailure = (message?: string): AnyAction => {
  return {
    type: GET_MONTH_BY_MONTH_AND_YEAR_FAILURE,
    message: message || "Unable to retrieve data, please try again later",
  };
};

// GET_TARGETS

export const getTargets = (userTargetId: string): AnyAction => {
  return {
    type: GET_TARGETS,
    userTargetId,
  };
};

export const getTargetsSuccess = (target: Target): AnyAction => {
  return {
    type: GET_TARGETS_SUCCESS,
    target,
  };
};

export const getTargetsFailure = (message?: string): AnyAction => {
  return {
    type: GET_TARGETS_FAILURE,
    message: message || "Unable to retrieve data, please try again later",
  };
};

// CREATE_TARGET

export const createTarget = (target: TargetInfo): AnyAction => {
  return {
    type: CREATE_TARGET,
    target,
  };
};

export const createTargetSuccess = (targets: TargetInfo[]): AnyAction => {
  return {
    type: CREATE_TARGET_SUCCESS,
    targets,
    message: "Successfully created target!",
  };
};

export const createTargetFailure = (message?: string): AnyAction => {
  return {
    type: CREATE_TARGET_FAILURE,
    message: message || "Unable to create target, please try again later",
  };
};

// UPDATE_TARGET

export const updateTarget = (target: TargetInfo): AnyAction => {
  return {
    type: UPDATE_TARGET,
    target,
  };
};

export const updateTargetSuccess = (targets: TargetInfo[]): AnyAction => {
  return {
    type: UPDATE_TARGET_SUCCESS,
    targets,
    message: "Successfully updated target!",
  };
};

export const updateTargetFailure = (message?: string): AnyAction => {
  return {
    type: UPDATE_TARGET_FAILURE,
    message: message || "Unable to update target, please try again later",
  };
};

// DELETE_TARGET

export const deleteTarget = (targetId: string): AnyAction => {
  return {
    type: DELETE_TARGET,
    targetId,
  };
};

export const deleteTargetSuccess = (targets: TargetInfo[]): AnyAction => {
  return {
    type: DELETE_TARGET_SUCCESS,
    targets,
    message: "Successfully deleted target!",
  };
};

export const deleteTargetFailure = (message?: string): AnyAction => {
  return {
    type: DELETE_TARGET_FAILURE,
    message: message || "Unable to delete target, please try again later",
  };
};

import { Grid, Typography } from "@material-ui/core";
import React from "react";
import InAppSamplesStyles from "./InAppSamplesStyle";
import activityProgress from "../../../assets/images/screenshots/activityProgress.png";
import history from "../../../assets/images/screenshots/history.png";
import targetsList from "../../../assets/images/screenshots/targetsList.png";
import targetsPerformance from "../../../assets/images/screenshots/targetsPerformance.png";

export interface InAppSamplesProps {}

const InAppSamples: React.FunctionComponent<InAppSamplesProps> = () => {
  const classes = InAppSamplesStyles();

  return (
    <section className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={12} md={12}>
          <Typography className={classes.sectionDescription} variant="h6" gutterBottom>
            Daily Schema at a glance
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.descriptionContainer}>
            <Typography variant="h6" className={classes.title}>
              Monthly Targets
            </Typography>
            <Typography display="inline" variant="body1" className={classes.subTitle}>
              Create your monthly targets, specify how you would like to measure them and set repeat days to help when
              planning or auto scheduling recurring activities on any day.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={9}>
          <img className={classes.screenshot} src={targetsList} alt="targets list" />
        </Grid>

        <Grid item xs={12} md={3}>
          <div className={classes.descriptionContainer}>
            <Typography variant="h6" className={classes.title}>
              Daily Activities
            </Typography>
            <Typography display="inline" variant="body1" className={classes.subTitle}>
              Create daily activities in line with your monthly targets. Each activity can be directly linked to a
              monthly target and will contribute to your progress on the target. You also have the option to schedule
              one off tasks and visualise your progress via performance charts.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={9}>
          <img className={classes.screenshot} src={activityProgress} alt="activity progress" />
        </Grid>

        <Grid item xs={12} md={3}>
          <div className={classes.descriptionContainer}>
            <Typography variant="h6" className={classes.title}>
              Performance Tracker
            </Typography>
            <Typography display="inline" variant="body1" className={classes.subTitle}>
              Monitor your performance on all your targets at any given time. Your daily activities will affect your
              progress. Identify areas for improvement and see where you are currently excelling.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={9}>
          <img className={classes.screenshot} src={targetsPerformance} alt="targets performance" />
        </Grid>

        <Grid item xs={12} md={3}>
          <div className={classes.descriptionContainer}>
            <Typography variant="h6" className={classes.title}>
              The Bigger Picture
            </Typography>
            <Typography display="inline" variant="body1" className={classes.subTitle}>
              Visualise every month via an intuitive calendar with different view options to see your progress over time
              and identify patterns. Journal entries can also be added for any day to highlight any noteworthy events.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={9}>
          <img className={classes.screenshot} src={history} alt="history" />
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography className={classes.subTitle} variant="body1" gutterBottom>
            Enjoy these features and more for FREE when you sign in! Daily Schema is also mobile friendly and can be
            used on the go.
          </Typography>
        </Grid>
      </Grid>
    </section>
  );
};

export default InAppSamples;

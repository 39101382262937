import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { DAYS, MONTHS } from "../../../../utils/constants";
import TodayStyles from "./TodayStyle";

export interface TodayProps {
  date: Date;
}

const Today: React.FunctionComponent<TodayProps> = ({ date }) => {
  const classes = TodayStyles();

  const today = new Date(date);

  const displayDate = {
    day: DAYS[today.getDay()].value,
    date: today.getDate(),
    month: MONTHS[today.getMonth()].value,
    year: today.getFullYear(),
  };

  return (
    <Paper elevation={3} className={classes.datePaper}>
      <div className={classes.dateWrap}>
        <Typography className={classes.day} variant="h6">
          {displayDate.day}
        </Typography>
        <Typography className={classes.date} variant="h1">
          {displayDate.date}
        </Typography>
        <Typography className={classes.monthYear} variant="h6">
          {displayDate.month} {displayDate.year}
        </Typography>
      </div>
    </Paper>
  );
};

export default Today;

import { createStyles, makeStyles, Theme } from "@material-ui/core";

const QuoteStyles = makeStyles((theme: Theme) =>
  createStyles({
    quoteWrap: {
      position: "relative",
    },
    quoteBackground: {
      opacity: 0.1,
      position: "absolute",
      width: "100%",
      height: "auto",
    },
    quote: {
      position: "relative",
      textAlign: "center",
    },
    quoteHeader: {
      paddingBottom: theme.spacing(3),
    },
    quoteContent: {
      paddingBottom: theme.spacing(3),
    },
  })
);

export default QuoteStyles;

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { FontWeight } from "../../../utils/enums";

const FeaturesStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
      backgroundSize: "cover",
    },
    container: {
      margin: theme.spacing(5, 5),
      display: "flex",
    },
    featureDescription: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: theme.spacing(5),
      textAlign: "center",
    },
    title: {
      fontWeight: FontWeight.bold,
    },
    subTitle: {},
  })
);

export default FeaturesStyles;

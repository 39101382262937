import { Button, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import AppDialog from "../../../../components/AppDialog/AppDialog";
import { SUBSCRIPTION_PLANS } from "../../../../utils/constants";
import { Plans } from "../../../../utils/enums";
import { UserSubscription } from "../../../../utils/types";
import CancelDialog from "../../Dialogs/SubscriptionDialog/CancelDialog";
import PaymentDialog from "../../Dialogs/SubscriptionDialog/PaymentDialog";
import SubscriptionStyles from "./SubscriptionStyle";

export interface SubscriptionProps {
  userSubscription: UserSubscription;
  userName: string;
  userEmail: string;
  displayPrice: number;
  clientSecret: string;
  customerSubscriptionVerified: boolean;
  verifyCustomerSubscription: () => void;
  provisionUserSubscription: () => void;
  cancelUserSubscription: () => void;
  resetCustomerSubscriptionVerified: () => void;
}

enum DialogType {
  "subscribe" = "Subscribe",
  "cancel" = "Cancel",
}

const Subscription: React.FunctionComponent<SubscriptionProps> = ({
  userSubscription,
  userName,
  userEmail,
  displayPrice,
  clientSecret,
  customerSubscriptionVerified,
  verifyCustomerSubscription,
  resetCustomerSubscriptionVerified,
  provisionUserSubscription,
  cancelUserSubscription,
}) => {
  useEffect(() => {
    if (customerSubscriptionVerified) {
      setDialogType(DialogType.subscribe);
      setOpenDialog(true);
      resetCustomerSubscriptionVerified();
    }
  }, [customerSubscriptionVerified, resetCustomerSubscriptionVerified]);

  const classes = SubscriptionStyles();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogType, setDialogType] = React.useState<DialogType>();

  const plan = SUBSCRIPTION_PLANS[userSubscription.plan];

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpgrade = () => {
    verifyCustomerSubscription();
  };

  const handleCancel = () => {
    setDialogType(DialogType.cancel);
    setOpenDialog(true);
  };

  return (
    <div className={classes.subscriptionWrap}>
      <div className={classes.subscriptionHeader}>
        <Typography className={classes.subscriptionHeaderTitle} variant="h6">
          Subscription
        </Typography>

        {plan.value === Plans.free && (
          <div className={classes.subscriptionHeaderButtonContainer}>
            <Button
              color="primary"
              onClick={handleUpgrade}
              variant="contained"
              size="small"
              className={classes.subscriptionHeaderButton}
              disabled
            >
              Upgrade
            </Button>
          </div>
        )}

        {plan.value === Plans.pro && (
          <div className={classes.subscriptionHeaderButtonContainer}>
            <Button
              color={userSubscription.autoRenew ? "secondary" : "primary"}
              onClick={userSubscription.autoRenew ? handleCancel : handleUpgrade}
              variant="contained"
              size="small"
              className={classes.subscriptionHeaderButton}
            >
              {userSubscription.autoRenew ? "Cancel" : "Extend"}
            </Button>
          </div>
        )}
      </div>

      <div className={classes.yourPlan}>
        <Typography className={classes.subscriptionText} variant="body2">
          You are currently on the {plan.value} plan.
        </Typography>
      </div>

      <div className={classes.additionalInfo}>
        <Typography className={classes.additionalInfotext} variant="subtitle2">
          There are no upgrade options at this time.
        </Typography>
      </div>

      {/*
      {plan.value === Plans.free && (
        <div className={classes.additionalInfo}>
          <Typography className={classes.additionalInfotext} variant="subtitle2">
            Upgrade now to get unlimited activities and targets.
          </Typography>
        </div>
      )}

      {plan.value === Plans.pro && (
        <div className={classes.additionalInfo}>
          <Typography className={classes.additionalInfotext} variant="subtitle2">
            Your plan will {userSubscription.autoRenew ? "auto renew" : "expire"} on{" "}
            {new Date(userSubscription.expiry).toDateString()}.
          </Typography>
        </div>
      )}
      */}
      <AppDialog
        isOpen={openDialog}
        handleClose={handleDialogClose}
        dialogContent={
          dialogType === DialogType.subscribe ? (
            <PaymentDialog
              provisionUserSubscription={() => provisionUserSubscription()}
              userName={userName}
              userEmail={userEmail}
              clientSecret={clientSecret}
              displayPrice={displayPrice}
              handleClose={handleDialogClose}
            />
          ) : (
            <CancelDialog
              cancelUserSubscription={() => {
                cancelUserSubscription();
                handleDialogClose();
              }}
              handleClose={handleDialogClose}
            />
          )
        }
      />
    </div>
  );
};

export default Subscription;

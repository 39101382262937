export const GET_DAYS_BY_DATE_RANGE = "HISTORY/GET_DAYS_BY_DATE_RANGE";
export const GET_DAYS_BY_DATE_RANGE_SUCCESS = "HISTORY/GET_DAYS_BY_DATE_RANGE_SUCCESS";
export const GET_DAYS_BY_DATE_RANGE_FAILURE = "HISTORY/GET_DAYS_BY_DATE_RANGE_FAILURE";

export const CREATE_ACTIVITY = "HISTORY/CREATE_ACTIVITY";
export const CREATE_ACTIVITY_SUCCESS = "HISTORY/CREATE_ACTIVITY_SUCCESS";
export const CREATE_ACTIVITY_FAILURE = "HISTORY/CREATE_ACTIVITY_FAILURE";

export const UPDATE_ACTIVITY = "HISTORY/UPDATE_ACTIVITY";
export const UPDATE_ACTIVITY_SUCCESS = "HISTORY/UPDATE_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY_FAILURE = "HISTORY/UPDATE_ACTIVITY_FAILURE";

export const DELETE_ACTIVITY = "HISTORY/DELETE_ACTIVITY";
export const DELETE_ACTIVITY_SUCCESS = "HISTORY/DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAILURE = "HISTORY/DELETE_ACTIVITY_FAILURE";

export const UPDATE_DAY_SUMMARY = "HISTORY/UPDATE_DAY_SUMMARY";
export const UPDATE_DAY_SUMMARY_SUCCESS = "HISTORY/UPDATE_DAY_SUMMARY_SUCCESS";
export const UPDATE_DAY_SUMMARY_FAILURE = "HISTORY/UPDATE_DAY_SUMMARY_FAILURE";

export const RESET_DAY_HAS_CHANGED = "HISTORY/RESET_DAY_HAS_CHANGED";

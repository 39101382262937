import { Typography } from "@material-ui/core";
import React from "react";
import ComingSoonStyles from "./ComingSoonStyle";
import underConstruction from "../../../../assets/images/illustrations/construction.svg";

export interface ComingSoonProps {}

const ComingSoon: React.FunctionComponent<ComingSoonProps> = () => {
  const classes = ComingSoonStyles();

  return (
    <div className={classes.comingSoonWrap}>
      <div className={classes.comingSoonHeader}>
        <Typography className={classes.comingSoonHeaderTitle} variant="h6">
          Coming Soon!
        </Typography>
      </div>

      <div className={classes.comingSoonContent}>
        <Typography className={classes.comingSoonText} variant="body1">
          This feature is currently under construction!
        </Typography>
        <img className={classes.comingSoonImage} alt="under construction" src={underConstruction} />
        <Typography className={classes.comingSoonText} variant="body1">
          We will notify you once it is ready 🙂
        </Typography>
      </div>
    </div>
  );
};

export default ComingSoon;

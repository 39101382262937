import { createStyles, makeStyles, Theme } from "@material-ui/core";

const AppDatePickerStyles = makeStyles((theme: Theme) =>
  createStyles({
    datePicker: {
      width: "100%",
    },
  })
);

export default AppDatePickerStyles;

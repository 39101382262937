import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { updateUserNotifications } from "../../../../store/areas/home/actions";
import { RootState } from "../../../../store/rootReducer";
import { UserNotification } from "../../../../utils/types";
import Notifications, { NotificationsProps } from "./Notifications";

const mapStateToProps = (state: RootState): Partial<NotificationsProps> => ({
  userNotification: state.Home.user.notification,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateUserNotifications: (notification: UserNotification) => dispatch(updateUserNotifications(notification)),
});

const NotificationsContainer = compose(connect(mapStateToProps, mapDispatchToProps))(
  Notifications as React.ComponentType<any>
);

export default NotificationsContainer;

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors, FontWeight } from "../../../../utils/enums";
import welcomeImagePlanned from "../../../../assets/images/illustrations/welcome-planned.svg";
import welcomeImageUnPlanned from "../../../../assets/images/illustrations/welcome-unplanned.svg";

const WelcomeStyles = makeStyles((theme: Theme) =>
  createStyles({
    welcome: {
      display: "flex",
      alignItems: "center",
      height: "100%",
      width: "100%",
      padding: theme.spacing(2),
    },
    welcomeLeft: {
      flex: 1,
    },
    welcomeLeftHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    welcomeLeftMessage: {
      paddingBottom: theme.spacing(2),
    },
    welcomeLeftLineUpMessage: {
      fontWeight: FontWeight.bold,
      color: AppColors.main,
    },
    welcomeLeftButton: {
      fontWeight: FontWeight.bold,
    },
    welcomeRightPlanned: {
      flex: 1,
      height: "100%",
      width: "100%",
      margin: theme.spacing(1),
      backgroundImage: `url(${welcomeImagePlanned})`,
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    welcomeRightUnPlanned: {
      flex: 1,
      height: "100%",
      width: "100%",
      margin: theme.spacing(1),
      backgroundImage: `url(${welcomeImageUnPlanned})`,
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
  })
);

export default WelcomeStyles;

import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { logoutUser } from "../../store/areas/home/actions";
import { RootState } from "../../store/rootReducer";
import Home, { HomeProps } from "./Home";

const mapStateToProps = (state: RootState): Partial<HomeProps> => ({
  user: state.Home.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  logoutUser: () => dispatch(logoutUser()),
});

const HomeContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Home as React.ComponentType<any>);

export default HomeContainer;

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors } from "../../../../utils/enums";
import backgroundImage from "../../../../assets/images/background/dark.jpg";

const TodayStyles = makeStyles((theme: Theme) =>
  createStyles({
    datePaper: {
      display: "flex",
      width: "100%",
      height: "100%",
      alignItems: "center",
      backgroundColor: AppColors["background-dark"],
      opacity: 0.9,
      backgroundSize: "center",
      backgroundRepeat: "repeat",
      backgroundPosition: "cover",
      backgroundImage: `url(${backgroundImage})`,
    },
    dateWrap: {
      flex: 1,
      textAlign: "center",
      color: theme.palette.common.white,
    },
    day: {
      color: theme.palette.common.white,
    },
    date: {
      color: theme.palette.common.white,
    },
    monthYear: {
      color: theme.palette.common.white,
    },
  })
);

export default TodayStyles;

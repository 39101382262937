import { User, UserSubscription } from "../types";

export const mapUser = (user: any) => {
  const mappedUser: User = {
    id: user._id,
    name: user.name,
    email: user.email,
    deviceId: user.deviceId,
    partners: user.partners,
    targets: user.targets,
    rituals: user.rituals,
    imageUrl: user.imageUrl,
    subscription: {
      autoRenew: user.subscription.autoRenew,
      stripeCustomerId: user.subscription.stripeCustomerId,
      stripeSubscriptionId: user.subscription.stripeSubscriptionId,
      plan: user.subscription.plan,
      expiry: user.subscription.expiry,
    },
    notification: {
      articles: user.notification.articles,
      challenges: user.notification.challenges,
    },
    reminder: {
      day: {
        plan: user.reminder.day.plan,
        planTime: user.reminder.day.planTime,
        review: user.reminder.day.review,
        reviewTime: user.reminder.day.reviewTime,
      },
      month: {
        plan: user.reminder.month.plan,
        planTime: user.reminder.month.planTime,
        review: user.reminder.month.review,
        reviewTime: user.reminder.month.reviewTime,
      },
    },
  };
  return mappedUser;
};

export const mapUserSubscription = (userSubscription: any) => {
  const mappedUserSubscription: UserSubscription = {
    autoRenew: userSubscription.autoRenew,
    stripeCustomerId: userSubscription.stripeCustomerId,
    stripeSubscriptionId: userSubscription.stripeSubscriptionId,
    plan: userSubscription.plan,
    expiry: userSubscription.expiry,
  };
  return mappedUserSubscription;
};

import { createStyles, makeStyles, Theme } from "@material-ui/core";

const SnackbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.common.white,
    },
  })
);

export default SnackbarStyles;

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import opinion from "../../../assets/images/illustrations/opinion.svg";

const FeedbackStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    container: {
      display: "flex",
    },
    headerItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    gridItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 450,
    },
    feedbackHeader: {
      display: "flex",
      textAlign: "center",
    },
    feedbackForm: {
      padding: theme.spacing(5, 2),
    },
    inputField: {
      width: "100%",
      paddingBottom: theme.spacing(3),
    },
    feedbackImageWrap: {
      height: "80%",
      width: "80%",
      padding: theme.spacing(5, 2),
      backgroundImage: `url(${opinion})`,
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
  })
);

export default FeedbackStyles;

import {
  Button,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { TargetInfo } from "../../../../utils/types";
import TargetDialogStyles from "./TargetDialogStyle";
import DeleteIcon from "@material-ui/icons/Delete";
import { TargetDialogAction, Unit } from "../../../../utils/enums";
import { UNIT_OF_MEASURE, DAYS } from "../../../../utils/constants";
import { GreenSwitch } from "../../../../components/AppList/AppList";

export interface TargetDialogProps {
  currentTargetInfo: TargetInfo;
  targetDialogAction: TargetDialogAction;
  handleClose: () => void;
  createTarget: (target: TargetInfo) => void;
  updateTarget: (target: TargetInfo) => void;
  deleteTarget: (targetId: string) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TargetDialog: React.FunctionComponent<TargetDialogProps> = ({
  currentTargetInfo,
  targetDialogAction,
  handleClose,
  createTarget,
  updateTarget,
  deleteTarget,
}) => {
  const classes = TargetDialogStyles();
  const [targetInfo, setTargetInfo] = React.useState<TargetInfo>(currentTargetInfo);

  const invalidState = () => {
    return targetInfo.name.length < 3 || targetInfo.expectedMeasure <= 0;
  };

  const dataHasNotChanged = () => {
    return (
      targetDialogAction === TargetDialogAction.update &&
      currentTargetInfo.name === targetInfo.name &&
      currentTargetInfo.purpose === targetInfo.purpose &&
      currentTargetInfo.unitOfMeasure === targetInfo.unitOfMeasure &&
      currentTargetInfo.active === targetInfo.active &&
      currentTargetInfo.repeatEveryDayOfTheMonth === targetInfo.repeatEveryDayOfTheMonth &&
      currentTargetInfo.expectedMeasure === targetInfo.expectedMeasure &&
      currentTargetInfo.repeatDays === targetInfo.repeatDays
    );
  };

  return (
    <div className={classes.TargetDialogWrap}>
      <Typography className={classes.dialogHeaderText} variant="body1">
        {`${targetDialogAction} Target`}
      </Typography>
      <TextField
        className={classes.inputField}
        error={targetInfo.name.length < 3}
        id="name"
        label="Name"
        defaultValue={targetInfo.name}
        placeholder="Enter a name for this target"
        variant="outlined"
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTargetInfo({ ...targetInfo, name: e.target.value })}
      />
      <TextField
        className={classes.inputField}
        id="purpose"
        label="Purpose"
        defaultValue={targetInfo.purpose}
        placeholder="Why is this important to you?"
        variant="outlined"
        type="text"
        multiline
        rowsMax={3}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTargetInfo({ ...targetInfo, purpose: e.target.value })}
      />

      <div className={classes.targetMeasure}>
        <div className={classes.unitOfMeaure}>
          <TextField
            className={classes.inputField}
            select
            disabled={targetDialogAction !== TargetDialogAction.create}
            id="unitOfMeasure"
            label="Unit of measure"
            value={targetInfo.unitOfMeasure}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTargetInfo({
                ...targetInfo,
                unitOfMeasure: isNaN(Number(e.target.value)) ? 0 : Number(e.target.value),
                repeatEveryDayOfTheMonth: false,
              })
            }
          >
            {Object.keys(UNIT_OF_MEASURE).map((day) => (
              <MenuItem key={UNIT_OF_MEASURE[Number(day)].id} value={UNIT_OF_MEASURE[Number(day)].id}>
                {UNIT_OF_MEASURE[Number(day)].value}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className={classes.expectedMeaure}>
          <TextField
            className={classes.inputField}
            error={targetInfo.expectedMeasure <= 0}
            id="expectedMeasure"
            disabled={targetInfo.repeatEveryDayOfTheMonth}
            label={UNIT_OF_MEASURE[targetInfo.unitOfMeasure].value + " per month"}
            value={targetInfo.expectedMeasure}
            variant="outlined"
            type="text"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTargetInfo({
                ...targetInfo,
                expectedMeasure:
                  isNaN(Number(e.target.value)) || Number(e.target.value) < 0 ? 0 : Number(e.target.value),
              })
            }
          />
        </div>
      </div>

      {UNIT_OF_MEASURE[targetInfo.unitOfMeasure].value === Unit.rep && (
        <div className={classes.targetCheckBox}>
          <Typography variant="body1" className={classes.targetCheckBoxText}>
            Repeat on every day of the month
          </Typography>
          <GreenSwitch
            className={classes.targetCheckBoxSwitch}
            checked={targetInfo.repeatEveryDayOfTheMonth}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTargetInfo({
                ...targetInfo,
                repeatEveryDayOfTheMonth: e.target.checked,
                repeatDays: e.target.checked ? Object.keys(DAYS).map((key) => Number(key)) : [],
              })
            }
            name="repeatEveryDayOfTheMonth"
          />
        </div>
      )}

      <FormControl className={classes.repeatDays}>
        <InputLabel id="repeatDays">Repeat days</InputLabel>
        <Select
          className={classes.selectDays}
          labelId="repeatDays"
          multiple
          variant="outlined"
          value={targetInfo.repeatDays}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setTargetInfo({
              ...targetInfo,
              repeatDays: event.target.value as number[],
            });
          }}
          input={<Input />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as number[]).map((id) => (
                <Chip key={id} label={DAYS[id].value.substring(0, 3)} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {Object.keys(DAYS).map((day) => (
            <MenuItem key={DAYS[Number(day)].id} value={DAYS[Number(day)].id}>
              {DAYS[Number(day)].value}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Select the days you would like to take action towards this target</FormHelperText>
      </FormControl>

      {targetDialogAction === TargetDialogAction.update && (
        <div className={classes.targetCheckBox}>
          <Typography variant="body1" className={classes.targetCheckBoxText}>
            Active
          </Typography>
          <GreenSwitch
            className={classes.targetCheckBoxSwitch}
            checked={targetInfo.active}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTargetInfo({ ...targetInfo, active: e.target.checked })
            }
            name="active"
          />
        </div>
      )}

      <div className={classes.actionButtons}>
        {targetDialogAction === TargetDialogAction.update && (
          <div className={classes.actionButtonsLeft}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                size="small"
                className={classes.deleteButton}
                onClick={() => {
                  deleteTarget(targetInfo.id);
                  handleClose();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}

        <div className={classes.actionButtonsRight}>
          <Button variant="contained" size="small" className={classes.cancelButton} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={invalidState() || dataHasNotChanged()}
            variant="contained"
            color="primary"
            size="small"
            className={classes.saveButton}
            onClick={
              targetDialogAction === TargetDialogAction.create
                ? () => {
                    createTarget(targetInfo);
                    handleClose();
                  }
                : () => {
                    updateTarget(targetInfo);
                    handleClose();
                  }
            }
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TargetDialog;

export const ENABLE_REDUX_DEV = process.env.REACT_APP_ENABLE_REDUX_DEV;
export const API_URL = process.env.REACT_APP_API_URL;
export const STRIPE_PK = process.env.REACT_APP_STRIPE_PK;
export const STRIPE_PRO_PRICE_ID = process.env.REACT_APP_STRIPE_PRO_PRICE_ID;
export const CONTACT_EMAIL_ADDRESS = process.env.REACT_APP_CONTACT_EMAIL_ADDRESS;
export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_CONFIG_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENT_ID,
};
export const FIREBASE_UI_CONFIG = {
  signInFlow: process.env.REACT_APP_FIREBASE_UI_CONFIG_SIGN_IN_FLOW,
  signInSuccessUrl: process.env.REACT_APP_FIREBASE_UI_CONFIG_SIGN_IN_SUCCESS_URL,
  signInOptions: ["", ""],
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
};

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors, FontWeight } from "../../../../utils/enums";

const SubscriptionDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    paymentDialogWrap: {
      minWidth: 300,
      padding: theme.spacing(0, 3),
    },
    dialogHeaderText: {
      paddingBottom: theme.spacing(3),
      fontWeight: FontWeight.bold,
    },
    cancelBody: {
      paddingBottom: theme.spacing(3),
    },
    inputField: {
      width: "100%",
      paddingBottom: theme.spacing(3),
    },
    cardDetails: {
      marginBottom: theme.spacing(1),
    },
    cardElementInput: {
      width: "100%",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3),
      border: `solid 1px ${AppColors["grey-light"]}`,
      borderRadius: 3,
    },
    feedbackContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paymentErrorMessage: {
      flex: 1,
      color: AppColors["red-light"],
      textAlign: "center",
      paddingBottom: theme.spacing(1),
    },
    loader: {
      marginBottom: theme.spacing(1),
    },
    actionButtons: {
      display: "flex",
    },
    actionButtonsRight: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
    },
    cancelButton: {
      fontWeight: FontWeight.bold,
      margin: theme.spacing(0, 0.5),
      backgroundColor: AppColors["grey-light"],
      color: AppColors["grey-dark"],
      "&:hover": {
        backgroundColor: AppColors["grey-light"],
        color: AppColors["grey-dark"],
      },
    },
    saveButton: {
      fontWeight: FontWeight.bold,
      margin: theme.spacing(0, 0.5),
    },
  })
);

export default SubscriptionDialogStyles;

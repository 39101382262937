import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AppDatePickerStyles from "./AppDatePickerStyle";

export interface AppDatePickerProps {
  date?: Date;
  handleDateChange: (newDate: Date | null) => void;
  readOnly?: boolean;
  disabled?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  label?: string;
}

const AppDatePicker: React.FunctionComponent<AppDatePickerProps> = ({
  date,
  handleDateChange,
  readOnly,
  disabled,
  disablePast,
  disableFuture,
  label,
}) => {
  const classes = AppDatePickerStyles();

  if (!date) date = new Date();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        readOnly={readOnly}
        disabled={disabled}
        disablePast={disablePast}
        disableFuture={disableFuture}
        className={classes.datePicker}
        label={label ?? "Date"}
        inputVariant="outlined"
        format="dd/MM/yyyy"
        value={date}
        onChange={handleDateChange}
      />
    </MuiPickersUtilsProvider>
  );
};

export default AppDatePicker;

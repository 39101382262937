import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors } from "../../../utils/enums";

const SnackbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 600,
    },
    success: {
      backgroundColor: AppColors.complete,
      color: theme.palette.common.white,
    },
    error: {
      backgroundColor: AppColors["red-dark"],
      color: theme.palette.common.white,
    },
  })
);

export default SnackbarStyles;

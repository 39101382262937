import { createStyles, makeStyles, Theme } from "@material-ui/core";

const AppListStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxHeight: 400,
      position: "relative",
      overflow: "auto",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(8),
    },
    listItemText: {},
    listItemTextChecked: {
      textDecoration: "line-through !important",
    },
  })
);

export default AppListStyles;

import { AnyAction } from "@reduxjs/toolkit";
import { User, UserNotification, UserSubscription } from "../../../utils/types";
import {
  CANCEL_USER_SUBSCRIPTION,
  CANCEL_USER_SUBSCRIPTION_FAILURE,
  CANCEL_USER_SUBSCRIPTION_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  PROVISION_USER_SUBSCRIPTION,
  PROVISION_USER_SUBSCRIPTION_FAILURE,
  PROVISION_USER_SUBSCRIPTION_SUCCESS,
  LOGOUT_USER,
  UPDATE_USER_NOTIFICATIONS,
  UPDATE_USER_NOTIFICATIONS_FAILURE,
  UPDATE_USER_NOTIFICATIONS_SUCCESS,
  UPDATE_USER_RITUALS,
  UPDATE_USER_RITUALS_FAILURE,
  UPDATE_USER_RITUALS_SUCCESS,
} from "./actionTypes";

export const loginUser = (name: string, email: string, imageUrl: string): AnyAction => {
  return {
    type: LOGIN_USER,
    name,
    email,
    imageUrl,
  };
};

export const loginUserSuccess = (user: User): AnyAction => {
  return {
    type: LOGIN_USER_SUCCESS,
    user,
  };
};

export const loginUserFailure = (): AnyAction => {
  return {
    type: LOGIN_USER_FAILURE,
    message: "Unable to retrieve user data, please try again later",
  };
};

export const updateUserRituals = (rituals: string): AnyAction => {
  return {
    type: UPDATE_USER_RITUALS,
    rituals,
  };
};

export const updateUserRitualsSuccess = (rituals: string): AnyAction => {
  return {
    type: UPDATE_USER_RITUALS_SUCCESS,
    rituals,
    message: "Successfully updated daily rituals!",
  };
};

export const updateUserRitualsFailure = (message?: string): AnyAction => {
  return {
    type: UPDATE_USER_RITUALS_FAILURE,
    message: message || "Unable to update daily rituals, please try again later",
  };
};

export const updateUserNotifications = (notification: UserNotification): AnyAction => {
  return {
    type: UPDATE_USER_NOTIFICATIONS,
    notification,
  };
};

export const updateUserNotificationsSuccess = (notification: UserNotification): AnyAction => {
  return {
    type: UPDATE_USER_NOTIFICATIONS_SUCCESS,
    notification,
    message: "Successfully updated notification settings!",
  };
};

export const updateUserNotificationsFailure = (message?: string): AnyAction => {
  return {
    type: UPDATE_USER_NOTIFICATIONS_FAILURE,
    message: message || "Unable to update notification settings, please try again later",
  };
};

export const provisionUserSubscription = (): AnyAction => {
  return {
    type: PROVISION_USER_SUBSCRIPTION,
  };
};

export const provisionUserSubscriptionSuccess = (subscription: UserSubscription): AnyAction => {
  return {
    type: PROVISION_USER_SUBSCRIPTION_SUCCESS,
    subscription,
    message: "Successfully updated user subscription!",
  };
};

export const provisionUserSubscriptionFailure = (message?: string): AnyAction => {
  return {
    type: PROVISION_USER_SUBSCRIPTION_FAILURE,
    message:
      message ||
      "Unable to update user subscription, please try again later or contact support but do not make another payment",
  };
};

export const cancelUserSubscription = (): AnyAction => {
  return {
    type: CANCEL_USER_SUBSCRIPTION,
  };
};

export const cancelUserSubscriptionSuccess = (subscription: UserSubscription): AnyAction => {
  return {
    type: CANCEL_USER_SUBSCRIPTION_SUCCESS,
    subscription,
    message: "Successfully cancelled user subscription!",
  };
};

export const cancelUserSubscriptionFailure = (message?: string): AnyAction => {
  return {
    type: CANCEL_USER_SUBSCRIPTION_FAILURE,
    message: message || "Unable to cancel user subscription, please try again later",
  };
};

export const logoutUser = (): AnyAction => {
  return {
    type: LOGOUT_USER,
  };
};

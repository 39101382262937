import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors } from "../../utils/enums";

const AppDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "fixed",
      backgroundColor: theme.palette.common.white,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
      textAlign: "center",
      color: theme.palette.common.black,
    },
    closeIcon: {
      color: AppColors.main,
    },
    dialogContent: {
      margin: theme.spacing(5, 1),
    },
  })
);

export default AppDialogStyles;

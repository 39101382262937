import { Grid } from "@material-ui/core";
import React from "react";
import AppCard from "../../../components/AppCard/AppCard";
import AccountabilityStyles from "./AccountabilityStyle";
import ComingSoon from "./ComingSoon/ComingSoon";

export interface AccountabilityProps {}

const Accountability: React.FunctionComponent<AccountabilityProps> = () => {
  const classes = AccountabilityStyles();

  const comingSoon = <ComingSoon />;

  return (
    <section className={classes.root}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
          <div className={classes.comingSoonCard}>
            <AppCard content={comingSoon} />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default Accountability;

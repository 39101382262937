import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { RootState } from "../../../../store/rootReducer";
import TargetsProgress, { TargetsProgressProps } from "./TargetsProgress";

const mapStateToProps = (state: RootState): Partial<TargetsProgressProps> => ({
  month: state.Targets.month,
});

const mapDispatchToProps = (dispatch: any) => ({});

const TargetsProgressContainer = compose(connect(mapStateToProps, mapDispatchToProps))(
  TargetsProgress as React.ComponentType<any>
);

export default TargetsProgressContainer;

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors, FontWeight } from "../../../utils/enums";

const SplashStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.palette.common.white,
    },
    container: {
      marginTop: theme.spacing(15),
      marginBottom: theme.spacing(15),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyItems: "center",
      justifyContent: "center",
    },
    splashText: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyItems: "center",
      maxWidth: "100%",
    },
    arrowDown: {
      position: "absolute",
      bottom: theme.spacing(4),
      color: AppColors.main,
    },
    button: {
      minWidth: 100,
      minHeight: 25,
      fontWeight: FontWeight.bold,
      color: theme.palette.common.white,
      backgroundColor: AppColors.main,
    },
    title: {
      fontWeight: FontWeight.bold,
      color: theme.palette.common.black,
    },
    subTitle1: {
      color: theme.palette.common.black,
      marginTop: theme.spacing(2),
    },
    subTitle2: {
      color: theme.palette.common.black,
      marginBottom: theme.spacing(6),
    },
  })
);

export default SplashStyles;

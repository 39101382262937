import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import theme from "./utils/theme";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import HomeContainer from "./views/Home/HomeContainer";
import { Routes } from "./utils/enums";
import LandingContainer from "./views/Landing/LandingContainer";
import SpinnerDisplayContainer from "./views/Shared/Spinner/SpinnerDisplayContainer";
import SnackbarDisplayContainer from "./views/Shared/Snackbar/SnackbarDisplayContainer";
import AppSecure from "./AppSecure";
import firebase from "firebase/app";
import "firebase/auth";
import { FIREBASE_CONFIG, FIREBASE_UI_CONFIG } from "./config/config";

export const store = configureStore();
firebase.initializeApp(FIREBASE_CONFIG);
export const uiConfig = FIREBASE_UI_CONFIG;
uiConfig.signInOptions[0] = firebase.auth.GoogleAuthProvider.PROVIDER_ID;
uiConfig.signInOptions[1] = firebase.auth.FacebookAuthProvider.PROVIDER_ID;

const App: React.FunctionComponent = () => {
  const [routes, setRoutes] = React.useState<any>();

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((firebaseUser: any) => {
      setRoutes(
        <Switch>
          <AppSecure exact path={Routes.landing} component={LandingContainer} firebaseUser={firebaseUser} />
          <AppSecure exact path={Routes.home} component={HomeContainer} firebaseUser={firebaseUser} privateRoute />
          <Redirect to={Routes.landing} />
        </Switch>
      );
    });
    return () => unregisterAuthObserver();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <React.Fragment>
          <Router>
            <SpinnerDisplayContainer />
            <SnackbarDisplayContainer />
            {routes}
          </Router>
        </React.Fragment>
      </Provider>
    </ThemeProvider>
  );
};

export default App;

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors, FontWeight } from "../../utils/enums";

const AppTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    container: {
      maxHeight: 375,
    },
    tableRow: {
      backgroundColor: AppColors.main,
    },
    headTableCell: {
      borderBottom: "none",
      backgroundColor: AppColors["grey-light"],
      color: AppColors["grey-dark"],
      fontWeight: FontWeight.bold,
    },
    bodyTableCell: {
      borderBottom: "none",
    },
    bodyTableCellActions: {
      borderBottom: "none",
      display: "flex",
    },
    bodyTableIconActions: {
      marginRight: theme.spacing(1),
    },
  })
);

export default AppTableStyles;

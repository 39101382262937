import * as React from "react";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import AppTableStyles from "./AppTableStyle";
import { TableActions, TargetTableHeader, TargetTableRow } from "../../utils/types";

export interface AppTableProps {
  tableHeaders: TargetTableHeader[];
  tableRows?: TargetTableRow[];
  tableActions?: TableActions[];
}

const AppTable: React.FunctionComponent<AppTableProps> = ({ tableHeaders, tableRows, tableActions }) => {
  const classes = AppTableStyles();

  return (
    <Paper elevation={0} className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((column) => (
                <TableCell
                  className={classes.headTableCell}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              {tableActions && (
                <TableCell className={classes.headTableCell} key={"actions"} style={{ maxWidth: 50 }}></TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows?.map((row) => {
              return (
                <TableRow key={row.id}>
                  {tableHeaders.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align} className={classes.bodyTableCell}>
                        {value}
                      </TableCell>
                    );
                  })}

                  {tableActions && (
                    <TableCell key={"actions"} align={"center"} className={classes.bodyTableCellActions}>
                      {tableActions?.map((action) => (
                        <IconButton
                          key={action.id}
                          className={classes.bodyTableIconActions}
                          aria-label="actions"
                          onClick={() => action.action(row.id)}
                        >
                          {action.icon}
                        </IconButton>
                      ))}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default AppTable;

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { FontWeight } from "../../../utils/enums";

const FeedStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    container: {
      display: "flex",
    },
    gridItem: {},
    feedCard: {
      display: "flex",
    },
    cardInfoWrap: {
      height: 100,
      display: "flex",
      flexDirection: "column",
    },
    cardInfoTextTitle: {
      flex: 3,
      fontWeight: FontWeight.bold,
    },
    cardInfoText: {
      flex: 1,
    },
    articleMarkdown: {
      margin: theme.spacing(0, 5),
    },
  })
);

export default FeedStyles;

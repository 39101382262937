export const GET_MONTH_BY_MONTH_AND_YEAR = "TARGETS/GET_MONTH_BY_MONTH_AND_YEAR";
export const GET_MONTH_BY_MONTH_AND_YEAR_SUCCESS = "TARGETS/GET_MONTH_BY_MONTH_AND_YEAR_SUCCESS";
export const GET_MONTH_BY_MONTH_AND_YEAR_FAILURE = "TARGETS/GET_MONTH_BY_MONTH_AND_YEAR_FAILURE";

export const GET_TARGETS = "TARGETS/GET_TARGETS";
export const GET_TARGETS_SUCCESS = "TARGETS/GET_TARGETS_SUCCESS";
export const GET_TARGETS_FAILURE = "TARGETS/GET_TARGETS_FAILURE";

export const CREATE_TARGET = "TARGETS/CREATE_TARGET";
export const CREATE_TARGET_SUCCESS = "TARGETS/CREATE_TARGET_SUCCESS";
export const CREATE_TARGET_FAILURE = "TARGETS/CREATE_TARGET_FAILURE";

export const UPDATE_TARGET = "TARGETS/UPDATE_TARGET";
export const UPDATE_TARGET_SUCCESS = "TARGETS/UPDATE_TARGET_SUCCESS";
export const UPDATE_TARGET_FAILURE = "TARGETS/UPDATE_TARGET_FAILURE";

export const DELETE_TARGET = "TARGETS/DELETE_TARGET";
export const DELETE_TARGET_SUCCESS = "TARGETS/DELETE_TARGET_SUCCESS";
export const DELETE_TARGET_FAILURE = "TARGETS/DELETE_TARGET_FAILURE";

import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import SpinnerDisplay, { SpinnerDisplayProps } from "./SpinnerDisplay";

const mapStateToProps = (state: RootState): Partial<SpinnerDisplayProps> => ({
  loading: state.Spinner.loading,
});

const mapDispatchToProps = (dispatch: any) => ({});

const SpinnerDisplayContainer = compose(connect(mapStateToProps, mapDispatchToProps))(
  SpinnerDisplay as React.ComponentType<any>
);

export default SpinnerDisplayContainer;

import { Button, Typography } from "@material-ui/core";
import React from "react";
import AppTransferList, { AppTransferListItem } from "../../../../components/AppTransferList/AppTransferList";
import { Activity, Target } from "../../../../utils/types";
import PlanDialogStyles from "./PlanDialogStyle";

export interface PlanDialogProps {
  handleClose: () => void;
  target: Target;
  createActivitiesFromPlan: (activities: Activity[]) => void;
}

const PlanDialog: React.FunctionComponent<PlanDialogProps> = ({ handleClose, target, createActivitiesFromPlan }) => {
  const classes = PlanDialogStyles();

  const currentDay = new Date().getUTCDay();

  const targetsThatRepeatToday =
    target?.targets?.filter((target) => target.repeatDays.includes(currentDay) && target.active) ?? [];

  const transferListItems: AppTransferListItem[] = targetsThatRepeatToday?.map((target, index) => ({
    id: index,
    key: target.id,
    primary: target.name,
    additionalInfo: target.unitOfMeasure,
  }));

  const [left, setLeft] = React.useState<AppTransferListItem[]>(transferListItems);
  const [right, setRight] = React.useState<AppTransferListItem[]>([]);

  const handleFinalise = () => {
    const activitiesToCreate: Activity[] = right.map((item) => ({
      id: "",
      target: item.key,
      name: item.primary,
      description: "",
      user: target.user,
      day: "",
      month: "",
      date: new Date(),
      previousMeasure: 0,
      currentMeasure: 0,
      expectedMeasure: 1,
      percentageComplete: 0,
      completed: false,
    }));

    createActivitiesFromPlan(activitiesToCreate);
    handleClose();
  };

  return (
    <div className={classes.planDialogWrap}>
      {transferListItems.length === 0 && (
        <div className={classes.nothingToDisplay}>
          <Typography className={classes.dialogHeaderText} variant="body2">
            No suggestions to display based on your current targets and repeat days. You can still create tasks that you
            would like to complete today.
          </Typography>
          <Button variant="contained" size="small" className={classes.cancelButton} onClick={handleClose}>
            Close
          </Button>
        </div>
      )}
      {transferListItems.length > 0 && (
        <>
          <Typography className={classes.dialogHeaderText} variant="body2">
            Here are some suggestions based on your current targets and repeat days. You can still create more tasks
            later on.
          </Typography>
          <div className={classes.planDialog}>
            <AppTransferList
              transferListItems={transferListItems}
              leftItemTitle={"Suggested"}
              rightItemTitle={"Chosen"}
              leftItems={left}
              setLeftItems={setLeft}
              rightItems={right}
              setRightItems={setRight}
            />
          </div>
          <div className={classes.actionButtonsRight}>
            <Button variant="contained" size="small" className={classes.cancelButton} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={right.length === 0}
              variant="contained"
              color="primary"
              size="small"
              className={classes.finaliseButton}
              onClick={handleFinalise}
            >
              Finalise
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default PlanDialog;

import { Reducer } from "@reduxjs/toolkit";
import { articles } from "../../../utils/displayData/articles";
import { Article } from "../../../utils/types";
import { SET_FEED_CONTENT } from "./actionTypes";

export interface FeedState {
  articles: Article[];
  feedHasChanged: boolean;
}

export const initialState: FeedState = {
  articles: [],
  feedHasChanged: true,
};

export const FeedReducer: Reducer<FeedState> = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_FEED_CONTENT:
      return {
        ...state,
        articles: articles,
        feedHasChanged: false,
      };
    default:
      return state;
  }
};

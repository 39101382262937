import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { setFeedContent } from "../../../store/areas/feed/actions";
import { RootState } from "../../../store/rootReducer";
import Feed, { FeedProps } from "./Feed";

const mapStateToProps = (state: RootState): Partial<FeedProps> => ({
  articles: state.Feed.articles,
  feedHasChanged: state.Feed.feedHasChanged,
});

const mapDispatchToProps = (dispatch: any) => ({
  setFeedContent: () => dispatch(setFeedContent()),
});

const FeedContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Feed as React.ComponentType<any>);

export default FeedContainer;

import { createStyles, makeStyles, Theme } from "@material-ui/core";

const AppTransferListStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "auto",
    },
    card: {},
    transferListPrimaryItem: {},
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      width: 200,
      maxHeight: 150,
      backgroundColor: theme.palette.common.white,
      overflow: "auto",
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
  })
);

export default AppTransferListStyles;

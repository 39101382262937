import React from "react";
import { AppBar, Button, Link, Toolbar } from "@material-ui/core";
import AppHeaderStyles from "./AppHeaderStyle";
import logo from "../../assets/images/logos/dailySchema.png";

export interface AppHeaderProps {
  login: () => void;
}

const AppHeader: React.FunctionComponent<AppHeaderProps> = ({ login }) => {
  const classes = AppHeaderStyles();
  return (
    <AppBar className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.left}>
          <img alt={"logo"} className={classes.logo} src={logo} />
          <Link variant="body2" underline="none" href="/" className={classes.title}>
            {"DAILY SCHEMA"}
          </Link>
        </div>
        <div className={classes.right}>
          <Button onClick={login} className={classes.rightLink}>
            {"SIGN IN"}
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;

import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { createTarget, deleteTarget, updateTarget } from "../../../../store/areas/targets/actions";
import { RootState } from "../../../../store/rootReducer";
import { TargetInfo } from "../../../../utils/types";
import TargetsList, { TargetsListProps } from "./TargetsList";

const mapStateToProps = (state: RootState): Partial<TargetsListProps> => ({
  target: state.Targets.target,
});

const mapDispatchToProps = (dispatch: any) => ({
  createTarget: (target: TargetInfo) => dispatch(createTarget(target)),
  updateTarget: (target: TargetInfo) => dispatch(updateTarget(target)),
  deleteTarget: (targetId: string) => dispatch(deleteTarget(targetId)),
});

const TargetsListContainer = compose(connect(mapStateToProps, mapDispatchToProps))(
  TargetsList as React.ComponentType<any>
);

export default TargetsListContainer;

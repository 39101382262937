import { createStyles, makeStyles, Theme } from "@material-ui/core";

const ReflectStyles = makeStyles((theme: Theme) =>
  createStyles({
    reflectWrap: {
      flex: 1,
      height: "100%",
    },
    reflectHeader: {
      display: "flex",
      textAlign: "center",
      paddingBottom: theme.spacing(3),
    },
    textFieldWrap: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    textField: {
      flex: 1,
      width: "100%",
    },
    actionButtonWrap: {
      flex: 1,
      padding: theme.spacing(1, 0),
    },
    actionButton: {
      float: "right",
    },
  })
);

export default ReflectStyles;

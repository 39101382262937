import { Button, TextField, Typography } from "@material-ui/core";
import React from "react";
import AppAccordion, { AppAccordionItem } from "../../../../components/AppAccordion/AppAccordion";
import ReflectStyles from "./ReflectStyle";

export interface ReflectProps {
  summary?: string;
  rituals?: string;
  updateUserRituals: (rituals: string) => void;
  updateDaySummary: (summary: string) => void;
}

const Reflect: React.FunctionComponent<ReflectProps> = ({ summary, rituals, updateUserRituals, updateDaySummary }) => {
  const classes = ReflectStyles();

  const [userRituals, setUserRituals] = React.useState<string>(rituals ?? "");
  const [daySummary, setDaySummary] = React.useState<string>(summary ?? "");

  const accordionItems: AppAccordionItem[] = [
    {
      heading: "Daily Rituals",
      secondaryHeading: "Personalised items to help keep you on track",
      details: (
        <div className={classes.textFieldWrap}>
          <TextField
            className={classes.textField}
            id="outlined-multiline-static"
            label="Rituals"
            multiline
            rows={5}
            placeholder={"Words or actions to give you that extra boost..."}
            defaultValue={rituals}
            variant="outlined"
            inputProps={{
              style: {
                fontSize: 14,
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserRituals(e.target.value)}
          />
          <div className={classes.actionButtonWrap}>
            <Button
              disabled={(rituals ?? "") === userRituals}
              className={classes.actionButton}
              variant="contained"
              size="small"
              color="primary"
              onClick={() => updateUserRituals(userRituals)}
            >
              Save
            </Button>
          </div>
        </div>
      ),
    },
    {
      heading: "Daily Journal",
      secondaryHeading: "A great way to review your progress during the day",
      details: (
        <div className={classes.textFieldWrap}>
          <TextField
            className={classes.textField}
            id="outlined-multiline-static"
            label="Diary"
            multiline
            rows={5}
            placeholder={"Reflect on your day and highlight any noteworthy moments..."}
            defaultValue={summary}
            variant="outlined"
            inputProps={{ style: { fontSize: 14 } }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDaySummary(e.target.value)}
          />

          <div className={classes.actionButtonWrap}>
            <Button
              className={classes.actionButton}
              disabled={(summary ?? "") === daySummary}
              variant="contained"
              size="small"
              color="primary"
              onClick={() => updateDaySummary(daySummary)}
            >
              Save
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={classes.reflectWrap}>
      <Typography className={classes.reflectHeader} variant="h6">
        Reflect
      </Typography>
      <AppAccordion useDivider accordionItems={accordionItems} />
    </div>
  );
};

export default Reflect;

import { Reducer } from "@reduxjs/toolkit";
import { User } from "../../../utils/types";
import {
  UPDATE_USER_NOTIFICATIONS_SUCCESS,
  UPDATE_USER_RITUALS_SUCCESS,
  PROVISION_USER_SUBSCRIPTION_SUCCESS,
  CANCEL_USER_SUBSCRIPTION_SUCCESS,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
} from "./actionTypes";

export interface HomeState {
  user: User;
}

export const initialState: HomeState = {
  user: {
    id: "",
    name: "",
    email: "",
    deviceId: "",
    rituals: "",
    reminder: {
      day: {
        plan: false,
        planTime: new Date(),
        review: false,
        reviewTime: new Date(),
      },
      month: {
        plan: false,
        planTime: new Date(),
        review: false,
        reviewTime: new Date(),
      },
    },
    subscription: {
      autoRenew: false,
      stripeCustomerId: "",
      stripeSubscriptionId: "",
      plan: 0,
      expiry: new Date(),
    },
    notification: {
      articles: false,
      challenges: false,
    },
    partners: "",
    targets: "",
    imageUrl: "",
  },
};

export const HomeReducer: Reducer<HomeState> = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case UPDATE_USER_RITUALS_SUCCESS:
      return {
        ...state,
        user: { ...state.user, rituals: action.rituals },
      };
    case UPDATE_USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        user: { ...state.user, notification: action.notification },
      };
    case PROVISION_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        user: { ...state.user, subscription: action.subscription },
      };
    case CANCEL_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        user: { ...state.user, subscription: action.subscription },
      };
    case LOGOUT_USER:
      return state;
    default:
      return state;
  }
};

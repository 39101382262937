import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors, FontWeight } from "../../../../utils/enums";

const PlanDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    planDialogWrap: {
      minWidth: 300,
      padding: theme.spacing(0, 3),
    },
    nothingToDisplay: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    dialogHeaderText: {
      textAlign: "center",
      paddingBottom: theme.spacing(1),
    },
    planDialog: {
      display: "flex",
    },
    transferListPrimaryItem: {},
    actionButtonsRight: {
      paddingTop: theme.spacing(1),
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
    },
    cancelButton: {
      fontWeight: FontWeight.bold,
      margin: theme.spacing(0, 0.5),
      backgroundColor: AppColors["grey-light"],
      color: AppColors["grey-dark"],
      "&:hover": {
        backgroundColor: AppColors["grey-light"],
        color: AppColors["grey-dark"],
      },
    },
    finaliseButton: {
      fontWeight: FontWeight.bold,
      margin: theme.spacing(0, 0.5),
    },
  })
);

export default PlanDialogStyles;

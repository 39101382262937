import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors, FontWeight } from "../../../../utils/enums";

const DaySummaryDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    daySummaryDialogWrap: {
      minWidth: 300,
      padding: theme.spacing(0, 3),
    },
    dialogHeaderText: {
      paddingBottom: theme.spacing(3),
      fontWeight: FontWeight.bold,
    },
    inputField: {
      width: "100%",
      paddingBottom: theme.spacing(3),
    },
    actionButtons: {
      display: "flex",
    },
    actionButtonsLeft: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-start",
    },
    actionButtonsRight: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
    },
    cancelButton: {
      fontWeight: FontWeight.bold,
      margin: theme.spacing(0, 0.5),
      backgroundColor: AppColors["grey-light"],
      color: AppColors["grey-dark"],
      "&:hover": {
        backgroundColor: AppColors["grey-light"],
        color: AppColors["grey-dark"],
      },
    },
    saveButton: {
      fontWeight: FontWeight.bold,
      margin: theme.spacing(0, 0.5),
    },
  })
);

export default DaySummaryDialogStyles;

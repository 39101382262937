import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors, FontWeight } from "../../utils/enums";

const drawerWidth = 240;

const AppDrawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      backgroundColor: theme.palette.common.white,
      height: "100%",
    },
    drawer: {
      [theme.breakpoints.up("lg")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
      backgroundColor: theme.palette.common.white,
    },
    appBar: {
      [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      backgroundColor: theme.palette.common.white,
    },
    user: {
      flex: 1,
      display: "flex",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      backgroundColor: AppColors["grey-light"],
    },
    avatar: {
      borderRadius: 100,
      height: 30,
      width: 30,
    },
    username: {
      maxWidth: 150,
      padding: theme.spacing(0, 1),
    },
    signOut: {
      padding: theme.spacing(3),
      display: "flex",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
    },
    signOutButton: {
      textTransform: "none",
      color: theme.palette.common.white,
      backgroundColor: AppColors["grey-dark"],
      "&:hover": {
        color: theme.palette.common.white,
        backgroundColor: AppColors["grey-dark"],
      },
    },
    listItem: {},
    selectedListItem: {},
    listIcon: {
      color: AppColors["grey-dark"],
      opacity: 0.5,
    },
    selectedListIcon: {
      color: AppColors.main,
    },
    listItemText: {
      color: AppColors["grey-dark"],
    },
    selectedListItemText: {
      color: AppColors.main,
      fontWeight: FontWeight.bold,
    },
    title: {
      textTransform: "none",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
      color: AppColors.main,
    },
    // necessary for content to be below app bar
    toolbar: {
      display: "flex",
      alignItems: "center",
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    // toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.common.white,
    },
    content: {
      flex: 1,
      padding: theme.spacing(3),
      backgroundColor: theme.palette.common.white,
    },
  })
);

export default AppDrawerStyles;

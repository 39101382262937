import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { setFaqsContent } from "../../../store/areas/faqs/actions";
import { RootState } from "../../../store/rootReducer";
import Faqs, { FaqsProps } from "./Faqs";

const mapStateToProps = (state: RootState): Partial<FaqsProps> => ({
  faqs: state.Faqs.faqs,
  faqsHasChanged: state.Faqs.faqsHasChanged,
});

const mapDispatchToProps = (dispatch: any) => ({
  setFaqsContent: () => dispatch(setFaqsContent()),
});

const FaqsContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Faqs as React.ComponentType<any>);

export default FaqsContainer;

import { Reducer } from "@reduxjs/toolkit";
import { faqs } from "../../../utils/displayData/faqs";
import { Faq } from "../../../utils/types";
import { SET_FAQS_CONTENT } from "./actionTypes";

export interface FaqsState {
  faqs: Faq[];
  faqsHasChanged: boolean;
}

export const initialState: FaqsState = {
  faqs: [],
  faqsHasChanged: true,
};

export const FaqsReducer: Reducer<FaqsState> = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_FAQS_CONTENT:
      return {
        ...state,
        faqs: faqs,
        faqsHasChanged: false,
      };
    default:
      return state;
  }
};

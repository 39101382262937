import { createStyles, makeStyles, Theme } from "@material-ui/core";

const AppAccordionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      borderRadius: 5,
    },
    accordion: {},
    expanded: {},
    heading: {
      flexBasis: "33.33%",
      flexShrink: 0,
      margin: theme.spacing(1),
    },
    secondaryHeading: {
      color: theme.palette.text.secondary,
      margin: theme.spacing(1),
    },
  })
);

export default AppAccordionStyles;

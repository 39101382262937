import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { RootState } from "../../../../store/rootReducer";
import Today, { TodayProps } from "./Today";

const mapStateToProps = (state: RootState): Partial<TodayProps> => ({
  date: state.Overview.day.date,
});

const mapDispatchToProps = (dispatch: any) => ({});

const TodayContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Today as React.ComponentType<any>);

export default TodayContainer;

import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import AppCard from "../../../components/AppCard/AppCard";
import OverviewStyles from "./OverviewStyle";

import { User } from "../../../utils/types";
import ActivityChartContainer from "./ActivityChart/ActivityChartContainer";
import ActivityListContainer from "./ActivityList/ActivityListContainer";
import ReflectContainer from "./Reflect/ReflectContainer";
import TodayContainer from "./Today/TodayContainer";
import QuoteContainer from "./Quote/QuoteContainer";
import WelcomeContainer from "./Welcome/WelcomeContainer";

export interface OverviewProps {
  user: User;
  getDayByDate: (date: Date) => void;
  getTargets: (userTargetId: string) => void;
  setQuote: () => void;
}

const Overview: React.FunctionComponent<OverviewProps> = ({ user, getDayByDate, getTargets, setQuote }) => {
  useEffect(() => {
    if (user.id) {
      getDayByDate(new Date());
    }

    if (user.targets) {
      getTargets(user.targets);
    }

    setQuote();
  }, [user, getDayByDate, getTargets, setQuote]);

  const classes = OverviewStyles();

  const welcome = <WelcomeContainer />;
  const today = <TodayContainer />;
  const quote = <QuoteContainer />;
  const activityList = <ActivityListContainer />;
  const activityChart = <ActivityChartContainer />;
  const reflect = <ReflectContainer />;

  return (
    <section className={classes.root}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
          <div className={classes.upperCards}>
            <AppCard content={welcome} />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3} className={classes.gridItem}>
          <div className={classes.upperCards}>{today}</div>
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3} className={classes.gridItem}>
          <div className={classes.upperCards}>
            <AppCard content={quote} />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={7} lg={8} className={classes.gridItem}>
          <div className={classes.middleCards}>
            <AppCard content={activityList} />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={4} className={classes.gridItem}>
          <div className={classes.middleCards}>
            <AppCard content={activityChart} />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
          <div className={classes.lowerCards}>
            <AppCard content={reflect} />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default Overview;

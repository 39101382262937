import { Reducer } from "@reduxjs/toolkit";
import { Target, Month } from "../../../utils/types";
import {
  GET_TARGETS_SUCCESS,
  CREATE_TARGET_SUCCESS,
  DELETE_TARGET_SUCCESS,
  UPDATE_TARGET_SUCCESS,
  GET_MONTH_BY_MONTH_AND_YEAR_SUCCESS,
} from "./actionTypes";

export interface TargetsState {
  target: Target;
  month: Month;
  targetChanged: boolean;
}

export const initialState: TargetsState = {
  target: {
    id: "",
    user: "",
    targets: [],
  },
  month: {
    id: "",
    user: "",
    targets: "",
    monthTargets: [],
    month: new Date().getUTCMonth(),
    year: new Date().getUTCFullYear(),
    strongestTarget: "",
    weakestTarget: "",
    review: "",
  },
  targetChanged: true,
};

export const TargetsReducer: Reducer<TargetsState> = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_MONTH_BY_MONTH_AND_YEAR_SUCCESS:
      return {
        ...state,
        month: action.month,
        targetChanged: false,
      };
    case GET_TARGETS_SUCCESS:
      return {
        ...state,
        target: action.target,
      };
    case CREATE_TARGET_SUCCESS:
      return {
        ...state,
        target: {
          ...state.target,
          targets: action.targets,
        },
        targetChanged: true,
      };
    case UPDATE_TARGET_SUCCESS:
      return {
        ...state,
        target: {
          ...state.target,
          targets: action.targets,
        },
        targetChanged: true,
      };
    case DELETE_TARGET_SUCCESS:
      return {
        ...state,
        target: {
          ...state.target,
          targets: action.targets,
        },
        targetChanged: true,
      };
    default:
      return state;
  }
};

import React from "react";
import { Avatar, Grid, Container, Link, Typography } from "@material-ui/core";
import AppFooterStyles from "./AppFooterStyle";
import AppDialog from "../AppDialog/AppDialog";
import ai from "../../assets/images/avatar/ai2.jpg";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

export interface AppFooterProps {
  copyright: string;
}

const AppFooter: React.FunctionComponent<AppFooterProps> = ({ copyright }) => {
  const classes = AppFooterStyles();

  const [aboutOpen, setAboutOpen] = React.useState(false);
  const [termsOpen, setTermsOpen] = React.useState(false);

  const handleAboutClick = () => {
    setAboutOpen(true);
  };

  const handleTermsClick = () => {
    setTermsOpen(true);
  };

  const handleAboutClose = () => {
    setAboutOpen(false);
  };

  const handleTermsClose = () => {
    setTermsOpen(false);
  };

  const aboutDisplay = () => {
    return (
      <div className={classes.cardInfoWrap}>
        <Typography className={classes.cardInfoTextTitle} variant="body2">
          The Daily Schema Story
        </Typography>
        <Avatar alt="Arinze Ibekwe" src={ai} className={classes.avatar} />
        <Typography align="center" className={classes.cardInfoTextTitle} variant="body2">
          Arinze Ibekwe, Software Engineer
        </Typography>
        <Typography className={classes.cardInfoText} variant="body2">
          The idea for Daily Schema came about during the 2020 lockdown in an attempt to maintain a consistent routine.
        </Typography>
        <Typography className={classes.cardInfoText} variant="body2">
          As an individual who tracks a lot of habits, it became clear that having endless spreadsheets was
          unsustainable and each new month added some new overhead.
        </Typography>
        <Typography className={classes.cardInfoText} variant="body2">
          With Daily Schema, I am now able to keep on track with my goals and targets each month while simplifying the
          process and time involved.
        </Typography>
        <div>
          <Typography className={classes.cardInfoText} variant="body2">
            You can find me on LinkedIn below
          </Typography>{" "}
          <a
            className={classes.connect}
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/arinzeibekwe"
            target="_blank"
          >
            <LinkedInIcon className={classes.linkedIn} />
          </a>
        </div>
      </div>
    );
  };

  const termsDisplay = () => {
    return (
      <div className={classes.cardInfoWrap}>
        <Typography className={classes.cardInfoTextTitle} variant="body2">
          Terms & Conditions
        </Typography>
        <Typography className={classes.cardInfoText} variant="body2">
          1. Your privacy is our priority and Daily Schema does not use any third party software that may identify you.
        </Typography>
        <Typography className={classes.cardInfoText} variant="body2">
          2. Daily Schema does not in any way use your personal information for marketing or analytics.
        </Typography>
        <Typography className={classes.cardInfoText} variant="body2">
          3. If you decide you no longer want to use the service, you can request to have your data and account deleted.
        </Typography>
      </div>
    );
  };

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid item xs={12} sm={12} md={12} className={classes.gridLinks}>
          <Link
            component="button"
            onClick={handleAboutClick}
            variant="body2"
            underline="none"
            className={classes.linkItem}
          >
            The Daily Schema Story
          </Link>

          <Link
            component="button"
            onClick={handleTermsClick}
            variant="body2"
            underline="none"
            className={classes.linkItem}
          >
            Terms & Conditions
          </Link>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className={classes.gridItem}>
          <Typography variant="body2" className={classes.copyright}>
            Copyright {"© "} {new Date().getUTCFullYear()} {copyright}
          </Typography>
        </Grid>
      </Container>
      <AppDialog isOpen={aboutOpen} handleClose={handleAboutClose} dialogContent={aboutDisplay()} />
      <AppDialog isOpen={termsOpen} handleClose={handleTermsClose} dialogContent={termsDisplay()} />
    </section>
  );
};

export default AppFooter;

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { FontWeight, AppColors } from "../../../../utils/enums";

const ActivityListStyles = makeStyles((theme: Theme) =>
  createStyles({
    activityListWrap: {
      flex: 1,
      height: "100%",
    },
    activityListHeader: {
      display: "flex",
    },
    activityListHeaderTitle: {
      flex: 1,
    },
    activityListSubtitle: {},
    activityListHeaderButtonContainer: {
      flex: 1,
    },
    activityListHeaderAddButton: {
      fontWeight: FontWeight.bold,
      float: "right",
    },
    menuItem: {
      display: "flex",
    },
    listItemIcon: {
      minWidth: 0,
      flex: 2,
    },
    listItemText: {
      flex: 3,
    },
    menuItemText: {},
    activityListNoDataContainer: {
      display: "flex",
      height: "80%",
      justifyContent: "center",
      alignItems: "center",
    },
    activityListNoDataText: {
      flex: 1,
      textAlign: "center",
      fontSize: 14,
      color: AppColors["grey-dark"],
    },
  })
);

export default ActivityListStyles;

import { Typography } from "@material-ui/core";
import React from "react";
import AppFeatureStyles from "./AppFeatureStyle";

export interface AppFeatureProps {
  title: string;
  subTitle: string;
  imagePath: string;
  altText: string;
}

const AppFeature: React.FunctionComponent<AppFeatureProps> = ({ title, subTitle, imagePath, altText }) => {
  const classes = AppFeatureStyles();
  return (
    <div className={classes.item}>
      <img className={classes.image} src={imagePath} alt={altText} />
      <Typography variant="subtitle1" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body2" className={classes.subTitle}>
        {subTitle}
      </Typography>
    </div>
  );
};

export default AppFeature;

import { put, takeLatest, call } from "redux-saga/effects";
import {
  createTargetFailure,
  createTargetSuccess,
  deleteTargetFailure,
  deleteTargetSuccess,
  getMonthByMonthAndYearFailure,
  getMonthByMonthAndYearSuccess,
  getTargetsFailure,
  getTargetsSuccess,
  updateTargetFailure,
  updateTargetSuccess,
} from "./actions";
import { CREATE_TARGET, DELETE_TARGET, GET_MONTH_BY_MONTH_AND_YEAR, GET_TARGETS, UPDATE_TARGET } from "./actionTypes";
import { Month, Target, TargetInfo } from "../../../utils/types";
import { fetchData } from "../../../utils/httpUtils";
import { ApiResponseCode, ApiResponseStatus, Endpoints, httpMethod } from "../../../utils/enums";
import { API_URL } from "../../../config/config";
import { RootState } from "../../rootReducer";
import { store } from "../../../App";
import { initialState } from "./reducer";
import { mapToSingleMonth } from "../../../utils/mappers/monthMapper";
import { mapToMultipleTargetInfo, mapToSingleTarget } from "../../../utils/mappers/targetMapper";

export function* getMonthByMonthAndYearSaga(action: any) {
  try {
    const { monthId, year }: { monthId: number; year: number } = action;
    const url = API_URL + Endpoints.monthByMonthAndYear;
    const body = { monthId, year };

    const { status, data, code } = yield call(fetchData, url, httpMethod.post, body);

    if (status === ApiResponseStatus.success) {
      const month: Month = mapToSingleMonth(data);

      yield put(getMonthByMonthAndYearSuccess(month));
    } else if (code === ApiResponseCode.notFound) {
      // month may not yet be created if no targets
      yield put(getMonthByMonthAndYearSuccess(initialState.month));
    } else {
      yield put(getMonthByMonthAndYearFailure());
    }
  } catch (error) {
    yield put(getMonthByMonthAndYearFailure());
  }
}

export function* getTargetsSaga(action: any) {
  try {
    const { userTargetId }: { userTargetId: string } = action;
    const url = API_URL + Endpoints.target + userTargetId;

    const { status, data } = yield call(fetchData, url, httpMethod.get);

    if (status === ApiResponseStatus.success) {
      const target: Target = mapToSingleTarget(data);

      yield put(getTargetsSuccess(target));
    } else {
      yield put(getTargetsFailure());
    }
  } catch (error) {
    yield put(getTargetsFailure());
  }
}

export function* createTargetSaga(action: any) {
  try {
    const { target }: { target: TargetInfo } = action;
    const state: RootState = store.getState();

    const url = API_URL + Endpoints.target + state.Targets.target.id;

    const body: any = {
      name: target.name,
      unitOfMeasure: target.unitOfMeasure,
      expectedMeasure: target.expectedMeasure,
      repeatEveryDayOfTheMonth: target.repeatEveryDayOfTheMonth,
      repeatDays: target.repeatDays,
    };
    if (target.purpose) body.purpose = target.purpose;
    if (target.reminder) body.reminder = target.reminder;
    if (target.partners) body.partners = target.partners;

    const { status, data, message } = yield call(fetchData, url, httpMethod.put, body);

    if (status === ApiResponseStatus.success) {
      const updatedTargetInfo: TargetInfo[] = mapToMultipleTargetInfo(data.targets);

      yield put(createTargetSuccess(updatedTargetInfo));
    } else {
      yield put(createTargetFailure(message));
    }
  } catch (error) {
    yield put(createTargetFailure());
  }
}

export function* updateTargetSaga(action: any) {
  try {
    const { target }: { target: TargetInfo } = action;
    const state: RootState = store.getState();

    const targetInfoListFromState = state.Targets.target.targets;
    const targetInfoFromState = targetInfoListFromState?.find((t) => t.id === target.id);

    if (targetInfoFromState && targetInfoListFromState) {
      const url = API_URL + Endpoints.target + state.Targets.target.id;

      const body: any = {
        targetId: target.id,
      };
      if (targetInfoFromState.name !== target.name) body.name = target.name;
      if (targetInfoFromState.purpose !== target.purpose) body.purpose = target.purpose;
      if (targetInfoFromState.unitOfMeasure !== target.unitOfMeasure) body.unitOfMeasure = target.unitOfMeasure;
      if (targetInfoFromState.expectedMeasure !== target.expectedMeasure) body.expectedMeasure = target.expectedMeasure;
      if (targetInfoFromState.repeatDays !== target.repeatDays) body.repeatDays = target.repeatDays;

      if (targetInfoFromState.reminder !== target.reminder) body.reminder = target.reminder;
      if (targetInfoFromState.partners !== target.partners) body.partners = target.partners;
      if (targetInfoFromState.active !== target.active) body.active = target.active;
      if (targetInfoFromState.repeatEveryDayOfTheMonth !== target.repeatEveryDayOfTheMonth)
        body.repeatEveryDayOfTheMonth = target.repeatEveryDayOfTheMonth;

      const { status, data, message } = yield call(fetchData, url, httpMethod.put, body);

      if (status === ApiResponseStatus.success) {
        const updatedTargetInfo: TargetInfo[] = mapToMultipleTargetInfo(data.targets);

        yield put(updateTargetSuccess(updatedTargetInfo));
      } else {
        yield put(updateTargetFailure(message));
      }
    } else {
      yield put(updateTargetFailure("An unexpected error has occured, please try again later"));
    }
  } catch (error) {
    yield put(updateTargetFailure());
  }
}

export function* deleteTargetSaga(action: any) {
  try {
    const { targetId }: { targetId: string } = action;
    const state: RootState = store.getState();
    const targetInfoListFromState = state.Targets.target.targets;
    const targetInfoFromState = targetInfoListFromState?.find((t) => t.id === targetId);

    if (targetInfoFromState && targetInfoListFromState) {
      const url = API_URL + Endpoints.target + state.Targets.target.id;

      const body: any = {
        targetId: targetId,
        deleteItem: true,
      };

      const { data, status, message } = yield call(fetchData, url, httpMethod.put, body);

      if (status === ApiResponseStatus.success) {
        const updatedTargetInfo: TargetInfo[] = mapToMultipleTargetInfo(data.targets);

        yield put(deleteTargetSuccess(updatedTargetInfo));
      } else {
        yield put(deleteTargetFailure(message));
      }
    } else {
      yield put(deleteTargetFailure("An unexpected error has occured, please try again later"));
    }
  } catch (error) {
    yield put(deleteTargetFailure());
  }
}

export function* targetsWatcher() {
  yield takeLatest(GET_MONTH_BY_MONTH_AND_YEAR, getMonthByMonthAndYearSaga);
  yield takeLatest(GET_TARGETS, getTargetsSaga);
  yield takeLatest(CREATE_TARGET, createTargetSaga);
  yield takeLatest(UPDATE_TARGET, updateTargetSaga);
  yield takeLatest(DELETE_TARGET, deleteTargetSaga);
}

import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { getMonthByMonthAndYear } from "../../../store/areas/targets/actions";
import { resetDayHasChanged as resetDayHasChangedOnOverview } from "../../../store/areas/overview/actions";
import { resetDayHasChanged as resetDayHasChangedOnHistory } from "../../../store/areas/history/actions";
import { RootState } from "../../../store/rootReducer";
import Targets, { TargetsProps } from "./Targets";

const mapStateToProps = (state: RootState): Partial<TargetsProps> => ({
  targetChanged: state.Targets.targetChanged,
  dayHasChangedOnOverview: state.Overview.dayHasChanged,
  dayHasChangedOnHistory: state.History.dayHasChanged,
});

const mapDispatchToProps = (dispatch: any) => ({
  getMonthByMonthAndYear: (monthId: number, year: number) => dispatch(getMonthByMonthAndYear(monthId, year)),
  resetDayHasChangedOnOverview: () => dispatch(resetDayHasChangedOnOverview()),
  resetDayHasChangedOnHistory: () => dispatch(resetDayHasChangedOnHistory()),
});

const TargetsContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Targets as React.ComponentType<any>);

export default TargetsContainer;

import { Reducer } from "@reduxjs/toolkit";

export interface SpinnerState {
  requests: { [id: string]: boolean };
  loading: boolean;
  progress: number;
}

const initialState: SpinnerState = {
  requests: {},
  loading: false,
  progress: 0,
};

export const SpinnerReducer: Reducer<SpinnerState> = (state = initialState, action: any) => {
  const { type } = action;

  switch (action.type) {
    default:
      const matches = /((CREATE|GET|SAVE|UPDATE|PROVISION|CANCEL|VERIFY|LOGIN)_.*)(_(SUCCESS|FAILURE))?/.exec(type);
      if (!matches) return state;

      const [requestName] = matches;

      const initialAction = requestName.replace("_SUCCESS", "").replace("_FAILURE", "");

      const requests = state.requests;
      let loading = false;
      requests[initialAction] = requestName === initialAction;

      for (const request in requests) {
        loading = loading || requests[request];
      }

      return {
        ...state,
        requests,
        loading,
      };
  }
};

import { Button, Typography } from "@material-ui/core";
import React from "react";
import AppTable from "../../../../components/AppTable/AppTable";
import { TableActions, Target, TargetInfo, TargetTableHeader, TargetTableRow } from "../../../../utils/types";
import TargetsListStyles from "./TargetsListStyle";
import { formatRepeatFrequency, formatActive, formatRepeatDays } from "./utils";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { TargetDialogAction } from "../../../../utils/enums";
import AppDialog from "../../../../components/AppDialog/AppDialog";
import TargetDialog from "../../Dialogs/TargetDialog/TargetDialog";

export interface TargetsListProps {
  target: Target;
  createTarget: (target: TargetInfo) => void;
  updateTarget: (target: TargetInfo) => void;
  deleteTarget: (targetId: string) => void;
}

const targetTableHeaders: TargetTableHeader[] = [
  {
    id: "name",
    label: "Name",
    minWidth: 130,
  },
  {
    id: "repeatFrequency",
    label: "Goal",
    minWidth: 170,
  },
  {
    id: "repeatDays",
    label: "Repeats On",
    minWidth: 450,
  },
  {
    id: "active",
    label: "Active",
    minWidth: 130,
    align: "center",
  },
];

const TargetsList: React.FunctionComponent<TargetsListProps> = ({
  target,
  createTarget,
  updateTarget,
  deleteTarget,
}) => {
  const classes = TargetsListStyles();

  const targets = target?.targets;

  const newTargetInfo: TargetInfo = {
    id: "",
    name: "",
    purpose: "",
    unitOfMeasure: 0,
    expectedMeasure: 1,
    repeatEveryDayOfTheMonth: false,
    repeatDays: [],
    reminder: null,
    partners: [],
    active: true,
  };

  const [openDialog, setOpenDialog] = React.useState(false);
  const [targetInfo, setTargetInfo] = React.useState<TargetInfo>(newTargetInfo);
  const [action, setAction] = React.useState<TargetDialogAction>(TargetDialogAction.create);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleCreate = () => {
    setTargetInfo(newTargetInfo);
    setAction(TargetDialogAction.create);
    setOpenDialog(true);
  };

  const handleUpdate = (id: string) => {
    const targetInfoFromList = targets?.find((targetInfo) => targetInfo.id === id);

    if (targetInfoFromList) {
      setTargetInfo(targetInfoFromList);
      setAction(TargetDialogAction.update);
      setOpenDialog(true);
    }
  };

  const targetTableActions: TableActions[] = [{ id: "update", icon: <MoreHorizIcon />, action: handleUpdate }];

  const targetTableRows: TargetTableRow[] | undefined = targets?.map((target) => ({
    id: target.id,
    name: target.name,
    repeatDays: formatRepeatDays(target.repeatDays),
    repeatFrequency: target.repeatEveryDayOfTheMonth
      ? "Every day of the month"
      : formatRepeatFrequency(target.expectedMeasure, target.unitOfMeasure) + " each month",
    active: formatActive(target.active),
  }));

  return (
    <div className={classes.targetsListWrap}>
      <div className={classes.targetsListHeader}>
        <Typography className={classes.targetsListHeaderTitle} variant="h6">
          Targets
        </Typography>

        <div className={classes.targetsListHeaderButtonContainer}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.targetsListHeaderAddButton}
            onClick={handleCreate}
          >
            Create
          </Button>
        </div>
      </div>

      {targets && targets.length === 0 && (
        <div className={classes.targetsListNoDataContainer}>
          <Typography className={classes.targetsListNoDataText}>Nothing to display</Typography>
        </div>
      )}

      {targets && targets.length > 0 && (
        <div className={classes.targetsListTableContainer}>
          <AppTable tableHeaders={targetTableHeaders} tableRows={targetTableRows} tableActions={targetTableActions} />
        </div>
      )}

      <AppDialog
        isOpen={openDialog}
        handleClose={handleDialogClose}
        dialogContent={
          <TargetDialog
            currentTargetInfo={targetInfo}
            targetDialogAction={action}
            handleClose={handleDialogClose}
            createTarget={createTarget}
            updateTarget={updateTarget}
            deleteTarget={deleteTarget}
          />
        }
      />
    </div>
  );
};

export default TargetsList;

import { Reducer } from "@reduxjs/toolkit";
import { quotes, quotesCount } from "../../../utils/displayData/quotes";
import { Day, Quote } from "../../../utils/types";
import {
  CREATE_ACTIVITIES_FROM_PLAN_SUCCESS,
  CREATE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_SUCCESS,
  GET_DAY_BY_DATE_SUCCESS,
  RESET_DAY_HAS_CHANGED,
  SET_QUOTE,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_DAY_SUMMARY_SUCCESS,
} from "./actionTypes";

export interface OverviewState {
  day: Day;
  quote: Quote;
  dayHasChanged: boolean;
}

export const initialState: OverviewState = {
  day: {
    id: "",
    user: "",
    date: new Date(),
    summary: "",
    activities: [],
  },
  quote: {
    id: "",
    author: "",
    body: "",
  },
  dayHasChanged: false,
};

export const OverviewReducer: Reducer<OverviewState> = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_DAY_BY_DATE_SUCCESS:
      return {
        ...state,
        day: action.day,
      };
    case UPDATE_DAY_SUMMARY_SUCCESS:
      return {
        ...state,
        day: { ...state.day, summary: action.summary },
        dayHasChanged: true,
      };
    case CREATE_ACTIVITY_SUCCESS:
      if (!state.day.id) {
        return {
          ...state,
          day: {
            ...state.day,
            id: action.activities[0].day,
            activities: action.activities,
          },
          dayHasChanged: true,
        };
      } else {
        return {
          ...state,
          day: {
            ...state.day,

            activities: action.activities,
          },
          dayHasChanged: true,
        };
      }
    case CREATE_ACTIVITIES_FROM_PLAN_SUCCESS:
      if (!state.day.id) {
        return {
          ...state,
          day: {
            ...state.day,
            id: action.activities[0].day,
            activities: action.activities,
          },
          dayHasChanged: true,
        };
      } else {
        return {
          ...state,
          day: {
            ...state.day,

            activities: action.activities,
          },
          dayHasChanged: true,
        };
      }
    case UPDATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        day: {
          ...state.day,
          activities: action.activities,
        },
        dayHasChanged: true,
      };
    case DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        day: {
          ...state.day,
          activities: action.activities,
        },
        dayHasChanged: true,
      };
    case RESET_DAY_HAS_CHANGED:
      return {
        ...state,
        dayHasChanged: false,
      };
    case SET_QUOTE:
      return {
        ...state,
        quote: quotes[Math.floor(Math.random() * quotesCount)],
      };
    default:
      return state;
  }
};

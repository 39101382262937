import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { RootState } from "../../../../store/rootReducer";
import ActivityChart, { ActivityChartProps } from "./ActivityChart";

const mapStateToProps = (state: RootState): Partial<ActivityChartProps> => ({
  activities: state.Overview.day?.activities,
});

const mapDispatchToProps = (dispatch: any) => ({});

const ActivityChartContainer = compose(connect(mapStateToProps, mapDispatchToProps))(
  ActivityChart as React.ComponentType<any>
);

export default ActivityChartContainer;

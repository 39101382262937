import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import Settings, { SettingsProps } from "./Settings";

const mapStateToProps = (state: RootState): Partial<SettingsProps> => ({});

const mapDispatchToProps = (dispatch: any) => ({});

const SettingsContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Settings as React.ComponentType<any>);

export default SettingsContainer;

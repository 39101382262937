import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { FontWeight } from "../../../../utils/enums";

const NotificationsStyles = makeStyles((theme: Theme) =>
  createStyles({
    notificationsWrap: {
      flex: 1,
      height: "100%",
    },
    notificationsHeader: {
      display: "flex",
    },
    notificationsHeaderTitle: {
      display: "flex",
    },
    notificationsHeaderButtonContainer: {
      flex: 1,
    },
    notificationsHeaderButton: {
      fontWeight: FontWeight.bold,
      float: "right",
    },
    notifications: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

export default NotificationsStyles;

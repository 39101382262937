import React from "react";
import CalendarStyles from "./CalendarStyle";
import FullCalendar, { ViewContentArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { Activity, CalendarEvent, DaysObject, Target } from "../../../../utils/types";
import { ActivityDialogAction, AppColors } from "../../../../utils/enums";
import AppDialog from "../../../../components/AppDialog/AppDialog";
import ActivityDialog from "../../Dialogs/ActivityDialog/ActivityDialog";
import { Button, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { formatDateString } from "../../../../utils/extensions";
import DaySummaryDialog from "../../Dialogs/DaySummaryDialog/DaySummaryDialog";

const windowWidth: number = 800;

const getHeaderToolbar = () => {
  return {
    left: window.innerWidth < windowWidth ? "prev,next" : "prev,next today",
    center: window.innerWidth < windowWidth ? "" : "title",
    right: window.innerWidth < windowWidth ? "title" : "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
  };
};

const getInitialView = () => {
  return window.innerWidth < windowWidth ? "listWeek" : "dayGridMonth";
};

const handleWindowResize = (event: ViewContentArg): void => {
  let contentApi = event.view.calendar;
  window.innerWidth < windowWidth ? contentApi.changeView("listWeek") : contentApi.changeView("dayGridMonth");
  contentApi.setOption("headerToolbar", getHeaderToolbar());
};

export interface CalendarProps {
  days: DaysObject;
  target: Target;
  latestStartDate: string;
  latestEndDate: string;
  dayHasChanged: boolean;
  getDaysByDateRange: (startDate: string, endDate: string) => void;
  createActivity: (activity: Activity) => void;
  updateActivity: (activity: Activity, dayObjectKey: string) => void;
  deleteActivity: (activityId: string, dayObjectKey: string) => void;
  updateDaySummary: (summary: string, dayObjectKey: string) => void;
  resetDayHasChanged: () => void;
}

enum DialogType {
  "activity" = "Activity",
  "summary" = "Summary",
}

const Calendar: React.FunctionComponent<CalendarProps> = ({
  days,
  target,
  latestStartDate,
  latestEndDate,
  dayHasChanged,
  getDaysByDateRange,
  createActivity,
  updateActivity,
  deleteActivity,
  updateDaySummary,
  resetDayHasChanged,
}) => {
  const newActivity: Activity = {
    id: "",
    name: "",
    description: "",
    user: "",
    day: "",
    month: "",
    date: new Date(),
    previousMeasure: 0,
    currentMeasure: 0,
    expectedMeasure: 1,
    percentageComplete: 0,
    completed: false,
  };

  const activities: Activity[] = Object.values(days).flatMap((day) => (day.activities ? day.activities : []));

  const classes = CalendarStyles();
  const [dialogType, setDialogType] = React.useState<DialogType>();
  const [open, setOpen] = React.useState(false);
  const [activity, setActivity] = React.useState<Activity>(newActivity);
  const [summary, setSummary] = React.useState("");

  const [dayKey, setDayKey] = React.useState("");
  const [action, setAction] = React.useState<ActivityDialogAction>(ActivityDialogAction.create);

  const calendarEvents: CalendarEvent[] = activities.map((activity: Activity) => ({
    id: activity.id,
    allDay: true,
    start: activity.date,
    title: activity.name,
    backgroundColor: activity.completed ? AppColors.complete : AppColors["red-dark"],
    extendedProps: {
      day: activity.day,
      dayDate: activity.date,
    },
  }));

  const handleDateClick = (dateClickInfo: any) => {
    const dateStr = dateClickInfo.dateStr;

    if (new Date(dateStr).setUTCHours(0, 0, 0, 0) <= new Date().setUTCHours(0, 0, 0, 0)) {
      setDialogType(DialogType.summary);
      setDayKey(dateStr);

      if (days[dateStr]) {
        setSummary(days[dateStr]?.summary || "");
      } else {
        setSummary("");
      }

      setOpen(true);
    }
  };

  const handleEventClick = (eventClickInfo: any) => {
    const formattedDayDate = formatDateString(eventClickInfo.event.extendedProps.dayDate);
    const activityId = eventClickInfo.event._def.publicId;

    const activityFromList = days[formattedDayDate].activities?.find((activity) => activity.id === activityId);
    if (activityFromList) {
      setDialogType(DialogType.activity);
      setDayKey(formattedDayDate);
      setActivity(activityFromList);
      setAction(ActivityDialogAction.update);
      setOpen(true);
    }
  };

  const handleActivityCreate = () => {
    setDialogType(DialogType.activity);
    setActivity(newActivity);
    setAction(ActivityDialogAction.create);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchEvents = (e: any) => {
    const startStr = new Date(e.startStr);
    const endStr = new Date(e.endStr);
    startStr.setUTCHours(0, 0, 0, 0);
    endStr.setUTCHours(0, 0, 0, 0);
    const startStrISO = startStr.toISOString();
    const endStrISO = endStr.toISOString();

    if ((startStrISO < latestStartDate && endStrISO > latestEndDate) || dayHasChanged) {
      getDaysByDateRange(startStrISO, endStrISO);
      resetDayHasChanged();
    } else if (startStrISO < latestStartDate) {
      let stopDate = new Date(latestStartDate);
      stopDate.setUTCHours(0, 0, 0, 0);
      stopDate.setUTCDate(stopDate.getUTCDate() - 1);
      getDaysByDateRange(startStrISO, stopDate.toISOString());
    } else if (endStrISO > latestEndDate) {
      let stopDate = new Date(latestEndDate);
      stopDate.setUTCHours(0, 0, 0, 0);
      stopDate.setUTCDate(stopDate.getUTCDate() + 1);
      getDaysByDateRange(stopDate.toISOString(), endStrISO);
    }
  };

  return (
    <div className={classes.calendarWrap}>
      <Alert className={classes.calendarHeaderAlert} severity="info">
        Click on a day to access your journal
      </Alert>
      <div className={classes.calendarHeader}>
        <Typography className={classes.calendarHeaderTitle} variant="h6">
          Calendar
        </Typography>
        <div className={classes.calendarHeaderButtonContainer}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.calendarHeaderAddButton}
            onClick={handleActivityCreate}
          >
            Create
          </Button>
        </div>
      </div>
      <div className={classes.calendar}>
        <FullCalendar
          datesSet={fetchEvents}
          eventClick={handleEventClick}
          dateClick={handleDateClick}
          events={calendarEvents}
          height={650}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          initialView={getInitialView()}
          headerToolbar={getHeaderToolbar()}
          windowResize={handleWindowResize}
        />
      </div>
      <AppDialog
        isOpen={open}
        handleClose={handleClose}
        dialogContent={
          dialogType === DialogType.activity ? (
            <ActivityDialog
              currentActivity={activity}
              target={target}
              activityDialogAction={action}
              createActivity={createActivity}
              updateActivity={(value: Activity) => updateActivity(value, dayKey)}
              deleteActivity={(value: string) => deleteActivity(value, dayKey)}
              handleClose={handleClose}
            />
          ) : (
            <DaySummaryDialog
              currentDaySummary={summary}
              updateDaySummary={(value: string) => updateDaySummary(value, dayKey)}
              handleClose={handleClose}
            />
          )
        }
      />
    </div>
  );
};

export default Calendar;

import { Target, TargetInfo } from "../types";

export const mapToMultipleTargetInfo = (targetInfo: any) => {
  const mappedTargetInfo: TargetInfo[] = targetInfo.map((target: any) => ({
    id: target._id,
    name: target.name,
    purpose: target.purpose,
    unitOfMeasure: target.unitOfMeasure,
    expectedMeasure: target.expectedMeasure,
    repeatDays: target.repeatDays,
    reminder: target.reminder,
    partners: target.partners,
    repeatEveryDayOfTheMonth: target.repeatEveryDayOfTheMonth,
    active: target.active,
  }));

  return mappedTargetInfo;
};

export const mapToSingleTarget = (target: any) => {
  const mappedTarget: Target = {
    id: target._id,
    user: target.user,
    targets: mapToMultipleTargetInfo(target.targets),
  };
  return mappedTarget;
};

import { createStyles, makeStyles, Theme } from "@material-ui/core";

const TargetsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    container: {
      display: "flex",
    },
    gridItem: {
      width: 350,
    },
    targetListCard: {
      display: "flex",
      height: 450,
    },
    targetProgressSummaryCard: {
      display: "flex",
      height: 500,
    },
  })
);

export default TargetsStyles;

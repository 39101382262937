export enum AppColors {
  "main" = "#3D64F5",
  "main-light" = "#4395DE",
  "main-dark" = "#442CF6",
  "background-dark" = "#221E28",
  "grey-light" = "#EEEEEE",
  "grey-dark" = "#616161",
  "complete" = "#43a047",
  "red-dark" = "#c62828",
  "red-light" = "#e53835",
  "amber" = "#ff9100",
}

export enum AppFonts {
  "main" = "Lato",
}

export enum FontWeight {
  "bold" = 600,
  "regular" = 400,
}

export enum Routes {
  "landing" = "/",
  "home" = "/home",
}

export enum Pages {
  "overview" = "Overview",
  "targets" = "Targets",
  "history" = "History",
  "accountability" = "Accountability",
  "feed" = "Feed",
  "settings" = "Settings",
  "faqs" = "FAQs",
  "feedback" = "Feedback",
}

export enum Unit {
  "rep" = "Repetitions",
  "time" = "Hours",
}

export enum Plans {
  "free" = "Free",
  "pro" = "Pro",
}

export enum ActivityDialogAction {
  "create" = "Create",
  "update" = "Update",
  "complete" = "Complete",
}

export enum TargetDialogAction {
  "create" = "Create",
  "update" = "Update",
}

export enum Endpoints {
  "dayByDate" = "day/byDate",
  "daysByDateRange" = "day/byDateRange",
  "monthByMonthAndYear" = "month/byMonthAndYear",
  "activity" = "activity/",
  "day" = "day/",
  "user" = "user/",
  "target" = "target/",
  "getOrCreateUser" = "user/getOrCreate",
  "verifyCustomerSubscription" = "payment/verifyCustomerSubscription",
  "provisionSubscription" = "payment/provisionSubscription",
  "cancelSubscription" = "payment/cancelSubscription",
}

export enum EndpointActions {
  "create" = "create",
  "delete" = "delete",
  "update" = "update",
}

export enum ApiResponseStatus {
  "success" = "success",
  "error" = "error",
}

export enum ApiResponseCode {
  "notFound" = 404,
}

export enum httpMethod {
  "get" = "GET",
  "put" = "PUT",
  "post" = "POST",
  "patch" = "PATCH",
  "delete" = "DELETE",
}

import React from "react";
import SnackbarStyles from "./SnackbarStyle";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

export interface SnackbarDisplayProps {
  closeSnackbar: () => void;
  open: boolean;
  message?: string;
  variant: "success" | "error";
}

const SnackbarDisplay: React.FunctionComponent<SnackbarDisplayProps> = ({ closeSnackbar, open, message, variant }) => {
  const classes = SnackbarStyles();

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity={variant}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackbarDisplay;

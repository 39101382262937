import React from "react";
import {
  AppBar,
  Avatar,
  CssBaseline,
  Drawer,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Hidden,
  Button,
} from "@material-ui/core";
import AppDrawerStyles from "./AppDrawerStyle";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export interface AppDrawerItem {
  id: string;
  title: string;
  icon: JSX.Element;
  content: JSX.Element;
  divider?: boolean;
}

export interface AppDrawerProps {
  appDrawerItems: AppDrawerItem[];
  userName: string;
  userImageUrl?: string;
  window?: () => Window;
  handleSignOut: () => void;
}

const AppDrawer: React.FunctionComponent<AppDrawerProps> = ({
  appDrawerItems,
  userName,
  userImageUrl,
  window,
  handleSignOut,
}) => {
  const classes = AppDrawerStyles();

  const [page, setPage] = React.useState(appDrawerItems[0]);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <div className={classes.user}>
          <Avatar variant="rounded" src={userImageUrl} className={classes.avatar} alt={userName} />
          <Typography className={classes.username} variant="subtitle2">
            {userName}
          </Typography>
        </div>
      </div>
      <List>
        {appDrawerItems.map((item) => (
          <div key={item.id}>
            <ListItem
              key={item.id}
              className={item.id === page.id ? classes.selectedListItem : classes.listItem}
              button
              selected={item.id === page.id}
              onClick={() => setPage(item)}
            >
              <ListItemIcon className={item.id === page.id ? classes.selectedListIcon : classes.listIcon}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    className={item.id === page.id ? classes.selectedListItemText : classes.listItemText}
                    variant="body2"
                  >
                    {item.title}
                  </Typography>
                }
              />
            </ListItem>
            {item.divider && <Divider />}
          </div>
        ))}
      </List>
      <div className={classes.signOut}>
        <Button
          startIcon={<ExitToAppIcon />}
          className={classes.signOutButton}
          fullWidth
          variant="contained"
          size="small"
          onClick={handleSignOut}
        >
          Sign Out
        </Button>
      </div>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="button" noWrap>
            {page.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden lgUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={"left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {page.content}
      </main>
    </div>
  );
};

export default AppDrawer;

import { Typography } from "@material-ui/core";
import React from "react";
import transactionsStyles from "./TransactionsStyle";

export interface TransactionsProps {}

const Transactions: React.FunctionComponent<TransactionsProps> = () => {
  const classes = transactionsStyles();

  return (
    <div className={classes.transactionsWrap}>
      <Typography className={classes.transactionsHeaderTitle} variant="h6">
        Transactions
      </Typography>

      <div className={classes.transactions}>
        <Typography className={classes.transactionsText} variant="body2">
          Nothing to display
        </Typography>
      </div>
    </div>
  );
};

export default Transactions;

import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { createActivitiesFromPlan } from "../../../../store/areas/overview/actions";
import { RootState } from "../../../../store/rootReducer";
import { Activity } from "../../../../utils/types";
import Welcome, { WelcomeProps } from "./Welcome";

const mapStateToProps = (state: RootState): Partial<WelcomeProps> => ({
  username: state.Home.user.name,
  activityCount: state.Overview.day.activities?.length ?? 0,
  target: state.Targets.target,
});

const mapDispatchToProps = (dispatch: any) => ({
  createActivitiesFromPlan: (activities: Activity[]) => dispatch(createActivitiesFromPlan(activities)),
});

const WelcomeContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Welcome as React.ComponentType<any>);

export default WelcomeContainer;

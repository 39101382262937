import { createStyles, makeStyles, Theme } from "@material-ui/core";

const comingSoonStyles = makeStyles((theme: Theme) =>
  createStyles({
    comingSoonWrap: {
      flex: 1,
      height: "100%",
    },
    comingSoonHeader: {
      display: "flex",
    },
    comingSoonHeaderTitle: {
      flex: 1,
      textAlign: "center",
    },
    comingSoonContent: {
      padding: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    comingSoonText: {
      flex: 1,
      textAlign: "center",
    },
    comingSoonImage: {
      flex: 1,
      padding: theme.spacing(10, 0),
      width: 400,
      height: 400,
    },
  })
);

export default comingSoonStyles;

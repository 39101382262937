import { put, takeLatest, call } from "redux-saga/effects";
import { User, UserNotification, UserSubscription } from "../../../utils/types";
import { fetchData } from "../../../utils/httpUtils";
import { ApiResponseStatus, EndpointActions, Endpoints, httpMethod } from "../../../utils/enums";
import { API_URL } from "../../../config/config";
import {
  cancelUserSubscriptionFailure,
  cancelUserSubscriptionSuccess,
  loginUserFailure,
  loginUserSuccess,
  provisionUserSubscriptionFailure,
  provisionUserSubscriptionSuccess,
  updateUserNotificationsFailure,
  updateUserNotificationsSuccess,
  updateUserRitualsFailure,
  updateUserRitualsSuccess,
} from "./actions";
import {
  CANCEL_USER_SUBSCRIPTION,
  LOGIN_USER,
  PROVISION_USER_SUBSCRIPTION,
  UPDATE_USER_NOTIFICATIONS,
  UPDATE_USER_RITUALS,
} from "./actionTypes";
import { mapUser, mapUserSubscription } from "../../../utils/mappers/userMapper";

export function* loginUserSaga(action: any) {
  try {
    const { name, email, imageUrl }: { name: string; email: string; imageUrl: string } = action;

    const url = API_URL + Endpoints.getOrCreateUser;

    const body = { name: name, email: email, imageUrl: imageUrl };

    const { status, data } = yield call(fetchData, url, httpMethod.post, body);

    if (status === ApiResponseStatus.success) {
      const user: User = mapUser(data);

      yield put(loginUserSuccess(user));
    } else {
      yield put(loginUserFailure());
    }
  } catch (error) {
    yield put(loginUserFailure());
  }
}

export function* updateUserRitualsSaga(action: any) {
  try {
    const { rituals }: { rituals: string } = action;

    const url = API_URL + Endpoints.user + EndpointActions.update;

    const body = { rituals: rituals };

    const { status } = yield call(fetchData, url, httpMethod.patch, body);

    if (status === ApiResponseStatus.success) {
      yield put(updateUserRitualsSuccess(rituals));
    } else {
      yield put(updateUserRitualsFailure());
    }
  } catch (error) {
    yield put(updateUserRitualsFailure());
  }
}

export function* updateUserNotificationsSaga(action: any) {
  try {
    const { notification }: { notification: UserNotification } = action;

    const url = API_URL + Endpoints.user + EndpointActions.update;

    const body = { notificationArticles: notification.articles, notificationChallenges: notification.challenges };

    const { status } = yield call(fetchData, url, httpMethod.patch, body);

    if (status === ApiResponseStatus.success) {
      yield put(updateUserNotificationsSuccess(notification));
    } else {
      yield put(updateUserNotificationsFailure());
    }
  } catch (error) {
    yield put(updateUserNotificationsFailure());
  }
}

export function* provisionUserSubscriptionSaga(action: any) {
  try {
    const url = API_URL + Endpoints.provisionSubscription;

    const { status, data } = yield call(fetchData, url, httpMethod.post, null);

    if (status === ApiResponseStatus.success) {
      const userSubscription: UserSubscription = mapUserSubscription(data);

      yield put(provisionUserSubscriptionSuccess(userSubscription));
    } else {
      yield put(provisionUserSubscriptionFailure());
    }
  } catch (error) {
    yield put(provisionUserSubscriptionFailure());
  }
}

export function* cancelUserSubscriptionSaga(action: any) {
  try {
    const url = API_URL + Endpoints.cancelSubscription;

    const { status, data } = yield call(fetchData, url, httpMethod.post, null);

    if (status === ApiResponseStatus.success) {
      const userSubscription: UserSubscription = mapUserSubscription(data);

      yield put(cancelUserSubscriptionSuccess(userSubscription));
    } else {
      yield put(cancelUserSubscriptionFailure());
    }
  } catch (error) {
    yield put(cancelUserSubscriptionFailure());
  }
}

export function* homeWatcher() {
  yield takeLatest(LOGIN_USER, loginUserSaga);
  yield takeLatest(UPDATE_USER_RITUALS, updateUserRitualsSaga);
  yield takeLatest(UPDATE_USER_NOTIFICATIONS, updateUserNotificationsSaga);
  yield takeLatest(PROVISION_USER_SUBSCRIPTION, provisionUserSubscriptionSaga);
  yield takeLatest(CANCEL_USER_SUBSCRIPTION, cancelUserSubscriptionSaga);
}

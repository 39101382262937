import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { RootState } from "./store/rootReducer";
import { Routes } from "./utils/enums";
import { loginUser } from "./store/areas/home/actions";

interface AppSecureProps {
  userId: string;
  firebaseUser: any;
  loginUser: (name: string, email: string, imageUrl: string) => void;
  component: React.FunctionComponent;
  path: string;
  exact?: boolean;
  privateRoute?: boolean;
}

const mapStateToProps = (state: RootState): Partial<AppSecureProps> => ({
  userId: state.Home.user.id,
});

const mapDispatchToProps = (dispatch: any) => ({
  loginUser: (name: string, email: string, imageUrl: string) => dispatch(loginUser(name, email, imageUrl)),
});

const Secure: React.FunctionComponent<AppSecureProps> = ({
  userId,
  firebaseUser,
  component,
  path,
  exact,
  privateRoute,
  loginUser,
}) => {
  useEffect(() => {
    if (!userId && firebaseUser) {
      loginUser(firebaseUser.displayName, firebaseUser.email, firebaseUser.photoURL);
    }
  }, [userId, firebaseUser, loginUser]);

  if (privateRoute) {
    return userId ? <Route exact={exact} path={path} component={component} /> : <Redirect to={Routes.landing} />;
  } else {
    return userId ? <Redirect to={Routes.home} /> : <Route exact={exact} path={path} component={component} />;
  }
};

const AppSecure = connect(mapStateToProps, mapDispatchToProps)(Secure as React.ComponentType<any>);

export default AppSecure;

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { FontWeight } from "../../utils/enums";

const AppCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    cardActionArea: {
      height: 350,
    },
    cardActionAreaImage: {
      height: 200,
      width: "100%",
    },
    cardHeader: {
      flex: 1,
      textAlign: "center",
      fontWeight: FontWeight.bold,
      backgroundColor: theme.palette.common.white,
    },
    cardContent: {
      height: "100%",
    },
  })
);

export default AppCardStyles;

import AppHeader from "../../components/AppHeader/AppHeader";
import React, { useEffect } from "react";
import Splash from "./Splash/Splash";
import Features from "./Features/Features";
import InAppSamples from "./InAppSamples/InAppSamples";
import AppFooter from "../../components/AppFooter/AppFooter";
import AppDialog from "../../components/AppDialog/AppDialog";
import LoginDialog from "./LoginDialog/LoginDialog";
import { uiConfig } from "../../App";
import firebase from "firebase/app";
import "firebase/auth";

export interface LandingProps {
  loginError: string;
}

const Landing: React.FunctionComponent<LandingProps> = ({ loginError }) => {
  useEffect(() => {
    if (loginError === "Unable to retrieve user data, please try again later") {
      handleDialogClose();
      firebase.auth().signOut();
    }
  }, [loginError]);

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  return (
    <React.Fragment>
      <AppHeader login={() => setOpenDialog(true)} />
      <Splash />
      <Features />
      <InAppSamples />
      <AppFooter copyright={"Daily Schema"} />
      <AppDialog
        isOpen={openDialog}
        handleClose={handleDialogClose}
        dialogContent={<LoginDialog uiConfig={uiConfig} handleClose={handleDialogClose} />}
      />
    </React.Fragment>
  );
};

export default Landing;

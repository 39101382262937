import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { getDayByDate, setQuote } from "../../../store/areas/overview/actions";
import { getTargets } from "../../../store/areas/targets/actions";
import { RootState } from "../../../store/rootReducer";
import Overview, { OverviewProps } from "./Overview";

const mapStateToProps = (state: RootState): Partial<OverviewProps> => ({
  user: state.Home.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  getDayByDate: (date: Date) => dispatch(getDayByDate(date)),
  getTargets: (userTargetId: string) => dispatch(getTargets(userTargetId)),
  setQuote: () => dispatch(setQuote()),
});

const OverviewContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Overview as React.ComponentType<any>);

export default OverviewContainer;

import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import AppCard from "../../../components/AppCard/AppCard";
import AppDialog from "../../../components/AppDialog/AppDialog";
import { Article } from "../../../utils/types";
import FeedStyles from "./FeedStyle";

export interface FeedProps {
  articles: Article[];
  feedHasChanged: boolean;
  setFeedContent: () => void;
}

const Feed: React.FunctionComponent<FeedProps> = ({ articles, feedHasChanged, setFeedContent }) => {
  useEffect(() => {
    if (feedHasChanged) {
      setFeedContent();
    }
  }, [setFeedContent, feedHasChanged]);
  const classes = FeedStyles();
  const [open, setOpen] = React.useState(false);
  const [article, setArticle] = React.useState<Article>(articles[0]);

  const handleClick = (article: Article) => {
    setArticle(article);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const cardInfo = (title: string, date: Date, author: string) => {
    return (
      <div className={classes.cardInfoWrap}>
        <Typography className={classes.cardInfoTextTitle} variant="body2">
          {title}
        </Typography>
        <Typography className={classes.cardInfoText} variant="body2">
          {date.toDateString()}
        </Typography>
        <Typography className={classes.cardInfoText} variant="body2">
          written by {author}
        </Typography>
      </div>
    );
  };

  const articleDisplay = (article: Article) => {
    return (
      <div className={classes.articleMarkdown}>
        <ReactMarkdown children={article?.body} />
      </div>
    );
  };

  return (
    <section className={classes.root}>
      <Grid container spacing={3} className={classes.container}>
        {articles.map((article) => (
          <Grid key={article.id} item xs={12} sm={6} md={4} lg={4} className={classes.gridItem}>
            <div className={classes.feedCard}>
              <AppCard
                actionArea
                actionAreaImage={article?.imageUrl}
                actionAreaClick={() => handleClick(article)}
                content={cardInfo(article.title, article.date, article.author)}
              />
            </div>
          </Grid>
        ))}
        <AppDialog fullScreen isOpen={open} handleClose={handleClose} dialogContent={articleDisplay(article)} />
      </Grid>
    </section>
  );
};

export default Feed;

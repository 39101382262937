import { put, takeLatest, call } from "redux-saga/effects";
import {
  createActivitiesFromPlanFailure,
  createActivitiesFromPlanSuccess,
  createActivityFailure,
  createActivitySuccess,
  deleteActivityFailure,
  deleteActivitySuccess,
  getDayByDateFailure,
  getDayByDateSuccess,
  updateActivityFailure,
  updateActivitySuccess,
  updateDaySummaryFailure,
  updateDaySummarySuccess,
} from "./actions";
import {
  CREATE_ACTIVITIES_FROM_PLAN,
  CREATE_ACTIVITY,
  DELETE_ACTIVITY,
  GET_DAY_BY_DATE,
  UPDATE_ACTIVITY,
  UPDATE_DAY_SUMMARY,
} from "./actionTypes";
import { Activity, Day } from "../../../utils/types";
import { fetchData } from "../../../utils/httpUtils";
import { ApiResponseStatus, ApiResponseCode, Endpoints, httpMethod, EndpointActions } from "../../../utils/enums";
import { API_URL } from "../../../config/config";
import { RootState } from "../../rootReducer";
import { store } from "../../../App";
import { initialState } from "../overview/reducer";
import { mapToSingleDay } from "../../../utils/mappers/dayMapper";
import { mapToSingleActivity } from "../../../utils/mappers/activityMapper";

export function* getDayByDateSaga(action: any) {
  try {
    const { date }: { date: Date } = action;
    const url = API_URL + Endpoints.dayByDate;
    const body = { date };

    const { status, data, code } = yield call(fetchData, url, httpMethod.post, body);

    if (status === ApiResponseStatus.success) {
      const day: Day = mapToSingleDay(data);

      yield put(getDayByDateSuccess(day));
    } else if (code === ApiResponseCode.notFound) {
      // day may not yet be created
      yield put(getDayByDateSuccess(initialState.day));
    } else {
      yield put(getDayByDateFailure());
    }
  } catch (error) {
    yield put(getDayByDateFailure());
  }
}

export function* updateDaySummarySaga(action: any) {
  try {
    const { summary }: { summary: string } = action;

    const state: RootState = store.getState();

    const dayId = state.Overview.day.id;

    if (dayId) {
      const url = API_URL + Endpoints.day + dayId;

      const body = { summary: summary };

      const { status } = yield call(fetchData, url, httpMethod.patch, body);
      if (status === ApiResponseStatus.success) {
        yield put(updateDaySummarySuccess(summary));
      } else {
        yield put(updateDaySummaryFailure());
      }
    } else {
      const url = API_URL + Endpoints.day + EndpointActions.create;

      const body = { date: new Date(), summary: summary };

      const { status } = yield call(fetchData, url, httpMethod.post, body);

      if (status === ApiResponseStatus.success) {
        yield put(updateDaySummarySuccess(summary));
      } else {
        yield put(updateDaySummaryFailure());
      }
    }
  } catch (error) {
    yield put(updateDaySummaryFailure());
  }
}

export function* createActivitySaga(action: any) {
  try {
    const { activity }: { activity: Activity } = action;

    const url = API_URL + Endpoints.activity + EndpointActions.create;

    const body: any = {
      date: activity.date,
      name: activity.name,
      expectedMeasure: activity.expectedMeasure,
    };
    if (activity.target) body.targetId = activity.target;
    if (activity.description) body.description = activity.description;
    if (activity.reminder) body.reminder = activity.reminder;

    const { status, data, message } = yield call(fetchData, url, httpMethod.post, body);

    if (status === ApiResponseStatus.success) {
      const createdActivity: Activity = mapToSingleActivity(data);

      const state: RootState = store.getState();

      const activitiesFromState = state.Overview.day.activities ?? [];

      let updatedActivitiesList: Activity[] = [...activitiesFromState];

      const today = new Date();
      today.setUTCHours(0, 0, 0, 0);

      const savedDate = new Date(createdActivity.date);
      savedDate.setUTCHours(0, 0, 0, 0);

      if (savedDate.toISOString() === today.toISOString()) {
        updatedActivitiesList = [...updatedActivitiesList, createdActivity];
      }

      yield put(createActivitySuccess(updatedActivitiesList));
    } else {
      yield put(createActivityFailure(message));
    }
  } catch (error) {
    yield put(createActivityFailure());
  }
}

export function* createActivitiesFromPlanSaga(action: any) {
  try {
    const { activities }: { activities: Activity[] } = action;

    const url = API_URL + Endpoints.activity + EndpointActions.create;

    let updatedActivityList: Activity[] = [];
    let errorCount = 0;

    for (let activity of activities) {
      const body: any = {
        date: activity.date,
        name: activity.name,
        expectedMeasure: activity.expectedMeasure,
        targetId: activity.target,
      };

      const { status, data } = yield call(fetchData, url, httpMethod.post, body);

      if (status === ApiResponseStatus.success) {
        const createdActivity: Activity = mapToSingleActivity(data);

        updatedActivityList.push(createdActivity);
      } else {
        errorCount++;
      }
    }

    if (errorCount > 0) {
      yield put(createActivitiesFromPlanFailure());
    } else {
      yield put(createActivitiesFromPlanSuccess(updatedActivityList));
    }
  } catch (error) {
    yield put(createActivitiesFromPlanFailure());
  }
}

export function* updateActivitySaga(action: any) {
  try {
    const { activity }: { activity: Activity } = action;

    const state: RootState = store.getState();
    const activitiesFromState = state.Overview.day.activities;
    const activityFromState = activitiesFromState?.find((a) => a.id === activity.id);

    if (activityFromState && activitiesFromState) {
      const url = API_URL + Endpoints.activity + activity.id;
      const body: any = {};

      if (activityFromState?.target !== activity.target) body.targetId = activity.target;
      if (activityFromState?.date !== activity.date) body.date = activity.date;
      if (activityFromState?.name !== activity.name) body.name = activity.name;
      if (activityFromState?.description !== activity.description) body.description = activity.description;
      if (activityFromState?.currentMeasure !== activity.currentMeasure) body.currentMeasure = activity.currentMeasure;
      if (activityFromState?.expectedMeasure !== activity.expectedMeasure)
        body.expectedMeasure = activity.expectedMeasure;
      if (activityFromState?.reminder !== activity.reminder) body.reminder = activity.reminder;

      const { status, data, message } = yield call(fetchData, url, httpMethod.patch, body);

      if (status === ApiResponseStatus.success) {
        const updatedActivity: Activity = mapToSingleActivity(data);

        let updatedActivitiesList: Activity[];

        activityFromState.day !== updatedActivity.day
          ? (updatedActivitiesList = activitiesFromState.filter((a) => a.id !== updatedActivity.id))
          : (updatedActivitiesList = activitiesFromState.map((a) =>
              a.id === updatedActivity.id && a.day === updatedActivity.day ? updatedActivity : a
            ));
        yield put(updateActivitySuccess(updatedActivitiesList));
      } else {
        yield put(updateActivityFailure(message));
      }
    } else {
      yield put(updateActivityFailure("An unexpected error has occured, please try again later"));
    }
  } catch (error) {
    yield put(updateActivityFailure());
  }
}

export function* deleteActivitySaga(action: any) {
  try {
    const { activityId }: { activityId: string } = action;

    const state: RootState = store.getState();
    const activitiesFromState = state.Overview.day.activities;
    const activityFromState = activitiesFromState?.find((a) => a.id === activityId);

    if (activityFromState && activitiesFromState) {
      const url = API_URL + Endpoints.activity + activityId;

      const { status, message } = yield call(fetchData, url, httpMethod.delete);

      if (status === ApiResponseStatus.success) {
        let updatedActivitiesList: Activity[];

        updatedActivitiesList = activitiesFromState.filter((a) => a.id !== activityId);

        yield put(deleteActivitySuccess(updatedActivitiesList));
      } else {
        yield put(deleteActivityFailure(message));
      }
    } else {
      yield put(deleteActivityFailure("An unexpected error has occured, please try again later"));
    }
  } catch (error) {
    yield put(deleteActivityFailure());
  }
}

export function* overviewWatcher() {
  yield takeLatest(GET_DAY_BY_DATE, getDayByDateSaga);
  yield takeLatest(UPDATE_DAY_SUMMARY, updateDaySummarySaga);
  yield takeLatest(CREATE_ACTIVITIES_FROM_PLAN, createActivitiesFromPlanSaga);
  yield takeLatest(CREATE_ACTIVITY, createActivitySaga);
  yield takeLatest(UPDATE_ACTIVITY, updateActivitySaga);
  yield takeLatest(DELETE_ACTIVITY, deleteActivitySaga);
}

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { DAYS, UNIT_OF_MEASURE } from "../../../../utils/constants";
import { AppColors, Unit } from "../../../../utils/enums";

export const formatActive = (value: boolean) => {
  return value ? (
    <CheckIcon style={{ fill: AppColors.complete }} />
  ) : (
    <CloseIcon style={{ fill: AppColors["red-light"] }} />
  );
};

export const formatRepeatDays = (days: number[]) => {
  let formattedRepeatDays: string = "";

  days.map((day) => {
    return (formattedRepeatDays += DAYS[day].value + " ");
  });

  return formattedRepeatDays;
};

export const formatRepeatFrequency = (expectedMeasure: number, unitOfMeasure: number) => {
  const unit = UNIT_OF_MEASURE[unitOfMeasure]?.value;

  if (!unit) return "";

  const formattedRepeatFrequency = unit === Unit.time ? formatDuration(expectedMeasure) : `${expectedMeasure} times`;

  return formattedRepeatFrequency;
};

export const formatDuration = (duration: number) => {
  if (duration < 1) {
    return `${duration * 60} minutes`;
  }

  if (duration === 1) {
    return "1 hour";
  }

  let hours = Math.floor(duration);
  let minutes = duration % hours;

  if (minutes === 0) {
    return `${hours} hours`;
  }

  return `${hours} hours ${minutes * 60} minutes`;
};

import { Reducer } from "@reduxjs/toolkit";
import { DaysObject } from "../../../utils/types";
import {
  CREATE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_SUCCESS,
  GET_DAYS_BY_DATE_RANGE_SUCCESS,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_DAY_SUMMARY_SUCCESS,
  RESET_DAY_HAS_CHANGED,
} from "./actionTypes";

export interface HistoryState {
  days: DaysObject;
  latestStartDate: string;
  latestEndDate: string;
  dayHasChanged: boolean;
}

export const initialState: HistoryState = {
  days: {},
  latestStartDate: new Date().toISOString(),
  latestEndDate: new Date().toISOString(),
  dayHasChanged: false,
};

export const HistoryReducer: Reducer<HistoryState> = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_DAYS_BY_DATE_RANGE_SUCCESS:
      return {
        ...state,
        days: { ...state.days, ...action.days },
        latestEndDate: state.latestEndDate > action.endDate ? state.latestEndDate : action.endDate,
        latestStartDate: state.latestStartDate < action.startDate ? state.latestStartDate : action.startDate,
      };
    case CREATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        days: action.days,
        dayHasChanged: true,
      };

    case UPDATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        days: action.days,
        dayHasChanged: true,
      };
    case DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        days: action.days,
        dayHasChanged: true,
      };
    case UPDATE_DAY_SUMMARY_SUCCESS:
      return {
        ...state,
        days: action.days,
      };
    case RESET_DAY_HAS_CHANGED:
      return {
        ...state,
        dayHasChanged: false,
      };
    default:
      return state;
  }
};

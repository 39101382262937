import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import {
  createActivity,
  deleteActivity,
  getDaysByDateRange,
  updateActivity,
  updateDaySummary,
} from "../../../../store/areas/history/actions";
import { resetDayHasChanged } from "../../../../store/areas/overview/actions";
import { RootState } from "../../../../store/rootReducer";
import { Activity } from "../../../../utils/types";
import Calendar, { CalendarProps } from "./Calendar";

const mapStateToProps = (state: RootState): Partial<CalendarProps> => ({
  days: state.History.days,
  target: state.Targets.target,
  latestStartDate: state.History.latestStartDate,
  latestEndDate: state.History.latestEndDate,
  dayHasChanged: state.Overview.dayHasChanged,
});

const mapDispatchToProps = (dispatch: any) => ({
  getDaysByDateRange: (startDate: string, endDate: string) => dispatch(getDaysByDateRange(startDate, endDate)),
  createActivity: (activity: Activity) => dispatch(createActivity(activity)),
  updateActivity: (activity: Activity, dayObjectKey: string) => dispatch(updateActivity(activity, dayObjectKey)),
  deleteActivity: (activityId: string, dayObjectKey: string) => dispatch(deleteActivity(activityId, dayObjectKey)),
  resetDayHasChanged: () => dispatch(resetDayHasChanged()),
  updateDaySummary: (summary: string, dayObjectKey: string) => dispatch(updateDaySummary(summary, dayObjectKey)),
});

const CalendarContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Calendar as React.ComponentType<any>);

export default CalendarContainer;

import React from "react";
import { AppBar, Dialog, Toolbar, IconButton, Typography, Slide } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { TransitionProps } from "@material-ui/core/transitions";
import AppDialogStyles from "./AppDialogStyle";

export interface AppDialogProps {
  isOpen: boolean;
  handleClose?: () => void;
  fullScreen?: boolean;
  headerTitle?: string;
  headerButton?: JSX.Element;
  dialogContent?: JSX.Element;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppDialog: React.FunctionComponent<AppDialogProps> = ({
  isOpen,
  handleClose,
  fullScreen,
  headerTitle,
  headerButton,
  dialogContent,
}) => {
  const classes = AppDialogStyles();

  return (
    <Dialog fullScreen={fullScreen} open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
      {fullScreen && (
        <AppBar elevation={0} className={classes.appBar}>
          <Toolbar>
            <IconButton className={classes.closeIcon} edge="start" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {headerTitle}
            </Typography>
            {headerButton}
          </Toolbar>
        </AppBar>
      )}
      <div className={classes.dialogContent}>{dialogContent}</div>
    </Dialog>
  );
};

export default AppDialog;

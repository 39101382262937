import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import AppTransferListStyles from "./AppTransferListStyle";

export interface AppTransferListItem {
  id: number;
  key: string;
  primary: string;
  additionalInfo?: any;
}

function not(a: AppTransferListItem[], b: AppTransferListItem[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: AppTransferListItem[], b: AppTransferListItem[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: AppTransferListItem[], b: AppTransferListItem[]) {
  return [...a, ...not(b, a)];
}

export interface AppTransferListProps {
  transferListItems: AppTransferListItem[];
  leftItemTitle: string;
  leftItems: AppTransferListItem[];
  setLeftItems: (items: AppTransferListItem[]) => void;
  rightItemTitle: string;
  rightItems: AppTransferListItem[];
  setRightItems: (items: AppTransferListItem[]) => void;
}

const AppTransferList: React.FunctionComponent<AppTransferListProps> = ({
  transferListItems,
  leftItemTitle,
  leftItems,
  setLeftItems,
  rightItemTitle,
  rightItems,
  setRightItems,
}) => {
  const classes = AppTransferListStyles();

  const [checked, setChecked] = React.useState<AppTransferListItem[]>([]);

  const leftChecked = intersection(checked, leftItems);
  const rightChecked = intersection(checked, rightItems);

  const handleToggle = (value: AppTransferListItem) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: AppTransferListItem[]) => intersection(checked, items).length;

  const handleToggleAll = (items: AppTransferListItem[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRightItems(rightItems.concat(leftChecked));
    setLeftItems(not(leftItems, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeftItems(leftItems.concat(rightChecked));
    setRightItems(not(rightItems, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title: React.ReactNode, items: AppTransferListItem[]) => (
    <Card elevation={3} className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            color="primary"
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={<Typography variant="subtitle2">{title}</Typography>}
        subheader={<Typography variant="body2">{`${numberOfChecked(items)}/${items.length} selected`}</Typography>}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((item: AppTransferListItem) => {
          const labelId = `transfer-list-all-item-${item}-label`;

          return (
            <ListItem key={item.id} role="listitem" button onClick={handleToggle(item)}>
              <ListItemIcon>
                <Checkbox
                  color="primary"
                  checked={checked.indexOf(item) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={
                  <Typography className={classes.transferListPrimaryItem} variant="body2">
                    {item.primary}
                  </Typography>
                }
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item>{customList(leftItemTitle, leftItems)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(rightItemTitle, rightItems)}</Grid>
    </Grid>
  );
};

export default AppTransferList;

import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import Feedback, { FeedbackProps } from "./Feedback";

const mapStateToProps = (state: RootState): Partial<FeedbackProps> => ({});

const mapDispatchToProps = (dispatch: any) => ({});

const FeedbackContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Feedback as React.ComponentType<any>);

export default FeedbackContainer;

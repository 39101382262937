import { Typography } from "@material-ui/core";
import React from "react";
import QuoteStyles from "./QuoteStyle";
import quoteImage from "../../../../assets/images/illustrations/quote.svg";
import { Quote } from "../../../../utils/types";

export interface QuoteProps {
  quote: Quote;
}

const DailyQuote: React.FunctionComponent<QuoteProps> = ({ quote }) => {
  const classes = QuoteStyles();

  return (
    <div className={classes.quoteWrap}>
      <img className={classes.quoteBackground} src={quoteImage} alt="quote" />
      <div className={classes.quote}>
        <Typography className={classes.quoteHeader} variant="h6">
          Words of Inspiration
        </Typography>
        <Typography className={classes.quoteContent} variant="body2">
          {quote.body}
        </Typography>
        <Typography className={classes.quoteContent} variant="body2">
          - {quote.author}
        </Typography>
      </div>
    </div>
  );
};

export default DailyQuote;

import { AnyAction, combineReducers, Reducer } from "@reduxjs/toolkit";
import { FaqsReducer, FaqsState } from "./areas/faqs/reducer";
import { FeedReducer, FeedState } from "./areas/feed/reducer";
import { HistoryReducer, HistoryState } from "./areas/history/reducer";
import { LOGOUT_USER } from "./areas/home/actionTypes";
import { HomeReducer, HomeState } from "./areas/home/reducer";
import { OverviewReducer, OverviewState } from "./areas/overview/reducer";
import { SettingsReducer, SettingsState } from "./areas/settings/reducer";
import { SnackbarReducer, SnackbarState } from "./areas/snackbar/reducer";
import { SpinnerReducer, SpinnerState } from "./areas/spinner/reducer";
import { TargetsReducer, TargetsState } from "./areas/targets/reducer";

export interface RootState {
  Home: HomeState;
  Overview: OverviewState;
  Targets: TargetsState;
  History: HistoryState;
  Feed: FeedState;
  Faqs: FaqsState;
  Settings: SettingsState;
  Snackbar: SnackbarState;
  Spinner: SpinnerState;
}

const appReducer: Reducer<RootState> = combineReducers<RootState>({
  Home: HomeReducer,
  Overview: OverviewReducer,
  Targets: TargetsReducer,
  History: HistoryReducer,
  Feed: FeedReducer,
  Faqs: FaqsReducer,
  Settings: SettingsReducer,
  Snackbar: SnackbarReducer,
  Spinner: SpinnerReducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === LOGOUT_USER) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;

import { Grid } from "@material-ui/core";
import React from "react";
import AppCard from "../../../components/AppCard/AppCard";
import HistoryStyles from "./HistoryStyle";
import CalendarContainer from "./Calendar/CalendarContainer";

export interface HistoryProps {}

const History: React.FunctionComponent<HistoryProps> = () => {
  const classes = HistoryStyles();

  const calendar = <CalendarContainer />;

  return (
    <section className={classes.root}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} md={12} className={classes.gridItem}>
          <div className={classes.calendarCard}>
            <AppCard content={calendar} />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default History;

import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { AppColors, AppFonts, FontWeight } from "./enums";
import Lato from "./../assets/fonts/Lato-Regular.ttf";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const lato: CSSProperties = {
  fontFamily: "Lato",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    url(${Lato}) format('truetype')
  `,
};

let theme = createMuiTheme({
  palette: {
    primary: {
      main: AppColors.main,
      dark: AppColors.main,
    },
    secondary: {
      main: AppColors["red-dark"],
      dark: AppColors["red-dark"],
    },
  },
  typography: {
    fontFamily: AppFonts.main,
    fontWeightBold: FontWeight.bold,
    fontWeightRegular: FontWeight.regular,
    allVariants: {
      color: AppColors["background-dark"],
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": lato,
      },
    },
  },
});

theme = responsiveFontSizes(theme);
export default theme;

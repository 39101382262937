import { Reducer } from "@reduxjs/toolkit";
import { CLOSE_SNACKBAR } from "./actionTypes";

export interface SnackbarState {
  open: boolean;
  variant: "success" | "error";
  message: string;
}

const initialState: SnackbarState = {
  open: false,
  variant: "error",
  message: "",
};

export const SnackbarReducer: Reducer<SnackbarState> = (state = initialState, action: any) => {
  const { type, message } = action;

  switch (action.type) {
    case CLOSE_SNACKBAR:
      return {
        ...state,
        open: false,
      };
    default:
      if (message === undefined) {
        return state;
      }
      const matches = /(SUCCESS|FAILURE)_?/.exec(type);
      if (!matches) return state;

      const [requestName] = matches;

      if (!requestName) return state;

      return {
        ...state,
        open: true,
        message,
        variant: requestName === "SUCCESS" ? "success" : "error",
      };
  }
};

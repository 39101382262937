import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors } from "../../../../utils/enums";

const ActivityChartStyles = makeStyles((theme: Theme) =>
  createStyles({
    activityChartWrap: {
      flex: 1,
      height: "100%",
    },
    activityChartHeaderTitle: {
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
    },
    activityChart: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    legend: {},
    activityChartLabelHeader: {
      fontSize: 12,
      fill: AppColors["grey-dark"],
    },
    activityChartLabelBody: {
      fontSize: 24,
      fill: AppColors["background-dark"],
    },
    activityChartNoDataContainer: {
      display: "flex",
      height: "80%",
      justifyContent: "center",
      alignItems: "center",
    },
    activityChartNoDataText: {
      flex: 1,
      textAlign: "center",
      fontSize: 14,
      color: AppColors["grey-dark"],
    },
  })
);

export default ActivityChartStyles;

import { Button, Typography } from "@material-ui/core";
import React from "react";
import SubscriptionDialogStyles from "./SubscriptionDialogStyle";

export interface CancelDialogProps {
  handleClose: () => void;
  cancelUserSubscription: () => void;
}

const CancelDialog: React.FunctionComponent<CancelDialogProps> = ({ handleClose, cancelUserSubscription }) => {
  const classes = SubscriptionDialogStyles();

  return (
    <div className={classes.paymentDialogWrap}>
      <Typography className={classes.dialogHeaderText} variant="body1">
        {"Are you sure you want to cancel your subscription?"}
      </Typography>
      <Typography className={classes.cancelBody} variant="subtitle2">
        {"You will still have access to your plan until the expiry date"}
      </Typography>
      <div className={classes.actionButtons}>
        <div className={classes.actionButtonsRight}>
          <Button variant="contained" size="small" className={classes.cancelButton} onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className={classes.saveButton}
            onClick={cancelUserSubscription}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CancelDialog;

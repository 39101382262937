import { httpMethod } from "./enums";
import firebase from "firebase/app";
import "firebase/auth";

export async function fetchData(url: string, method: httpMethod, data: any = undefined) {
  const token = await firebase.auth().currentUser?.getIdToken(true);

  const response = await fetch(url, {
    method: method,
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });

  return response.json();
}

import { Typography } from "@material-ui/core";
import React from "react";
import { Month } from "../../../../utils/types";
import TargetsSummaryStyles from "./TargetsSummaryStyle";
import { Legend, PolarAngleAxis, RadialBarChart, RadialBar, ResponsiveContainer } from "recharts";
import { AppColors } from "../../../../utils/enums";
import { MONTHS } from "../../../../utils/constants";

export interface TargetsSummaryProps {
  month: Month;
}

const TargetsSummary: React.FunctionComponent<TargetsSummaryProps> = ({ month }) => {
  const classes = TargetsSummaryStyles();
  const monthTargets = month.monthTargets?.filter((mt) => !mt.deleted) ?? [];

  const totalTargets = monthTargets.length;

  const weightPerTarget = Number((100 / totalTargets).toFixed(2));

  const reducer = (accumulator: number, currentValue: number) =>
    accumulator + Number(((currentValue / 100) * weightPerTarget).toFixed(2));

  const allPercentageComplete = monthTargets.map((target) => target.percentageComplete);

  const percentageComplete = allPercentageComplete.length > 0 ? allPercentageComplete.reduce(reducer, 0) : 0;

  const data = [{ value: percentageComplete, fill: AppColors.main }];

  const percentageCompletePerTarget = monthTargets.map((target) => target.percentageComplete);

  const strongestTarget =
    monthTargets.find(
      (target) =>
        target.percentageComplete > 0 && target.percentageComplete === Math.max(...percentageCompletePerTarget)
    )?.name ?? "N/A";
  const weakestTarget =
    monthTargets.find((target) => target.percentageComplete === Math.min(...percentageCompletePerTarget))?.name ??
    "N/A";

  const strongestAndWeakestAreas = [
    { value: `Strongest Area - ${strongestTarget}`, id: "1", color: AppColors.complete },
    { value: `Weakest Area - ${weakestTarget}`, id: "2", color: AppColors.amber },
  ];

  const displayMonth = MONTHS[month.month].value;

  return (
    <div className={classes.targetsSummaryWrap}>
      <Typography className={classes.targetsSummaryHeaderTitle} variant="h6">
        Summary
      </Typography>

      {totalTargets === 0 && (
        <div className={classes.targetsSummaryNoDataContainer}>
          <Typography className={classes.targetsSummaryNoDataText}>No data available</Typography>
        </div>
      )}

      {totalTargets > 0 && (
        <div className={classes.targetsSummary}>
          <div className={classes.targetsSummaryChart}>
            <ResponsiveContainer>
              <RadialBarChart innerRadius={120} outerRadius={130} startAngle={90} endAngle={450} data={data}>
                <PolarAngleAxis type="number" domain={[0, 100]} dataKey={"value"} angleAxisId={0} tick={false} />
                <text className={classes.targetsSummaryLabelBody} x="50%" y="48%" textAnchor="middle">
                  {percentageComplete.toFixed(2)}%
                </text>
                <text className={classes.targetsSummaryLabelHeader} x="50%" y="52%" textAnchor="middle">
                  across all targets
                </text>
                <text className={classes.targetsSummaryLabelPeriod} x="50%" y="58%" textAnchor="middle">
                  {displayMonth} {month.year}
                </text>
                <RadialBar dataKey="value" data={data} background angleAxisId={0} />
                <Legend
                  align="center"
                  verticalAlign="bottom"
                  height={30}
                  iconType="circle"
                  iconSize={8}
                  layout="vertical"
                  payload={strongestAndWeakestAreas}
                />
              </RadialBarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default TargetsSummary;

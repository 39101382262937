import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import AppCard from "../../../components/AppCard/AppCard";
import FeedbackStyles from "./FeedbackStyle";
import { CONTACT_EMAIL_ADDRESS } from "../../../config/config";

export interface FeedbackProps {}

const Feedback: React.FunctionComponent<FeedbackProps> = () => {
  const classes = FeedbackStyles();

  const [feedback, setFeedback] = React.useState({ subject: "", message: "" });

  const feedbackForm = () => {
    return (
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.headerItem}>
          <Typography className={classes.feedbackHeader} variant="body1">
            We would love to hear any suggestions, improvements or questions you may have about the product 😃
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
          <div className={classes.feedbackForm}>
            <TextField
              className={classes.inputField}
              id="subject"
              label="Subject"
              value={feedback.subject}
              variant="outlined"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFeedback({ ...feedback, subject: e.target.value })
              }
            />
            <TextField
              multiline
              rows={4}
              className={classes.inputField}
              id="message"
              label="Enter your message"
              value={feedback.message}
              variant="outlined"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFeedback({ ...feedback, message: e.target.value })
              }
            />
            <Button
              href={`mailto:${CONTACT_EMAIL_ADDRESS}?subject=${feedback.subject}&body=${feedback.message}`}
              variant="contained"
              color="primary"
              disabled={!feedback.message || !feedback.subject}
            >
              Submit
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
          <div className={classes.feedbackImageWrap} />
        </Grid>
      </Grid>
    );
  };

  return (
    <section className={classes.root}>
      <AppCard content={feedbackForm()} />
    </section>
  );
};

export default Feedback;

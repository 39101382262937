import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors, FontWeight } from "../../utils/enums";

const AppHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.common.white,
      position: "fixed",
    },
    toolbar: {
      justifyContent: "space-between",
    },
    left: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    right: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
    },
    rightLink: {
      fontWeight: FontWeight.bold,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      "&:hover": {
        backgroundColor: AppColors.main,
        color: theme.palette.common.white,
      },
    },
    logo: {
      maxHeight: 30,
      maxWidth: 30,
    },
    title: {
      color: AppColors["background-dark"],
      fontWeight: FontWeight.bold,
      marginLeft: theme.spacing(1),
    },
  })
);

export default AppHeaderStyles;

import { Activity } from "../types";

export const mapToSingleActivity = (activity: any) => {
  const mappedActivity: Activity = {
    id: activity._id,
    user: activity.user,
    target: activity.target,
    day: activity.day,
    month: activity.month,
    date: activity.date,
    name: activity.name,
    description: activity.description,
    previousMeasure: activity.previousMeasure,
    currentMeasure: activity.currentMeasure,
    expectedMeasure: activity.expectedMeasure,
    percentageComplete: activity.percentageComplete,
    reminder: activity.reminder,
    completed: activity.completed,
  };

  return mappedActivity;
};

export const mapToMultipleActivities = (activities: any) => {
  const mappedActivitiesList: Activity[] = activities.map((activity: any) => ({
    id: activity._id,
    user: activity.user,
    target: activity.target,
    day: activity.day,
    month: activity.month,
    date: activity.date,
    name: activity.name,
    description: activity.description,
    previousMeasure: activity.previousMeasure,
    currentMeasure: activity.currentMeasure,
    expectedMeasure: activity.expectedMeasure,
    percentageComplete: activity.percentageComplete,
    reminder: activity.reminder,
    completed: activity.completed,
  }));

  return mappedActivitiesList;
};

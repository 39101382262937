import { AppDrawerItem } from "../../components/AppDrawer/AppDrawer";
import OverviewContainer from "./Overview/OverviewContainer";
import TargetsContainer from "./Targets/TargetsContainer";
import HistoryContainer from "./History/HistoryContainer";
import Accountability from "./Accountability/Accountability";
import SettingsContainer from "./Settings/SettingsContainer";
import FeedContainer from "./Feed/FeedContainer";
import FaqsContainer from "./Faqs/FaqsContainer";
import FeedbackContainer from "./Feedback/FeedbackContainer";

import VisibilityIcon from "@material-ui/icons/Visibility";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import TimelineIcon from "@material-ui/icons/Timeline";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import NotesIcon from "@material-ui/icons/Notes";
import HelpIcon from "@material-ui/icons/Help";
import SettingsIcon from "@material-ui/icons/Settings";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";

import { Pages } from "../../utils/enums";

export const appDrawerItems: AppDrawerItem[] = [
  { id: "overview", title: Pages.overview, icon: <VisibilityIcon />, content: <OverviewContainer /> },
  { id: "targets", title: Pages.targets, icon: <TrackChangesIcon />, content: <TargetsContainer /> },
  { id: "history", title: Pages.history, icon: <TimelineIcon />, content: <HistoryContainer /> },
  { id: "feed", title: Pages.feed, icon: <NotesIcon />, content: <FeedContainer /> },
  {
    id: "accountability",
    title: Pages.accountability,
    icon: <SupervisorAccountIcon />,
    content: <Accountability />,
    divider: true,
  },
  { id: "settings", title: Pages.settings, icon: <SettingsIcon />, content: <SettingsContainer /> },
  { id: "faqs", title: Pages.faqs, icon: <HelpIcon />, content: <FaqsContainer /> },
  {
    id: "feedback",
    title: Pages.feedback,
    icon: <AlternateEmailIcon />,
    content: <FeedbackContainer />,
    divider: true,
  },
];

import { createStyles, makeStyles, Theme } from "@material-ui/core";

const TransactionsStyles = makeStyles((theme: Theme) =>
  createStyles({
    transactionsWrap: {
      flex: 1,
      height: "100%",
    },
    transactionsHeader: {
      display: "flex",
    },
    transactionsHeaderTitle: {
      display: "flex",
    },
    transactions: {
      paddingTop: theme.spacing(3),
      width: "100%",
    },
    transactionsText: {},
  })
);

export default TransactionsStyles;

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors, FontWeight } from "../../utils/enums";

const AppFooterStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      position: "relative",
      backgroundColor: AppColors["background-dark"],
    },
    container: {
      marginTop: theme.spacing(2.5),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    gridLinks: {
      flexDirection: "row",
      display: "flex",
    },
    gridItem: {
      margin: theme.spacing(2.5, 5),
    },
    linkItem: {
      color: theme.palette.common.white,
      padding: theme.spacing(0, 2),
    },
    copyright: {
      color: theme.palette.common.white,
    },
    cardInfoWrap: {
      minWidth: 300,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    cardInfoTextTitle: {
      flex: 3,
      fontWeight: FontWeight.bold,
      textAlign: "center",
      marginBottom: theme.spacing(1),
    },
    cardInfoText: {
      width: "100%",
      flex: 1,
      margin: theme.spacing(1),
    },
    avatar: {
      minWidth: 100,
      minHeight: 100,
      marginBottom: theme.spacing(1),
    },
    connect: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
    linkedIn: {
      minWidth: 30,
      minHeight: 30,
      color: "#0072b1",
    },
  })
);

export default AppFooterStyles;

export const GET_DAY_BY_DATE = "OVERVIEW/GET_DAY_BY_DATE";
export const GET_DAY_BY_DATE_SUCCESS = "OVERVIEW/GET_DAY_BY_DATE_SUCCESS";
export const GET_DAY_BY_DATE_FAILURE = "OVERVIEW/GET_DAY_BY_DATE_FAILURE";

export const UPDATE_DAY_SUMMARY = "OVERVIEW/UPDATE_DAY_SUMMARY";
export const UPDATE_DAY_SUMMARY_SUCCESS = "OVERVIEW/UPDATE_DAY_SUMMARY_SUCCESS";
export const UPDATE_DAY_SUMMARY_FAILURE = "OVERVIEW/UPDATE_DAY_SUMMARY_FAILURE";

export const CREATE_ACTIVITY = "OVERVIEW/CREATE_ACTIVITY";
export const CREATE_ACTIVITY_SUCCESS = "OVERVIEW/CREATE_ACTIVITY_SUCCESS";
export const CREATE_ACTIVITY_FAILURE = "OVERVIEW/CREATE_ACTIVITY_FAILURE";

export const CREATE_ACTIVITIES_FROM_PLAN = "OVERVIEW/CREATE_ACTIVITIES_FROM_PLAN";
export const CREATE_ACTIVITIES_FROM_PLAN_SUCCESS = "OVERVIEW/CREATE_ACTIVITIES_FROM_PLAN_SUCCESS";
export const CREATE_ACTIVITIES_FROM_PLAN_FAILURE = "OVERVIEW/CREATE_ACTIVITIES_FROM_PLAN_FAILURE";

export const UPDATE_ACTIVITY = "OVERVIEW/UPDATE_ACTIVITY";
export const UPDATE_ACTIVITY_SUCCESS = "OVERVIEW/UPDATE_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY_FAILURE = "OVERVIEW/UPDATE_ACTIVITY_FAILURE";

export const DELETE_ACTIVITY = "OVERVIEW/DELETE_ACTIVITY";
export const DELETE_ACTIVITY_SUCCESS = "OVERVIEW/DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAILURE = "OVERVIEW/DELETE_ACTIVITY_FAILURE";

export const SET_QUOTE = "OVERVIEW/SET_QUOTE";

export const RESET_DAY_HAS_CHANGED = "OVERVIEW/RESET_DAY_HAS_CHANGED";

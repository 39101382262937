export const LOGIN_USER = "HOME/LOGIN_USER";
export const LOGIN_USER_SUCCESS = "HOME/LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "HOME/LOGIN_USER_FAILURE";

export const UPDATE_USER_RITUALS = "HOME/UPDATE_USER_RITUALS";
export const UPDATE_USER_RITUALS_SUCCESS = "HOME/UPDATE_USER_RITUALS_SUCCESS";
export const UPDATE_USER_RITUALS_FAILURE = "HOME/UPDATE_USER_RITUALS_FAILURE";

export const UPDATE_USER_NOTIFICATIONS = "HOME/UPDATE_USER_NOTIFICATIONS";
export const UPDATE_USER_NOTIFICATIONS_SUCCESS = "HOME/UPDATE_USER_NOTIFICATIONS_SUCCESS";
export const UPDATE_USER_NOTIFICATIONS_FAILURE = "HOME/UPDATE_USER_NOTIFICATIONS_FAILURE";

export const PROVISION_USER_SUBSCRIPTION = "HOME/PROVISION_USER_SUBSCRIPTION";
export const PROVISION_USER_SUBSCRIPTION_SUCCESS = "HOME/PROVISION_USER_SUBSCRIPTION_SUCCESS";
export const PROVISION_USER_SUBSCRIPTION_FAILURE = "HOME/PROVISION_USER_SUBSCRIPTION_FAILURE";

export const CANCEL_USER_SUBSCRIPTION = "HOME/CANCEL_USER_SUBSCRIPTION";
export const CANCEL_USER_SUBSCRIPTION_SUCCESS = "HOME/CANCEL_USER_SUBSCRIPTION_SUCCESS";
export const CANCEL_USER_SUBSCRIPTION_FAILURE = "HOME/CANCEL_USER_SUBSCRIPTION_FAILURE";

export const LOGOUT_USER = "HOME/LOGOUT_USER";

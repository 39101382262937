import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { FontWeight } from "../../../../utils/enums";

const SubscriptionStyles = makeStyles((theme: Theme) =>
  createStyles({
    subscriptionWrap: {
      flex: 1,
      height: "100%",
    },
    subscriptionHeader: {
      display: "flex",
    },
    subscriptionHeaderTitle: {
      display: "flex",
    },
    subscriptionHeaderButtonContainer: {
      flex: 1,
    },
    subscriptionHeaderButton: {
      fontWeight: FontWeight.bold,
      float: "right",
    },
    yourPlan: {
      paddingTop: theme.spacing(3),
      width: "100%",
    },
    additionalInfo: {
      paddingTop: theme.spacing(3),
      width: "100%",
    },
    additionalInfotext: {},
    subscriptionText: {},
  })
);

export default SubscriptionStyles;

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { FontWeight, AppColors } from "../../../../utils/enums";

const TargetsListStyles = makeStyles((theme: Theme) =>
  createStyles({
    targetsListWrap: {
      flex: 1,
      height: "100%",
    },
    targetsListHeader: {
      display: "flex",
    },
    targetsListHeaderTitle: {
      flex: 1,
    },
    targetsListHeaderButtonContainer: {
      flex: 1,
    },
    targetsListHeaderAddButton: {
      fontWeight: FontWeight.bold,
      float: "right",
    },
    targetsListNoDataContainer: {
      display: "flex",
      height: "80%",
      justifyContent: "center",
      alignItems: "center",
    },
    targetsListNoDataText: {
      flex: 1,
      textAlign: "center",
      fontSize: 14,
      color: AppColors["grey-dark"],
    },
    targetsListTableContainer: {
      paddingTop: theme.spacing(2),
    },
    editActionButton: {},
  })
);

export default TargetsListStyles;

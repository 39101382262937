import { fork } from "redux-saga/effects";
import { historyWatcher } from "./areas/history/sagas";
import { homeWatcher } from "./areas/home/sagas";
import { overviewWatcher } from "./areas/overview/sagas";
import { settingsWatcher } from "./areas/settings/sagas";
import { targetsWatcher } from "./areas/targets/sagas";

export default function* rootSaga() {
  yield fork(homeWatcher);
  yield fork(overviewWatcher);
  yield fork(targetsWatcher);
  yield fork(historyWatcher);
  yield fork(settingsWatcher);
}

import { Faq } from "../types";

const faq1: Faq = {
  id: "faq-001",
  header: "Targets",
  title: "How are my targets calculated?",
  body: "Targets can be measured in two ways, time and repetitions. Each time you create a new activity, you have the option to link the activity to a target and any progress made will reflect in your target expectation each month.",
};

const faq2: Faq = {
  id: "faq-002",
  header: "History",
  title: "How can I update my data for past activities?",
  body: "To update data for past activities, simply head to the History section of the app and this will show you a calendar view of all your activities. You can also update any daily journal entries or add in entries for past days.",
};

const faq3: Faq = {
  id: "faq-003",
  header: "Export",
  title: "Is there any way I can export or share my data?",
  body: "At the moment there is no way to do this but this feature is planned for a future iteration of the product.",
};

const faq4: Faq = {
  id: "faq-004",
  header: "Activity",
  title: "What does it mean to link an activity to a target?",
  body: "Linking an activity to a target allows you to make an individual contribution to your monthly goal. For example if you would like to workout 15 times each month then each workout you complete will be an activity linked to your workout target.",
};

export const faqs: Faq[] = [faq1, faq2, faq3, faq4];

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { FontWeight } from "../../../utils/enums";

const InAppSamplesStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      marginBottom: 0,
      display: "flex",
      justifyContent: "center",
    },
    sectionDescription: {
      textAlign: "center",
      marginBottom: theme.spacing(3),
      fontWeight: FontWeight.bold,
    },
    container: {
      margin: theme.spacing(5),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    screenshot: {
      marginBottom: theme.spacing(5),
      marginLeft: theme.spacing(2),
      maxWidth: "100%",
      maxHeight: "100%",
    },
    descriptionContainer: {
      flex: 1,
      margin: theme.spacing(3),
    },
    title: {
      fontWeight: FontWeight.bold,
    },
    subTitle: {
      textAlign: "center",
    },
  })
);

export default InAppSamplesStyles;

import { Typography, Button } from "@material-ui/core";
import React from "react";
import AppList, { AppListItem } from "../../../../components/AppList/AppList";
import { UserNotification } from "../../../../utils/types";
import NotificationsStyles from "./NotificationsStyle";

export interface NotificationsProps {
  userNotification: UserNotification;
  updateUserNotifications: (userNotification: UserNotification) => void;
}

const Notifications: React.FunctionComponent<NotificationsProps> = ({ userNotification, updateUserNotifications }) => {
  const classes = NotificationsStyles();

  const [notifications, setNotifications] = React.useState<UserNotification>(userNotification);

  const listItems: AppListItem[] = [
    {
      id: "articles",
      primary: "Notify me when a new article is available in my feed",
      checked: notifications.articles,
    },
    {
      id: "challenges",
      primary: "Notify me when a new community challenge is available in my feed",
      checked: notifications.challenges,
    },
  ];

  const handleUpdate = (item: string) => {
    if (item === "articles") {
      setNotifications({ ...notifications, articles: !notifications.articles });
    } else {
      setNotifications({ ...notifications, challenges: !notifications.challenges });
    }
  };

  return (
    <div className={classes.notificationsWrap}>
      <div className={classes.notificationsHeader}>
        <Typography className={classes.notificationsHeaderTitle} variant="h6">
          Notifications
        </Typography>

        <div className={classes.notificationsHeaderButtonContainer}>
          <Button
            disabled={
              notifications.articles === userNotification.articles &&
              notifications.challenges === userNotification.challenges
            }
            color="primary"
            onClick={() => updateUserNotifications(notifications)}
            variant="contained"
            size="small"
            className={classes.notificationsHeaderButton}
          >
            Save
          </Button>
        </div>
      </div>
      <AppList
        listItemPrimaryAction={handleUpdate}
        useCheckedPrimaryIcon
        checkedPrimaryIcon={"switch"}
        listItems={listItems}
      />
    </div>
  );
};

export default Notifications;

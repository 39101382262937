import { Button, Container, Typography } from "@material-ui/core";
import React from "react";
import SplashStyles from "./SplashStyle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppDialog from "../../../components/AppDialog/AppDialog";
import LoginDialog from "../LoginDialog/LoginDialog";
import { uiConfig } from "../../../App";

export interface SplashProps {}

const Splash: React.FunctionComponent<SplashProps> = () => {
  const classes = SplashStyles();

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.splashText}>
          <Typography align="center" variant="h4" className={classes.title}>
            Make time for the things that matter
          </Typography>
          <Typography display="inline" align="center" variant="body1" className={classes.subTitle1}>
            Whether it's forming new habits, introducing structure into your day or tackling your goals and aspirations,
          </Typography>
          <Typography display="inline" align="center" variant="body1" className={classes.subTitle2}>
            Daily Schema helps you stay on track, develop consistency and streamline your life one day at a time.
          </Typography>
          <Button
            color="primary"
            variant="contained"
            size="medium"
            className={classes.button}
            onClick={() => setOpenDialog(true)}
          >
            Get Started
          </Button>
          <div className={classes.arrowDown}>
            <ExpandMoreIcon fontSize="large" />
          </div>
        </div>
      </Container>
      <AppDialog
        isOpen={openDialog}
        handleClose={handleDialogClose}
        dialogContent={<LoginDialog uiConfig={uiConfig} handleClose={handleDialogClose} />}
      />
    </section>
  );
};

export default Splash;

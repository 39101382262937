import { createStyles, makeStyles, Theme } from "@material-ui/core";

const LoginDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginDialogWrap: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      minWidth: 300,
    },
    logo: {
      maxHeight: 40,
      maxWidth: 40,
      marginBottom: theme.spacing(1),
    },
    dialogHeaderText: {
      paddingBottom: theme.spacing(2),
    },
    actionButtons: {},
  })
);

export default LoginDialogStyles;

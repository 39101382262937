export const arrayToObject = (array: any, keyField: string) =>
  array.reduce((obj: any, item: any) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

export const DateRegex = /T(.*)/i;

export const formatDateString = (date: Date | string) => {
  if (typeof date === "string") {
    return date.toString().replace(DateRegex, "");
  } else {
    const newDate = new Date(date);
    const month = newDate.getUTCMonth() + 1;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${newDate.getUTCFullYear()}-${formattedMonth}-${newDate.getUTCDate()}`;
  }
};

export const arrayToObjectWithDateKey = (array: any, keyField: string) =>
  array.reduce((obj: any, item: any) => {
    obj[item[keyField].replace(DateRegex, "")] = item;
    return obj;
  }, {});

import React from "react";
import AppDrawer from "../../components/AppDrawer/AppDrawer";
import { User } from "../../utils/types";
import { appDrawerItems } from "./HomeLayout";
import firebase from "firebase/app";
import "firebase/auth";

export interface HomeProps {
  user: User;
  logoutUser: () => void;
}

const Home: React.FunctionComponent<HomeProps> = ({ user, logoutUser }) => {
  const handleSignOut = async () => {
    await firebase.auth().signOut();
    logoutUser();
  };

  return (
    <React.Fragment>
      <AppDrawer
        appDrawerItems={appDrawerItems}
        userName={user.name}
        userImageUrl={user.imageUrl}
        handleSignOut={handleSignOut}
      />
    </React.Fragment>
  );
};

export default Home;

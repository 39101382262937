import { Quote } from "../types";

export const quotesCount = 10;

export const quotes: { [id: number]: Quote } = {
  0: {
    id: "0",
    body: "A goal without a plan is just a wish.",
    author: "Antoine de Saint-Exupéry",
  },
  1: {
    id: "1",
    body: "By failing to prepare, you are preparing to fail.",
    author: "Benjamin Franklin",
  },
  2: {
    id: "2",
    body: "Plan your work and work your plan.",
    author: "Napoleon Hill",
  },
  3: {
    id: "3",
    body: "The tragedy in life doesn’t lie in not reaching your goal. The tragedy lies in having no goal to reach.",
    author: "Benjamin E. Mays",
  },
  4: {
    id: "4",
    body: "A clear vision, backed by definite plans, gives you a tremendous feeling of confidence and personal power.",
    author: "Brian Tracy",
  },
  5: {
    id: "5",
    body: "A good plan today is better than a perfect plan tomorrow.",
    author: "George S. Patton",
  },
  6: {
    id: "6",
    body: "Always plan ahead, it wasn't raining when Noah built the Ark.",
    author: "Richard Cushing",
  },
  7: {
    id: "7",
    body: "Someone’s sitting in the shade today because someone planted a tree a long time ago.",
    author: "Warren Buffett",
  },
  8: {
    id: "8",
    body: "Our goals can only be reached through a vehicle of a plan, in which we must fervently believe, and upon which we must vigorously act. There is no other route to success.",
    author: "Pablo Picasso",
  },
  9: {
    id: "9",
    body: "Good fortune is what happens when opportunity meets with planning.",
    author: "Thomas Edison",
  },
};

import { createStyles, makeStyles, Theme } from "@material-ui/core";

const SettingsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    container: {
      display: "flex",
    },
    gridItem: {},
    subscriptionCard: {
      display: "flex",
    },
    notificationsCard: {
      display: "flex",
      height: 200,
    },
    transactionsCard: {
      display: "flex",
    },
  })
);

export default SettingsStyles;

import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { cancelUserSubscription, provisionUserSubscription } from "../../../../store/areas/home/actions";
import {
  verifyCustomerSubscription,
  resetCustomerSubscriptionVerified,
} from "../../../../store/areas/settings/actions";
import { RootState } from "../../../../store/rootReducer";
import Subscription, { SubscriptionProps } from "./Subscription";

const mapStateToProps = (state: RootState): Partial<SubscriptionProps> => ({
  userSubscription: state.Home.user.subscription,
  userName: state.Home.user.name,
  userEmail: state.Home.user.email,
  customerSubscriptionVerified: state.Settings.customerSubscriptionVerified,
  clientSecret: state.Settings.clientSecret,
  displayPrice: state.Settings.displayPrice,
});

const mapDispatchToProps = (dispatch: any) => ({
  verifyCustomerSubscription: () => dispatch(verifyCustomerSubscription()),
  resetCustomerSubscriptionVerified: () => dispatch(resetCustomerSubscriptionVerified()),
  provisionUserSubscription: () => dispatch(provisionUserSubscription()),
  cancelUserSubscription: () => dispatch(cancelUserSubscription()),
});

const SubscriptionContainer = compose(connect(mapStateToProps, mapDispatchToProps))(
  Subscription as React.ComponentType<any>
);

export default SubscriptionContainer;

import { AnyAction } from "@reduxjs/toolkit";
import {
  RESET_CUSTOMER_SUBSCRIPTION_VERIFIED,
  VERIFY_CUSTOMER_SUBSCRIPTION,
  VERIFY_CUSTOMER_SUBSCRIPTION_FAILURE,
  VERIFY_CUSTOMER_SUBSCRIPTION_SUCCESS,
} from "./actionTypes";

// VERIFY_CUSTOMER_SUBSCRIPTION

export const verifyCustomerSubscription = (): AnyAction => {
  return {
    type: VERIFY_CUSTOMER_SUBSCRIPTION,
  };
};

export const verifyCustomerSubscriptionSuccess = (clientSecret: string, displayPrice: number): AnyAction => {
  return {
    type: VERIFY_CUSTOMER_SUBSCRIPTION_SUCCESS,
    clientSecret,
    displayPrice,
  };
};

export const verifyCustomerSubscriptionFailure = (message?: string): AnyAction => {
  return {
    type: VERIFY_CUSTOMER_SUBSCRIPTION_FAILURE,
    message: message || "Unable to process payment, please try again later",
  };
};

export const resetCustomerSubscriptionVerified = (): AnyAction => {
  return {
    type: RESET_CUSTOMER_SUBSCRIPTION_VERIFIED,
  };
};

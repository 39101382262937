import React from "react";
import { Divider, List, ListItem, Switch, Typography, withStyles } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import AppListStyles from "./AppListStyle";
import { AppColors } from "../../utils/enums";

export const GreenCheckbox = withStyles({
  root: {
    color: AppColors["grey-dark"],
    "&$checked": {
      color: AppColors.complete,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const GreenSwitch = withStyles({
  switchBase: {
    color: AppColors["grey-light"],
    "&$checked": {
      color: AppColors.complete,
    },
    "&$checked + $track": {
      backgroundColor: AppColors.complete,
    },
  },
  checked: {},
  track: {},
})(Switch);

export interface AppListItem {
  id: string;
  checked?: boolean;
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  customComponent?: JSX.Element;
}

export interface AppListProps {
  listItems: AppListItem[];
  useDivider?: boolean;
  useCheckedPrimaryIcon?: boolean;
  checkedPrimaryIcon?: "checkbox" | "switch";
  listItemPrimaryIcon?: JSX.Element;
  listItemPrimaryAction?: (id: string) => void;
  listItemSecondaryIcon?: JSX.Element;
  listItemSecondaryAction?: (id: string) => void;
}

const AppList: React.FunctionComponent<AppListProps> = ({
  listItems,
  useDivider,
  listItemPrimaryIcon,
  useCheckedPrimaryIcon,
  checkedPrimaryIcon,
  listItemPrimaryAction,
  listItemSecondaryIcon,
  listItemSecondaryAction,
}) => {
  const classes = AppListStyles();

  return (
    <List className={classes.root}>
      {listItems.map((item) => {
        const labelId = `checkbox-list-label-${item.id}`;

        return (
          <div key={item.id}>
            <ListItem dense>
              {listItemPrimaryIcon ? (
                <ListItemIcon onClick={() => listItemPrimaryAction?.(item.id)}>{listItemPrimaryIcon}</ListItemIcon>
              ) : (
                useCheckedPrimaryIcon && (
                  <ListItemIcon onClick={() => listItemPrimaryAction?.(item.id)}>
                    {checkedPrimaryIcon === "switch" ? (
                      <GreenSwitch color="default" edge="start" checked={item.checked} />
                    ) : (
                      <GreenCheckbox color="default" edge="start" checked={item.checked} />
                    )}
                  </ListItemIcon>
                )
              )}
              <ListItemText
                id={labelId}
                primary={
                  <Typography
                    className={
                      item.checked && checkedPrimaryIcon === "checkbox"
                        ? classes.listItemTextChecked
                        : classes.listItemText
                    }
                    variant="subtitle2"
                  >
                    {item.primary}
                  </Typography>
                }
                secondary={item.secondary}
              />
              {listItemSecondaryIcon && (
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="right-icon" onClick={() => listItemSecondaryAction?.(item.id)}>
                    {listItemSecondaryIcon}
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            {item.customComponent}
            {useDivider && <Divider />}
          </div>
        );
      })}
    </List>
  );
};

export default AppList;

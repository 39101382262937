import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import AppAccordion, { AppAccordionItem } from "../../../components/AppAccordion/AppAccordion";
import AppCard from "../../../components/AppCard/AppCard";
import { Faq } from "../../../utils/types";
import FaqsStyles from "./FaqsStyle";

export interface FaqsProps {
  faqs: Faq[];
  faqsHasChanged: boolean;
  setFaqsContent: () => void;
}

const Faqs: React.FunctionComponent<FaqsProps> = ({ faqs, faqsHasChanged, setFaqsContent }) => {
  useEffect(() => {
    if (faqsHasChanged) {
      setFaqsContent();
    }
  }, [setFaqsContent, faqsHasChanged]);
  const classes = FaqsStyles();

  const accordionItems: AppAccordionItem[] = faqs.map((faq) => ({
    heading: faq.header,
    secondaryHeading: faq.title,
    details: (
      <Typography className={classes.faqsText} variant="body2">
        {faq.body}
      </Typography>
    ),
  }));

  const faqsList = <AppAccordion accordionItems={accordionItems} />;

  return (
    <section className={classes.root}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
          <div className={classes.faqsCard}>
            <AppCard content={faqsList} />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default Faqs;

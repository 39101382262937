import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors, FontWeight } from "../../../../utils/enums";

const ActivityDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    activityDialogWrap: {
      minWidth: 300,
      padding: theme.spacing(0, 3),
    },
    dialogHeaderText: {
      paddingBottom: theme.spacing(3),
      fontWeight: 500,
    },
    targetInputMeasure: {
      display: "flex",
    },
    expectedMeaure: {
      flex: 1,
      paddingRight: theme.spacing(1),
    },
    currentMeaure: {
      flex: 1,
      paddingLeft: theme.spacing(1),
    },
    inputField: {
      width: "100%",
      paddingBottom: theme.spacing(3),
    },
    actionButtons: {
      display: "flex",
    },
    actionButtonsLeft: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-start",
    },
    deleteButton: {
      margin: theme.spacing(0, 1),
      color: AppColors["grey-dark"],
      "&:hover": {
        color: theme.palette.error.main,
      },
    },
    actionButtonsRight: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
    },
    cancelButton: {
      fontWeight: 500,
      margin: theme.spacing(0, 0.5),
      backgroundColor: AppColors["grey-light"],
      color: AppColors["grey-dark"],
      "&:hover": {
        backgroundColor: AppColors["grey-light"],
        color: AppColors["grey-dark"],
      },
    },
    saveButton: {
      fontWeight: FontWeight.bold,
      margin: theme.spacing(0, 0.5),
    },
    acitivityComplete: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: theme.spacing(3),
    },
    activityCompleteText: {
      paddingRight: theme.spacing(2),
    },
  })
);

export default ActivityDialogStyles;

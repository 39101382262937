import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { closeSnackbar } from "../../../store/areas/snackbar/actions";
import { RootState } from "../../../store/rootReducer";
import SnackbarDisplay, { SnackbarDisplayProps } from "./SnackbarDisplay";

const mapStateToProps = (state: RootState): Partial<SnackbarDisplayProps> => ({
  open: state.Snackbar.open,
  message: state.Snackbar.message,
  variant: state.Snackbar.variant,
});

const mapDispatchToProps = (dispatch: any) => ({
  closeSnackbar: () => dispatch(closeSnackbar()),
});

const SnackbarDisplayContainer = compose(connect(mapStateToProps, mapDispatchToProps))(
  SnackbarDisplay as React.ComponentType<any>
);

export default SnackbarDisplayContainer;

import { Button, IconButton, MenuItem, TextField, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import AppDatePicker from "../../../../components/AppDatePicker/AppDatePicker";
import { Activity, Target } from "../../../../utils/types";
import ActivityDialogStyles from "./ActivityDialogStyle";
import DeleteIcon from "@material-ui/icons/Delete";
import { ActivityDialogAction } from "../../../../utils/enums";
import { UNIT_OF_MEASURE } from "../../../../utils/constants";
import { GreenSwitch } from "../../../../components/AppList/AppList";

export interface ActivityDialogProps {
  currentActivity: Activity;
  target: Target;
  createActivity: (activity: Activity) => void;
  updateActivity: (activity: Activity) => void;
  deleteActivity: (activityId: string) => void;
  activityDialogAction: ActivityDialogAction;
  handleClose: () => void;
}

const ActivityDialog: React.FunctionComponent<ActivityDialogProps> = ({
  currentActivity,
  target,
  createActivity,
  updateActivity,
  deleteActivity,
  activityDialogAction,
  handleClose,
}) => {
  const classes = ActivityDialogStyles();
  const [activity, setActivity] = React.useState<Activity>(currentActivity);

  const activeTargets = target?.targets?.filter((target) => target.active) ?? [];

  const invalidState = () => {
    if (activity.target) {
      return activityDialogAction === ActivityDialogAction.complete
        ? activity.name.length < 3 || !activity.date || activity.expectedMeasure <= 0 || activity.currentMeasure <= 0
        : activity.name.length < 3 || !activity.date || activity.expectedMeasure <= 0;
    }

    return activity.name.length < 3 || !activity.date;
  };

  const dataHasNotChanged = () => {
    return (
      (activityDialogAction === ActivityDialogAction.update ||
        activityDialogAction === ActivityDialogAction.complete) &&
      currentActivity.name === activity.name &&
      currentActivity.description === activity.description &&
      currentActivity.date === activity.date &&
      currentActivity.target === activity.target &&
      currentActivity.currentMeasure === activity.currentMeasure &&
      currentActivity.expectedMeasure === activity.expectedMeasure
    );
  };

  return (
    <div className={classes.activityDialogWrap}>
      <Typography className={classes.dialogHeaderText} variant="body1">
        {`${activityDialogAction} Activity`}
      </Typography>
      <TextField
        className={classes.inputField}
        error={activity.name.length < 3}
        id="title"
        label="Title"
        defaultValue={activity.name}
        placeholder="Enter a title for this activity"
        variant="outlined"
        type="text"
        disabled={activityDialogAction === ActivityDialogAction.complete}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setActivity({ ...activity, name: e.target.value })}
      />
      <TextField
        className={classes.inputField}
        id="description"
        label="Description"
        defaultValue={activity.description}
        placeholder="Enter a description for this activity"
        variant="outlined"
        type="text"
        multiline
        rowsMax={3}
        disabled={activityDialogAction === ActivityDialogAction.complete}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setActivity({ ...activity, description: e.target.value })}
      />
      <div className={classes.inputField}>
        <AppDatePicker
          disabled={activityDialogAction === ActivityDialogAction.complete}
          disablePast={activityDialogAction === ActivityDialogAction.create}
          date={activity.date}
          handleDateChange={(newDate: any) => setActivity({ ...activity, date: newDate })}
          label="Completion date"
        />
      </div>

      <>
        <TextField
          className={classes.inputField}
          select
          id="target"
          label="Link this activity to a monthly target"
          value={activity.target ?? ""}
          disabled={activityDialogAction === ActivityDialogAction.complete}
          InputLabelProps={{
            shrink: true,
          }}
          helperText={activity.target ? "Any progress made on this activity will count towards your monthly goal" : ""}
          variant="outlined"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setActivity({ ...activity, target: e.target.value })}
        >
          <MenuItem value="">None</MenuItem>
          {activeTargets.map((target) => (
            <MenuItem key={target.id} value={target.id}>
              {target.name} (measured in {UNIT_OF_MEASURE[target.unitOfMeasure]?.value.toLowerCase()})
            </MenuItem>
          ))}
        </TextField>

        {activity.target && (
          <div className={classes.targetInputMeasure}>
            <div className={classes.expectedMeaure}>
              <TextField
                className={classes.inputField}
                id="expectedMeasure"
                label={
                  "Expected " +
                  UNIT_OF_MEASURE[
                    activeTargets.find((t) => t.id === activity.target)?.unitOfMeasure ?? 0
                  ].value.toLowerCase()
                }
                value={activity.expectedMeasure}
                variant="outlined"
                type="text"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setActivity({
                    ...activity,
                    expectedMeasure:
                      isNaN(Number(e.target.value)) || Number(e.target.value) < 0 ? 0 : Number(e.target.value),
                  })
                }
                error={activity.expectedMeasure <= 0}
              />
            </div>
            <div className={classes.currentMeaure}>
              <TextField
                disabled={activityDialogAction === ActivityDialogAction.create}
                className={classes.inputField}
                id="currentMeasure"
                label={
                  "Completed " +
                  UNIT_OF_MEASURE[
                    activeTargets.find((t) => t.id === activity.target)?.unitOfMeasure ?? 0
                  ].value.toLowerCase()
                }
                value={activity.currentMeasure}
                variant="outlined"
                type="text"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setActivity({
                    ...activity,
                    currentMeasure:
                      isNaN(Number(e.target.value)) || Number(e.target.value) < 0 ? 0 : Number(e.target.value),
                  })
                }
                error={
                  activity.currentMeasure < 0 ||
                  (activityDialogAction === ActivityDialogAction.complete && activity.currentMeasure <= 0)
                }
              />
            </div>
          </div>
        )}
      </>
      {activityDialogAction === ActivityDialogAction.update && !activity.target && (
        <div className={classes.acitivityComplete}>
          <Typography variant="body1" className={classes.activityCompleteText}>
            Complete
          </Typography>
          <GreenSwitch
            checked={activity.currentMeasure > 0}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setActivity({ ...activity, currentMeasure: e.target.checked ? 1 : 0 })
            }
            name="complete"
          />
        </div>
      )}
      <div className={classes.actionButtons}>
        {activityDialogAction === ActivityDialogAction.update && (
          <div className={classes.actionButtonsLeft}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                size="small"
                className={classes.deleteButton}
                onClick={() => {
                  deleteActivity(activity.id);
                  handleClose();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}

        <div className={classes.actionButtonsRight}>
          <Button variant="contained" size="small" className={classes.cancelButton} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={invalidState() || dataHasNotChanged()}
            variant="contained"
            color="primary"
            size="small"
            className={classes.saveButton}
            onClick={
              activityDialogAction === ActivityDialogAction.create
                ? () => {
                    createActivity(activity);
                    handleClose();
                  }
                : () => {
                    updateActivity(activity);
                    handleClose();
                  }
            }
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ActivityDialog;

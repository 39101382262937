import { Grid, Typography } from "@material-ui/core";
import React from "react";
import FeaturesStyles from "./FeaturesStyle";
import schedule from "../../../assets/images/illustrations/schedule.svg";
import measure from "../../../assets/images/illustrations/measure.svg";
import visualReport from "../../../assets/images/illustrations/visual-report.svg";
import accountability from "../../../assets/images/illustrations/accountability.svg";
import focus from "../../../assets/images/illustrations/focus.svg";
import readingBook from "../../../assets/images/illustrations/reading-book.svg";

import AppFeature from "../../../components/AppFeature/AppFeature";

export interface FeaturesProps {}

const Features: React.FunctionComponent<FeaturesProps> = () => {
  const classes = FeaturesStyles();
  return (
    <section className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={12} md={12} className={classes.featureDescription}>
          <Typography className={classes.title} variant="h6" gutterBottom>
            What is Daily Schema?
          </Typography>
          <Typography variant="subtitle1" className={classes.subTitle}>
            Daily Schema is a unique platform that aims to help individuals develop better habits and take actions aimed
            at self development, improvement and fulfilment.
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <AppFeature
            title={"Declutter your days"}
            subTitle={
              "An unplanned day leaves a lot to chance so it's important to clearly define your priorities. Daily Schema helps you outline what you would like to accomplish each day and designs a system that works for you."
            }
            imagePath={schedule}
            altText="schedule"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AppFeature
            title={"Set measurable targets"}
            subTitle={
              "Setting goals won't work without measurable targets. With Daily Schema you can calibrate quantifiable objectives for your goals and deposit the necessary actions required to achieve them over time."
            }
            imagePath={measure}
            altText="measure"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AppFeature
            title={"Track your progress"}
            subTitle={
              "Without the relevant data, it becomes very difficult to identify areas of improvement. Daily Schema helps you track your performance on your targets over time to help identify your stronger and weaker points."
            }
            imagePath={visualReport}
            altText="visual report"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AppFeature
            title={"Develop accountability"}
            subTitle={
              "You're more likely to get something done if you set aside a specific time for it. Schedule your tasks with Daily Schema and set up accountability partners to get progress updates on your selected targets. (coming soon)"
            }
            imagePath={accountability}
            altText="accountability"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AppFeature
            title={"Form good habits"}
            subTitle={
              "Forming a new habit takes time and requires consistent action. Daily Schema can help steer you towards developing new habits by taking repeated actions over time until it becomes second nature."
            }
            imagePath={readingBook}
            altText="reading book"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AppFeature
            title={"Boost productivity"}
            subTitle={
              "By reducing the amount of noise in your everyday life, there becomes a clearer sense of priorities. This helps you become more focused, develop a greater sense of purpose and improve productivity in various aspects."
            }
            imagePath={focus}
            altText="focus"
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default Features;

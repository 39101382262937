import { Plans, Unit } from "./enums";
import { KeyValue } from "./types";

export const SUBSCRIPTION_PLANS: KeyValue = {
  0: {
    id: 0,
    value: Plans.free,
    description: "With this plan, you get 5 activities per day and 3 targets per month.",
  },
  1: {
    id: 1,
    value: Plans.pro,
    description: "With this plan, you have access to unlimited activities and targets.",
  },
};

export const UNIT_OF_MEASURE: KeyValue = {
  0: {
    id: 0,
    value: Unit.rep,
  },
  1: {
    id: 1,
    value: Unit.time,
  },
};

export const DAYS: KeyValue = {
  0: {
    id: 0,
    value: "Sunday",
  },
  1: {
    id: 1,
    value: "Monday",
  },
  2: {
    id: 2,
    value: "Tuesday",
  },
  3: {
    id: 3,
    value: "Wednesday",
  },
  4: {
    id: 4,
    value: "Thursday",
  },
  5: {
    id: 5,
    value: "Friday",
  },
  6: {
    id: 6,
    value: "Saturday",
  },
};

export const MONTHS: KeyValue = {
  0: {
    id: 0,
    value: "January",
  },
  1: {
    id: 1,
    value: "February",
  },
  2: {
    id: 2,
    value: "March",
  },
  3: {
    id: 3,
    value: "April",
  },
  4: {
    id: 4,
    value: "May",
  },
  5: {
    id: 5,
    value: "June",
  },
  6: {
    id: 6,
    value: "July",
  },
  7: {
    id: 7,
    value: "August",
  },
  8: {
    id: 8,
    value: "September",
  },
  9: {
    id: 9,
    value: "October",
  },
  10: {
    id: 10,
    value: "November",
  },
  11: {
    id: 11,
    value: "December",
  },
};

import { Typography } from "@material-ui/core";
import React from "react";
import { PolarAngleAxis, RadialBarChart, RadialBar, Legend } from "recharts";
import { Activity } from "../../../../utils/types";
import ActivityChartStyles from "./ActivityChartStyle";
import { AppColors } from "../../../../utils/enums";

export interface ActivityChartProps {
  activities: Activity[];
}

const ActivityChart: React.FunctionComponent<ActivityChartProps> = ({ activities }) => {
  const classes = ActivityChartStyles();

  const totalActivities = activities.length;
  const completeOverall = activities.filter((activity) => activity.completed);
  const targetsOverall = activities.filter((activity) => activity.target);
  const completeWithTargets = completeOverall.filter((activity) => activity.target);

  const percentageComplete = ((completeOverall.length / totalActivities) * 100).toFixed(0);

  const data = [
    {
      name: `Complete (Targets only) - ${completeWithTargets.length}`,
      value: completeWithTargets.length,
      fill: AppColors.main,
    },
    { name: `Complete (All) - ${completeOverall.length}`, value: completeOverall.length, fill: AppColors.complete },
  ];

  return (
    <div className={classes.activityChartWrap}>
      <Typography className={classes.activityChartHeaderTitle} variant="h6">
        Performance
      </Typography>

      {totalActivities === 0 && (
        <div className={classes.activityChartNoDataContainer}>
          <Typography className={classes.activityChartNoDataText}>No data available</Typography>
        </div>
      )}

      {totalActivities > 0 && (
        <div className={classes.activityChart}>
          <RadialBarChart
            width={300}
            height={300}
            innerRadius={100}
            outerRadius={150}
            startAngle={90}
            endAngle={450}
            data={data}
          >
            <PolarAngleAxis
              type="number"
              domain={[0, targetsOverall.length]}
              dataKey={"value"}
              angleAxisId={0}
              tick={false}
            />
            <PolarAngleAxis
              type="number"
              domain={[0, totalActivities]}
              dataKey={"value"}
              angleAxisId={1}
              tick={false}
            />
            <text className={classes.activityChartLabelHeader} x="50%" y="45%" textAnchor="middle">
              Total Progress
            </text>
            <text className={classes.activityChartLabelBody} x="50%" y="55%" textAnchor="middle">
              {percentageComplete}%
            </text>
            <RadialBar dataKey="value" data={[data[0]]} background angleAxisId={0} />
            <RadialBar dataKey="value" data={[data[1]]} background angleAxisId={1} />
            <Legend
              layout="vertical"
              align="center"
              verticalAlign="bottom"
              height={20}
              iconType="circle"
              iconSize={8}
            />
          </RadialBarChart>
        </div>
      )}
    </div>
  );
};

export default ActivityChart;

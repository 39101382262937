import { Grid } from "@material-ui/core";
import React from "react";
import AppCard from "../../../components/AppCard/AppCard";
import NotificationsContainer from "./Notifications/NotificationsContainer";
import SettingsStyles from "./SettingsStyle";
import SubscriptionContainer from "./Subscription/SubscriptionContainer";
import Transactions from "./Transactions/Transactions";

export interface SettingsProps {}

const Settings: React.FunctionComponent<SettingsProps> = () => {
  const classes = SettingsStyles();

  const subscription = <SubscriptionContainer />;
  const notifications = <NotificationsContainer />;
  const transactions = <Transactions />;
  return (
    <section className={classes.root}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
          <div className={classes.subscriptionCard}>
            <AppCard content={subscription} />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
          <div className={classes.notificationsCard}>
            <AppCard content={notifications} />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
          <div className={classes.transactionsCard}>
            <AppCard content={transactions} />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default Settings;

import { ClickAwayListener, Typography } from "@material-ui/core";
import React from "react";
import LoginDialogStyles from "./LoginDialogStyle";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/app";
import logo from "../../../assets/images/logos/dailySchema.png";

export interface LoginDialogProps {
  handleClose: () => void;
  uiConfig: any;
}

const LoginDialog: React.FunctionComponent<LoginDialogProps> = ({ handleClose, uiConfig }) => {
  const classes = LoginDialogStyles();

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={classes.loginDialogWrap}>
        <img alt={"logo"} className={classes.logo} src={logo} />
        <Typography className={classes.dialogHeaderText} variant="body1">
          Sign in to Daily Schema
        </Typography>

        <div className={classes.actionButtons}>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default LoginDialog;

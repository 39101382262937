import { Reducer } from "@reduxjs/toolkit";
import { RESET_CUSTOMER_SUBSCRIPTION_VERIFIED, VERIFY_CUSTOMER_SUBSCRIPTION_SUCCESS } from "./actionTypes";

export interface SettingsState {
  customerSubscriptionVerified: boolean;
  clientSecret: string;
  displayPrice: number;
}

export const initialState: SettingsState = {
  customerSubscriptionVerified: false,
  clientSecret: "",
  displayPrice: 0,
};

export const SettingsReducer: Reducer<SettingsState> = (state = initialState, action: any) => {
  switch (action.type) {
    case VERIFY_CUSTOMER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        clientSecret: action.clientSecret,
        displayPrice: action.displayPrice,
        customerSubscriptionVerified: true,
      };
    case RESET_CUSTOMER_SUBSCRIPTION_VERIFIED:
      return {
        ...state,
        customerSubscriptionVerified: false,
      };
    default:
      return state;
  }
};

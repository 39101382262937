import { AnyAction } from "@reduxjs/toolkit";
import { Activity, DaysObject } from "../../../utils/types";
import {
  GET_DAYS_BY_DATE_RANGE,
  GET_DAYS_BY_DATE_RANGE_SUCCESS,
  GET_DAYS_BY_DATE_RANGE_FAILURE,
  CREATE_ACTIVITY,
  CREATE_ACTIVITY_FAILURE,
  CREATE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_FAILURE,
  DELETE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_FAILURE,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_DAY_SUMMARY,
  UPDATE_DAY_SUMMARY_SUCCESS,
  UPDATE_DAY_SUMMARY_FAILURE,
  RESET_DAY_HAS_CHANGED,
} from "./actionTypes";

// GET_DAYS_BY_DATE_RANGE

export const getDaysByDateRange = (startDate: string, endDate: string): AnyAction => {
  return {
    type: GET_DAYS_BY_DATE_RANGE,
    startDate,
    endDate,
  };
};

export const getDaysByDateRangeSuccess = (startDate: string, endDate: string, days: DaysObject): AnyAction => {
  return {
    type: GET_DAYS_BY_DATE_RANGE_SUCCESS,
    startDate,
    endDate,
    days,
  };
};

export const getDaysByDateRangeFailure = (message?: string): AnyAction => {
  return {
    type: GET_DAYS_BY_DATE_RANGE_FAILURE,
    message: message || "Unable to retrieve data, please try again later",
  };
};

// CREATE_ACTIVITY

export const createActivity = (activity: Activity): AnyAction => {
  return {
    type: CREATE_ACTIVITY,
    activity,
  };
};

export const createActivitySuccess = (days: DaysObject): AnyAction => {
  return {
    type: CREATE_ACTIVITY_SUCCESS,
    days,
    message: "Successfully created activity!",
  };
};

export const createActivityFailure = (message?: string): AnyAction => {
  return {
    type: CREATE_ACTIVITY_FAILURE,
    message: message || "Unable to create activity, please try again later",
  };
};

// UPDATE_ACTIVITY

export const updateActivity = (activity: Activity, dayObjectKey: string): AnyAction => {
  return {
    type: UPDATE_ACTIVITY,
    activity,
    dayObjectKey,
  };
};

export const updateActivitySuccess = (days: DaysObject): AnyAction => {
  return {
    type: UPDATE_ACTIVITY_SUCCESS,
    days,
    message: "Successfully updated activity!",
  };
};

export const updateActivityFailure = (message?: string): AnyAction => {
  return {
    type: UPDATE_ACTIVITY_FAILURE,
    message: message || "Unable to update activity, please try again later",
  };
};

// DELETE_ACTIVITY

export const deleteActivity = (activityId: string, dayObjectKey: string): AnyAction => {
  return {
    type: DELETE_ACTIVITY,
    activityId,
    dayObjectKey,
  };
};

export const deleteActivitySuccess = (days: DaysObject): AnyAction => {
  return {
    type: DELETE_ACTIVITY_SUCCESS,
    days,
    message: "Successfully deleted activity!",
  };
};

export const deleteActivityFailure = (message?: string): AnyAction => {
  return {
    type: DELETE_ACTIVITY_FAILURE,
    message: message || "Unable to delete activity, please try again later",
  };
};

// UPDATE_DAY_SUMMARY

export const updateDaySummary = (summary: string, dayObjectKey: string): AnyAction => {
  return {
    type: UPDATE_DAY_SUMMARY,
    summary,
    dayObjectKey,
  };
};

export const updateDaySummarySuccess = (days: DaysObject): AnyAction => {
  return {
    type: UPDATE_DAY_SUMMARY_SUCCESS,
    days,
    message: "Successfully updated daily journal!",
  };
};

export const updateDaySummaryFailure = (message?: string): AnyAction => {
  return {
    type: UPDATE_DAY_SUMMARY_FAILURE,
    message: message || "Unable to update daily journal, please try again later",
  };
};

export const resetDayHasChanged = (): AnyAction => {
  return {
    type: RESET_DAY_HAS_CHANGED,
  };
};

import { Button, Typography } from "@material-ui/core";
import React from "react";
import AppList, { AppListItem } from "../../../../components/AppList/AppList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Activity, Target } from "../../../../utils/types";
import ActivityListStyles from "./ActivityListStyle";
import { ActivityDialogAction } from "../../../../utils/enums";
import AppDialog from "../../../../components/AppDialog/AppDialog";
import ActivityDialog from "../../Dialogs/ActivityDialog/ActivityDialog";

export interface ActivityListProps {
  target: Target;
  activities: Activity[];
  createActivity: (activity: Activity) => void;
  updateActivity: (activity: Activity) => void;
  deleteActivity: (activityId: string) => void;
}

const ActivityList: React.FunctionComponent<ActivityListProps> = ({
  target,
  activities,
  createActivity,
  updateActivity,
  deleteActivity,
}) => {
  const newActivity: Activity = {
    id: "",
    name: "",
    description: "",
    user: "",
    day: "",
    month: "",
    date: new Date(),
    previousMeasure: 0,
    currentMeasure: 0,
    expectedMeasure: 1,
    percentageComplete: 0,
    completed: false,
  };

  const classes = ActivityListStyles();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [activity, setActivity] = React.useState<Activity>(newActivity);
  const [action, setAction] = React.useState<ActivityDialogAction>(ActivityDialogAction.create);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const activityList: AppListItem[] = activities.map((activity) => ({
    id: activity.id,
    primary: activity.name,
    secondary: (
      <Typography className={classes.activityListSubtitle} variant="body2">
        {activity?.description}
      </Typography>
    ),
    checked: activity.completed,
  }));

  const handleCreate = () => {
    setActivity(newActivity);
    setAction(ActivityDialogAction.create);
    setOpenDialog(true);
  };

  const handleComplete = (id: string) => {
    const activityFromList = activities.find((activity) => activity.id === id);

    if (activityFromList) {
      if (activityFromList.target) {
        setActivity(activityFromList);
        setAction(ActivityDialogAction.complete);
        setOpenDialog(true);
      } else {
        // handle instant complete for activities without targets
        activityFromList.completed
          ? updateActivity({ ...activityFromList, currentMeasure: 0 })
          : updateActivity({ ...activityFromList, currentMeasure: activityFromList.expectedMeasure });
      }
    }
  };

  const handleUpdate = (id: string) => {
    const activityFromList = activities.find((activity) => activity.id === id);

    if (activityFromList) {
      setActivity(activityFromList);
      setAction(ActivityDialogAction.update);
      setOpenDialog(true);
    }
  };

  return (
    <div className={classes.activityListWrap}>
      <div className={classes.activityListHeader}>
        <Typography className={classes.activityListHeaderTitle} variant="h6">
          Tasks
        </Typography>
        <div className={classes.activityListHeaderButtonContainer}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.activityListHeaderAddButton}
            onClick={handleCreate}
          >
            Create
          </Button>
        </div>
      </div>

      {activityList.length === 0 && (
        <div className={classes.activityListNoDataContainer}>
          <Typography className={classes.activityListNoDataText}>Nothing to display</Typography>
        </div>
      )}

      {activityList.length > 0 && (
        <>
          <AppList
            useCheckedPrimaryIcon
            checkedPrimaryIcon={"checkbox"}
            listItems={activityList}
            listItemPrimaryAction={handleComplete}
            listItemSecondaryIcon={<MoreVertIcon />}
            listItemSecondaryAction={handleUpdate}
          />
        </>
      )}
      <AppDialog
        isOpen={openDialog}
        handleClose={handleDialogClose}
        dialogContent={
          <ActivityDialog
            currentActivity={activity}
            target={target}
            activityDialogAction={action}
            createActivity={createActivity}
            updateActivity={updateActivity}
            deleteActivity={deleteActivity}
            handleClose={handleDialogClose}
          />
        }
      />
    </div>
  );
};

export default ActivityList;

import React from "react";
import SpinnerStyles from "./SpinnerStyle";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface SpinnerDisplayProps {
  loading: boolean;
}

const SpinnerDisplay: React.FunctionComponent<SpinnerDisplayProps> = ({ loading }) => {
  const classes = SpinnerStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default SpinnerDisplay;

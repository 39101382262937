import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import AppCard from "../../../components/AppCard/AppCard";
import TargetsStyles from "./TargetsStyle";
import TargetsListContainer from "./TargetsList/TargetsListContainer";
import TargetsProgressContainer from "./TargetsProgress/TargetsProgressContainer";
import TargetsSummaryContainer from "./TargetsSummary/TargetsSummaryContainer";

export interface TargetsProps {
  targetChanged: boolean;
  dayHasChangedOnOverview: boolean;
  dayHasChangedOnHistory: boolean;
  getMonthByMonthAndYear: (monthId: number, year: number) => void;
  resetDayHasChangedOnOverview: () => void;
  resetDayHasChangedOnHistory: () => void;
}

const Targets: React.FunctionComponent<TargetsProps> = ({
  targetChanged,
  dayHasChangedOnOverview,
  dayHasChangedOnHistory,
  getMonthByMonthAndYear,
  resetDayHasChangedOnOverview,
  resetDayHasChangedOnHistory,
}) => {
  useEffect(() => {
    if (targetChanged || dayHasChangedOnOverview || dayHasChangedOnHistory) {
      const currentDate = new Date();
      getMonthByMonthAndYear(currentDate.getUTCMonth(), currentDate.getUTCFullYear());
      resetDayHasChangedOnHistory();
      // resetDayHasChangedOnOverview() Avoid resetting day has changed on overview if update has not hit calendar yet
    }
  }, [
    getMonthByMonthAndYear,
    dayHasChangedOnOverview,
    dayHasChangedOnHistory,
    targetChanged,
    resetDayHasChangedOnOverview,
    resetDayHasChangedOnHistory,
  ]);

  const classes = TargetsStyles();

  const targetsList = <TargetsListContainer />;
  const targetsSummary = <TargetsSummaryContainer />;
  const targetsProgress = <TargetsProgressContainer />;

  return (
    <section className={classes.root}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
          <div className={classes.targetListCard}>
            <AppCard content={targetsList} />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
          <div className={classes.targetProgressSummaryCard}>
            <AppCard content={targetsSummary} />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
          <div className={classes.targetProgressSummaryCard}>
            <AppCard content={targetsProgress} />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default Targets;

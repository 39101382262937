import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { FontWeight } from "../../utils/enums";

const AppFeaturesStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(1, 5),
    },
    image: {
      marginTop: theme.spacing(2.5),
      height: 120,
    },
    title: {
      marginTop: theme.spacing(2.5),
      textAlign: "center",
      fontWeight: FontWeight.bold,
    },
    subTitle: {
      textAlign: "center",
      marginBottom: theme.spacing(2.5),
    },
  })
);

export default AppFeaturesStyles;

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors, FontWeight } from "../../../../utils/enums";

const CalendarStyles = makeStyles((theme: Theme) =>
  createStyles({
    calendarWrap: {
      flex: 1,
      height: "100%",
    },
    calendarHeader: {
      display: "flex",
      paddingBottom: theme.spacing(2),
    },
    calendarHeaderTitle: {
      flex: 1,
    },
    calendarHeaderAlert: {
      marginBottom: theme.spacing(2),
    },
    calendarHeaderButtonContainer: {
      flex: 1,
    },
    calendarHeaderAddButton: {
      fontWeight: FontWeight.bold,
      float: "right",
    },
    calendar: {
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      "& .fc-toolbar-title": {
        fontSize: "100%",
        fontWeight: FontWeight.bold,
      },
      "& .fc-button-primary": {
        fontWeight: FontWeight.bold,
        backgroundColor: `${AppColors["grey-light"]}!important`,
        color: `${AppColors["grey-dark"]}!important`,
        borderColor: `${theme.palette.common.white}!important`,
        borderRadius: 5,
      },
      "& .fc-button-primary:hover": {
        backgroundColor: `${AppColors.main}!important`,
        color: `${theme.palette.common.white}!important`,
        borderColor: `${theme.palette.common.white}!important`,
        outline: "none !important",
        boxShadow: "none !important",
        borderRadius: 5,
        opacity: 0.5,
      },
      "& .fc-button-active": {
        backgroundColor: `${AppColors.main}!important`,
        color: `${theme.palette.common.white}!important`,
        borderColor: `${theme.palette.common.white}!important`,
        outline: "none !important",
        boxShadow: "none !important",
        borderRadius: 5,
      },
      "& .fc-button-primary:active, .fc-button-primary:focus-visible": {
        backgroundColor: `${AppColors.main}!important`,
        borderColor: `${theme.palette.common.white}!important`,
        outline: "none !important",
        boxShadow: "none !important",
        borderRadius: 5,
      },
      "& .fc-button-primary:focus, .fc-button-primary:focus-visible": {
        outline: "none !important",
        boxShadow: "none !important",
      },
      "& .fc-today-button": {
        backgroundColor: `${AppColors.main}!important`,
        color: `${theme.palette.common.white}!important`,
        borderColor: `${theme.palette.common.white}!important`,
        outline: "none !important",
        boxShadow: "none !important",
        borderRadius: 5,
      },
      "& .fc-today-button:disabled": {
        backgroundColor: `${AppColors["grey-light"]}!important`,
        color: `${AppColors["grey-dark"]}!important`,
        borderColor: `${theme.palette.common.white}!important`,
        outline: "none !important",
        boxShadow: "none !important",
        borderRadius: 5,
      },
    },
  })
);

export default CalendarStyles;

import { Button, TextField, Typography } from "@material-ui/core";
import React from "react";
import DaySummaryDialogStyles from "./DaySummaryStyle";

export interface DaySummaryDialogProps {
  currentDaySummary: string;
  updateDaySummary: (summary: string) => void;
  handleClose: () => void;
}

const DaySummaryDialog: React.FunctionComponent<DaySummaryDialogProps> = ({
  currentDaySummary,
  updateDaySummary,
  handleClose,
}) => {
  const classes = DaySummaryDialogStyles();
  const [daySummary, setDaySummary] = React.useState<string>(currentDaySummary ?? "");

  return (
    <div className={classes.daySummaryDialogWrap}>
      <Typography className={classes.dialogHeaderText} variant="body1">
        {"Update Daily Journal"}
      </Typography>
      <TextField
        className={classes.inputField}
        id="outlined-multiline-static"
        label="Diary"
        multiline
        rows={5}
        placeholder={"Reflect on your day and highlight any noteworthy moments..."}
        defaultValue={currentDaySummary}
        variant="outlined"
        inputProps={{ style: { fontSize: 14 } }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDaySummary(e.target.value)}
      />
      <div className={classes.actionButtons}>
        <div className={classes.actionButtonsRight}>
          <Button variant="contained" size="small" className={classes.cancelButton} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className={classes.saveButton}
            disabled={currentDaySummary === daySummary}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              updateDaySummary(daySummary);
              handleClose();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DaySummaryDialog;

import { createStyles, makeStyles, Theme } from "@material-ui/core";

const HistoryStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    container: {
      display: "flex",
      height: 800,
    },
    gridItem: {},
    calendarCard: {
      display: "flex",
    },
  })
);

export default HistoryStyles;

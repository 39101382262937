import { Day } from "../types";
import { mapToMultipleActivities } from "./activityMapper";

export const mapToSingleDay = (day: any) => {
  const mappedDay: Day = {
    id: day._id,
    user: day.user,
    date: day.date,
    activities: mapToMultipleActivities(day.activities),
    summary: day.summary,
  };

  return mappedDay;
};

export const mapToMultipleDays = (days: any) => {
  const mappedDaysList: Day[] = days.map((day: any) => ({
    id: day._id,
    user: day.user,
    date: day.date,
    activities: mapToMultipleActivities(day.activities),
    summary: day.summary,
  }));

  return mappedDaysList;
};

import { createStyles, LinearProgress, Theme, Typography, withStyles } from "@material-ui/core";
import React from "react";
import { Month } from "../../../../utils/types";
import TargetsProgressStyles from "./TargetsProgressStyle";
import AppList, { AppListItem } from "../../../../components/AppList/AppList";
import { formatRepeatFrequency } from "../TargetsList/utils";
import { AppColors } from "../../../../utils/enums";

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 7,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: AppColors["grey-light"],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: AppColors.complete,
    },
  })
)(LinearProgress);

const ProgressDisplay = (current: number, expected: number, unit: number, progress: number, classes: any) => {
  const displayCurrent = formatRepeatFrequency(current, unit);
  const displayUnit = formatRepeatFrequency(expected, unit);
  return (
    <div className={classes.progressDisplayContainer}>
      <Typography variant="body2" className={classes.progressDisplayText}>
        {displayCurrent} out of {displayUnit} completed
      </Typography>
      <BorderLinearProgress variant="determinate" value={progress} />
    </div>
  );
};

export interface TargetsProgressProps {
  month: Month;
}

const TargetsProgress: React.FunctionComponent<TargetsProgressProps> = ({ month }) => {
  const classes = TargetsProgressStyles();

  const monthTargets = month.monthTargets?.filter((mt) => !mt.deleted) ?? [];
  const totalTargets = monthTargets.length;

  const monthTargetsList: AppListItem[] = monthTargets.map((target) => ({
    id: target.id,
    primary: target.name,
    customComponent: ProgressDisplay(
      target.currentMeasure,
      target.expectedMeasure,
      target.unitOfMeasure,
      target.percentageComplete,
      classes
    ),
  }));

  return (
    <div className={classes.targetsProgressWrap}>
      <Typography className={classes.targetsProgressHeaderTitle} variant="h6">
        Progress
      </Typography>

      {totalTargets === 0 && (
        <div className={classes.targetsProgressNoDataContainer}>
          <Typography className={classes.targetsProgressNoDataText}>No data available</Typography>
        </div>
      )}

      {totalTargets > 0 && (
        <div className={classes.targetsProgressList}>
          <AppList listItems={monthTargetsList} useDivider />
        </div>
      )}
    </div>
  );
};

export default TargetsProgress;

import { createStyles, makeStyles, Theme } from "@material-ui/core";

const OverviewStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    container: {
      display: "flex",
    },
    gridItem: {
      width: 350,
    },
    upperCards: {
      display: "flex",
      height: 250,
    },
    middleCards: {
      display: "flex",
      height: 425,
    },
    lowerCards: {
      display: "flex",
    },
  })
);

export default OverviewStyles;

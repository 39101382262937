import { Month, MonthTarget } from "../types";

export const mapToMultipleMonthTargets = (monthTargets: any) => {
  const mappedMonthTargets: MonthTarget[] = monthTargets.map((monthTarget: any) => ({
    id: monthTarget._id,
    name: monthTarget.name,
    unitOfMeasure: monthTarget.unitOfMeasure,
    currentMeasure: monthTarget.currentMeasure,
    percentageComplete: monthTarget.percentageComplete,
    expectedMeasure: monthTarget.expectedMeasure,
    active: monthTarget.active,
    deleted: monthTarget.deleted,
  }));

  return mappedMonthTargets;
};

export const mapToSingleMonth = (month: any) => {
  const mappedMonth: Month = {
    id: month._id,
    month: month.month,
    year: month.year,
    user: month.user,
    monthTargets: mapToMultipleMonthTargets(month.monthTargets),
  };
  return mappedMonth;
};

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors } from "../../../../utils/enums";

const TargetsProgressStyles = makeStyles((theme: Theme) =>
  createStyles({
    targetsProgressWrap: {
      flex: 1,
      height: "100%",
    },
    targetsProgressHeaderTitle: {
      display: "flex",
    },
    targetsProgressList: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      height: 400,
    },
    targetsProgressNoDataContainer: {
      display: "flex",
      height: "80%",
      justifyContent: "center",
      alignItems: "center",
    },
    targetsProgressNoDataText: {
      flex: 1,
      textAlign: "center",
      fontSize: 14,
      color: AppColors["grey-dark"],
    },
    progressDisplayContainer: {
      padding: theme.spacing(1, 2),
    },
    progressDisplayText: {},
  })
);

export default TargetsProgressStyles;

import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppAccordionStyles from "./AppAccordionStyle";
import { Divider } from "@material-ui/core";

export interface AppAccordionItem {
  heading: string;
  secondaryHeading?: string;
  details?: JSX.Element;
}

export interface AppAccordionProps {
  accordionItems: AppAccordionItem[];
  useDivider?: boolean;
}

const AppAccordion: React.FunctionComponent<AppAccordionProps> = ({ accordionItems, useDivider }) => {
  const classes = AppAccordionStyles();

  return (
    <div className={classes.root}>
      {accordionItems.map((item) => {
        return (
          <Accordion
            key={item.heading}
            elevation={0}
            classes={{ expanded: classes.expanded, root: classes.accordion }}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
              <Typography variant="subtitle2" className={classes.heading}>
                {item.heading}
              </Typography>
              <Typography variant="body2" className={classes.secondaryHeading}>
                {item.secondaryHeading}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{item.details}</AccordionDetails>
            {useDivider && <Divider />}
          </Accordion>
        );
      })}
    </div>
  );
};

export default AppAccordion;

import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { RootState } from "../../../../store/rootReducer";
import TargetsSummary, { TargetsSummaryProps } from "./TargetsSummary";

const mapStateToProps = (state: RootState): Partial<TargetsSummaryProps> => ({
  month: state.Targets.month,
});

const mapDispatchToProps = (dispatch: any) => ({});

const TargetsSummaryContainer = compose(connect(mapStateToProps, mapDispatchToProps))(
  TargetsSummary as React.ComponentType<any>
);

export default TargetsSummaryContainer;

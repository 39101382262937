import { createStore, applyMiddleware, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import { ENABLE_REDUX_DEV } from "../config/config";

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware();

  let store: Store;
  if (ENABLE_REDUX_DEV) {
    store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
  } else {
    store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
  }

  sagaMiddleware.run(rootSaga);

  return store;
}

import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import History, { HistoryProps } from "./History";

const mapStateToProps = (state: RootState): Partial<HistoryProps> => ({});

const mapDispatchToProps = (dispatch: any) => ({});

const HistoryContainer = compose(connect(mapStateToProps, mapDispatchToProps))(History as React.ComponentType<any>);

export default HistoryContainer;

import { put, takeLatest, call } from "redux-saga/effects";
import {
  CREATE_ACTIVITY,
  DELETE_ACTIVITY,
  GET_DAYS_BY_DATE_RANGE,
  UPDATE_ACTIVITY,
  UPDATE_DAY_SUMMARY,
} from "./actionTypes";
import { Activity, Day, DaysObject } from "../../../utils/types";
import { fetchData } from "../../../utils/httpUtils";
import { ApiResponseStatus, EndpointActions, Endpoints, httpMethod } from "../../../utils/enums";
import { API_URL } from "../../../config/config";
import { mapToMultipleDays, mapToSingleDay } from "../../../utils/mappers/dayMapper";
import {
  createActivityFailure,
  createActivitySuccess,
  deleteActivityFailure,
  deleteActivitySuccess,
  getDaysByDateRangeFailure,
  getDaysByDateRangeSuccess,
  updateActivityFailure,
  updateActivitySuccess,
  updateDaySummaryFailure,
  updateDaySummarySuccess,
} from "./actions";
import { arrayToObjectWithDateKey, formatDateString } from "../../../utils/extensions";
import { store } from "../../../App";
import { mapToSingleActivity } from "../../../utils/mappers/activityMapper";
import { RootState } from "../../rootReducer";

export function* getDaysByDateRangeSaga(action: any) {
  try {
    const { startDate, endDate }: { startDate: string; endDate: string } = action;
    const url = API_URL + Endpoints.daysByDateRange;
    const body = { startDate, endDate };

    const { status, data } = yield call(fetchData, url, httpMethod.post, body);

    if (status === ApiResponseStatus.success) {
      const days: Day[] = mapToMultipleDays(data);

      const daysObject: DaysObject = arrayToObjectWithDateKey(days, "date");

      yield put(getDaysByDateRangeSuccess(startDate, endDate, daysObject));
    } else {
      yield put(getDaysByDateRangeFailure());
    }
  } catch (error) {
    yield put(getDaysByDateRangeFailure());
  }
}

export function* createActivitySaga(action: any) {
  try {
    const { activity }: { activity: Activity } = action;

    const url = API_URL + Endpoints.activity + EndpointActions.create;

    const body: any = {
      date: activity.date,
      name: activity.name,
      expectedMeasure: activity.expectedMeasure,
    };
    if (activity.target) body.targetId = activity.target;
    if (activity.description) body.description = activity.description;
    if (activity.reminder) body.reminder = activity.reminder;

    const { status, data, message } = yield call(fetchData, url, httpMethod.post, body);

    if (status === ApiResponseStatus.success) {
      const createdActivity: Activity = mapToSingleActivity(data);

      const formattedDateString = formatDateString(createdActivity.date);

      const state: RootState = store.getState();

      let updatedDaysObject: DaysObject;

      let days = state.History.days;

      // If existing day then add new activity to the day
      if (days[formattedDateString]) {
        updatedDaysObject = {
          ...days,
          [formattedDateString]: {
            ...days[formattedDateString],
            activities: [...(days[formattedDateString].activities ?? []), createdActivity],
          },
        };
        // Create new day for activity if none exists
      } else {
        const newDayObject: DaysObject = {
          [formattedDateString]: {
            id: createdActivity.day,
            date: createdActivity.date,
            user: createdActivity.user,
            activities: [createdActivity],
          },
        };
        updatedDaysObject = { ...state.History.days, ...newDayObject };
      }

      yield put(createActivitySuccess(updatedDaysObject));
    } else {
      yield put(createActivityFailure(message));
    }
  } catch (error) {
    yield put(createActivityFailure());
  }
}

export function* updateActivitySaga(action: any) {
  try {
    const { activity, dayObjectKey }: { activity: Activity; dayObjectKey: string } = action;

    const state: RootState = store.getState();
    let days = state.History.days;

    const activityFromState = days[dayObjectKey].activities?.find((a) => a.id === activity.id);

    if (activityFromState) {
      const url = API_URL + Endpoints.activity + activity.id;
      const body: any = {};

      if (activityFromState?.target !== activity.target) body.targetId = activity.target;
      if (activityFromState?.date !== activity.date) body.date = activity.date;
      if (activityFromState?.name !== activity.name) body.name = activity.name;
      if (activityFromState?.description !== activity.description) body.description = activity.description;
      if (activityFromState?.currentMeasure !== activity.currentMeasure) body.currentMeasure = activity.currentMeasure;
      if (activityFromState?.expectedMeasure !== activity.expectedMeasure)
        body.expectedMeasure = activity.expectedMeasure;
      if (activityFromState?.reminder !== activity.reminder) body.reminder = activity.reminder;

      const { status, data, message } = yield call(fetchData, url, httpMethod.patch, body);

      if (status === ApiResponseStatus.success) {
        const updatedActivity: Activity = mapToSingleActivity(data);

        let updatedDaysObject: DaysObject;

        if (activityFromState.day === updatedActivity.day) {
          // Same day so remove and re add
          const filteredActivityList = days[dayObjectKey].activities?.filter((a) => a.id !== updatedActivity.id) ?? [];

          updatedDaysObject = {
            ...days,
            [dayObjectKey]: {
              ...days[dayObjectKey],
              activities: [...filteredActivityList, updatedActivity],
            },
          };
        } else {
          // Different day so remove from previously linked day
          const filteredActivityList = days[dayObjectKey].activities?.filter((a) => a.id !== updatedActivity.id) ?? [];

          updatedDaysObject = {
            ...days,
            [dayObjectKey]: {
              ...days[dayObjectKey],
              activities: filteredActivityList,
            },
          };

          const updatedFormattedDateString = formatDateString(updatedActivity.date);

          // Different day so add to new or existing day
          if (updatedDaysObject[updatedFormattedDateString]) {
            updatedDaysObject = {
              ...updatedDaysObject,
              [updatedFormattedDateString]: {
                ...updatedDaysObject[updatedFormattedDateString],
                activities: [...(updatedDaysObject[updatedFormattedDateString].activities ?? []), updatedActivity],
              },
            };
          } else {
            const newDayObject: DaysObject = {
              [updatedFormattedDateString]: {
                id: updatedActivity.day,
                date: updatedActivity.date,
                user: updatedActivity.user,
                activities: [updatedActivity],
              },
            };
            updatedDaysObject = { ...updatedDaysObject, ...newDayObject };
          }
        }

        yield put(updateActivitySuccess(updatedDaysObject));
      } else {
        yield put(updateActivityFailure(message));
      }
    } else {
      yield put(updateActivityFailure("An unexpected error has occured, please try again later"));
    }
  } catch (error) {
    yield put(updateActivityFailure());
  }
}

export function* deleteActivitySaga(action: any) {
  try {
    const { activityId, dayObjectKey }: { activityId: string; dayObjectKey: string } = action;

    const state: RootState = store.getState();

    let days = state.History.days;

    const activityFromState = days[dayObjectKey].activities?.find((a) => a.id === activityId);

    if (activityFromState) {
      const url = API_URL + Endpoints.activity + activityId;

      const { status, message } = yield call(fetchData, url, httpMethod.delete);

      if (status === ApiResponseStatus.success) {
        let updatedDaysObject: DaysObject;

        const filteredActivityList = days[dayObjectKey].activities?.filter((a) => a.id !== activityId) ?? [];

        updatedDaysObject = {
          ...days,
          [dayObjectKey]: {
            ...days[dayObjectKey],
            activities: [...filteredActivityList],
          },
        };

        yield put(deleteActivitySuccess(updatedDaysObject));
      } else {
        yield put(deleteActivityFailure(message));
      }
    } else {
      yield put(deleteActivityFailure("An unexpected error has occured, please try again later"));
    }
  } catch (error) {
    yield put(deleteActivityFailure());
  }
}

export function* updateDaySummarySaga(action: any) {
  try {
    const { summary, dayObjectKey }: { summary: string; dayObjectKey: string } = action;

    const state: RootState = store.getState();

    let days = state.History.days;

    const day = days[dayObjectKey];

    if (day) {
      const url = API_URL + Endpoints.day + day.id;

      const body = { summary: summary };

      const { status } = yield call(fetchData, url, httpMethod.patch, body);
      if (status === ApiResponseStatus.success) {
        let updatedDaysObject: DaysObject;

        updatedDaysObject = {
          ...days,
          [dayObjectKey]: {
            ...days[dayObjectKey],
            summary: summary,
          },
        };

        yield put(updateDaySummarySuccess(updatedDaysObject));
      } else {
        yield put(updateDaySummaryFailure());
      }
    } else {
      const url = API_URL + Endpoints.day + EndpointActions.create;

      const body = { date: new Date(dayObjectKey), summary: summary };

      const { status, data } = yield call(fetchData, url, httpMethod.post, body);

      if (status === ApiResponseStatus.success) {
        const createdDay: Day = mapToSingleDay(data);

        let updatedDaysObject: DaysObject;

        const newDayObject: DaysObject = {
          [dayObjectKey]: {
            id: createdDay.id,
            date: createdDay.date,
            user: createdDay.user,
            activities: createdDay.activities,
            summary: createdDay.summary,
          },
        };
        updatedDaysObject = { ...state.History.days, ...newDayObject };

        yield put(updateDaySummarySuccess(updatedDaysObject));
      } else {
        yield put(updateDaySummaryFailure());
      }
    }
  } catch (error) {
    yield put(updateDaySummaryFailure());
  }
}

export function* historyWatcher() {
  yield takeLatest(GET_DAYS_BY_DATE_RANGE, getDaysByDateRangeSaga);
  yield takeLatest(CREATE_ACTIVITY, createActivitySaga);
  yield takeLatest(UPDATE_ACTIVITY, updateActivitySaga);
  yield takeLatest(DELETE_ACTIVITY, deleteActivitySaga);
  yield takeLatest(UPDATE_DAY_SUMMARY, updateDaySummarySaga);
}

import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { RootState } from "../../store/rootReducer";
import Landing, { LandingProps } from "./Landing";

const mapStateToProps = (state: RootState): Partial<LandingProps> => ({
  loginError: state.Snackbar.message,
});

const mapDispatchToProps = (dispatch: any) => ({});

const LandingContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Landing as React.ComponentType<any>);

export default LandingContainer;

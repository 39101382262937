import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { updateUserRituals } from "../../../../store/areas/home/actions";
import { updateDaySummary } from "../../../../store/areas/overview/actions";
import { RootState } from "../../../../store/rootReducer";
import Reflect, { ReflectProps } from "./Reflect";

const mapStateToProps = (state: RootState): Partial<ReflectProps> => ({
  rituals: state.Home.user.rituals,
  summary: state.Overview.day.summary,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateUserRituals: (rituals: string) => dispatch(updateUserRituals(rituals)),
  updateDaySummary: (summary: string) => dispatch(updateDaySummary(summary)),
});

const ReflectContainer = compose(connect(mapStateToProps, mapDispatchToProps))(Reflect as React.ComponentType<any>);

export default ReflectContainer;

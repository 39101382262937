import { Button, Typography } from "@material-ui/core";
import React from "react";
import AppDialog from "../../../../components/AppDialog/AppDialog";
import { Activity, Target } from "../../../../utils/types";
import PlanDialog from "../../Dialogs/PlanDialog/PlanDialog";
import WelcomeStyles from "./WelcomeStyle";

export interface WelcomeProps {
  username: string;
  activityCount: number;
  target: Target;
  createActivitiesFromPlan: (activities: Activity[]) => void;
}

const Welcome: React.FunctionComponent<WelcomeProps> = ({
  username,
  activityCount,
  target,
  createActivitiesFromPlan,
}) => {
  const classes = WelcomeStyles();

  const isPlannedDay = activityCount > 0;

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handlePlan = () => {
    setOpenDialog(true);
  };

  const welcomeMesage = `Welcome back ${username}!`;

  return (
    <div className={classes.welcome}>
      <div className={classes.welcomeLeft}>
        <Typography className={classes.welcomeLeftHeader} variant="h6">
          {welcomeMesage}
        </Typography>
        {isPlannedDay ? (
          <>
            <Typography className={classes.welcomeLeftMessage} variant="body2">
              You're all set up for today so let's make the most of it!
            </Typography>
            <Typography className={classes.welcomeLeftLineUpMessage} variant="body2">
              {activityCount === 1 ? "1 task created" : activityCount + " tasks created"}
            </Typography>
          </>
        ) : (
          <>
            <Typography className={classes.welcomeLeftMessage} variant="body2">
              Nothing yet lined up for today so go ahead and plan your day!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.welcomeLeftButton}
              onClick={handlePlan}
            >
              Plan
            </Button>
          </>
        )}
      </div>
      <div className={isPlannedDay ? classes.welcomeRightPlanned : classes.welcomeRightUnPlanned} />
      <AppDialog
        isOpen={openDialog}
        handleClose={handleDialogClose}
        dialogContent={
          <PlanDialog
            handleClose={handleDialogClose}
            target={target}
            createActivitiesFromPlan={createActivitiesFromPlan}
          />
        }
      />
    </div>
  );
};

export default Welcome;

import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { createActivity, updateActivity, deleteActivity } from "../../../../store/areas/overview/actions";
import { RootState } from "../../../../store/rootReducer";
import { Activity } from "../../../../utils/types";
import ActivityList, { ActivityListProps } from "./ActivityList";

const mapStateToProps = (state: RootState): Partial<ActivityListProps> => ({
  activities: state.Overview.day?.activities,
  target: state.Targets.target,
});

const mapDispatchToProps = (dispatch: any) => ({
  createActivity: (activity: Activity) => dispatch(createActivity(activity)),
  updateActivity: (activity: Activity) => dispatch(updateActivity(activity)),
  deleteActivity: (activityId: string) => dispatch(deleteActivity(activityId)),
});

const ActivityListContainer = compose(connect(mapStateToProps, mapDispatchToProps))(
  ActivityList as React.ComponentType<any>
);

export default ActivityListContainer;

import { AnyAction } from "@reduxjs/toolkit";
import { Activity, Day } from "../../../utils/types";
import {
  CREATE_ACTIVITIES_FROM_PLAN,
  CREATE_ACTIVITIES_FROM_PLAN_FAILURE,
  CREATE_ACTIVITIES_FROM_PLAN_SUCCESS,
  CREATE_ACTIVITY,
  CREATE_ACTIVITY_FAILURE,
  CREATE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_FAILURE,
  DELETE_ACTIVITY_SUCCESS,
  GET_DAY_BY_DATE,
  GET_DAY_BY_DATE_FAILURE,
  GET_DAY_BY_DATE_SUCCESS,
  RESET_DAY_HAS_CHANGED,
  SET_QUOTE,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_FAILURE,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_DAY_SUMMARY,
  UPDATE_DAY_SUMMARY_FAILURE,
  UPDATE_DAY_SUMMARY_SUCCESS,
} from "./actionTypes";

// GET_DAY_BY_DATE

export const getDayByDate = (date: Date): AnyAction => {
  return {
    type: GET_DAY_BY_DATE,
    date,
  };
};

export const getDayByDateSuccess = (day: Day): AnyAction => {
  return {
    type: GET_DAY_BY_DATE_SUCCESS,
    day,
  };
};

export const getDayByDateFailure = (message?: string): AnyAction => {
  return {
    type: GET_DAY_BY_DATE_FAILURE,
    message: message || "Unable to retrieve data, please try again later",
  };
};

// UPDATE_DAY_SUMMARY

export const updateDaySummary = (summary: string): AnyAction => {
  return {
    type: UPDATE_DAY_SUMMARY,
    summary,
  };
};

export const updateDaySummarySuccess = (summary: string): AnyAction => {
  return {
    type: UPDATE_DAY_SUMMARY_SUCCESS,
    summary,
    message: "Successfully updated daily journal!",
  };
};

export const updateDaySummaryFailure = (message?: string): AnyAction => {
  return {
    type: UPDATE_DAY_SUMMARY_FAILURE,
    message: message || "Unable to update daily journal, please try again later",
  };
};

// CREATE_ACTIVITY

export const createActivity = (activity: Activity): AnyAction => {
  return {
    type: CREATE_ACTIVITY,
    activity,
  };
};

export const createActivitySuccess = (activities: Activity[]): AnyAction => {
  return {
    type: CREATE_ACTIVITY_SUCCESS,
    activities,
    message: "Successfully created activity!",
  };
};

export const createActivityFailure = (message?: string): AnyAction => {
  return {
    type: CREATE_ACTIVITY_FAILURE,
    message: message || "Unable to create activity, please try again later",
  };
};

// CREATE_ACTIVITIES_FROM_PLAN

export const createActivitiesFromPlan = (activities: Activity[]): AnyAction => {
  return {
    type: CREATE_ACTIVITIES_FROM_PLAN,
    activities,
  };
};

export const createActivitiesFromPlanSuccess = (activities: Activity[]): AnyAction => {
  return {
    type: CREATE_ACTIVITIES_FROM_PLAN_SUCCESS,
    activities,
    message: "Successfully created activities!",
  };
};

export const createActivitiesFromPlanFailure = (message?: string): AnyAction => {
  return {
    type: CREATE_ACTIVITIES_FROM_PLAN_FAILURE,
    message: message || "An error occured while creating activities, please try again later",
  };
};

// UPDATE_ACTIVITY

export const updateActivity = (activity: Activity): AnyAction => {
  return {
    type: UPDATE_ACTIVITY,
    activity,
  };
};

export const updateActivitySuccess = (activities: Activity[]): AnyAction => {
  return {
    type: UPDATE_ACTIVITY_SUCCESS,
    activities,
    message: "Successfully updated activity!",
  };
};

export const updateActivityFailure = (message?: string): AnyAction => {
  return {
    type: UPDATE_ACTIVITY_FAILURE,
    message: message || "Unable to update activity, please try again later",
  };
};

// DELETE_ACTIVITY

export const deleteActivity = (activityId: string): AnyAction => {
  return {
    type: DELETE_ACTIVITY,
    activityId,
  };
};

export const deleteActivitySuccess = (activities: Activity[]): AnyAction => {
  return {
    type: DELETE_ACTIVITY_SUCCESS,
    activities,
    message: "Successfully deleted activity!",
  };
};

export const deleteActivityFailure = (message?: string): AnyAction => {
  return {
    type: DELETE_ACTIVITY_FAILURE,
    message: message || "Unable to delete activity, please try again later",
  };
};

export const resetDayHasChanged = (): AnyAction => {
  return {
    type: RESET_DAY_HAS_CHANGED,
  };
};

export const setQuote = (): AnyAction => {
  return {
    type: SET_QUOTE,
  };
};

import React from "react";
import AppCardStyles from "./AppCardStyle";
import { Card, CardActionArea, CardContent, CardHeader } from "@material-ui/core";
import defaultBackground from "../../assets/images/background/dark.jpg";

export interface AppCardProps {
  header?: string;
  actionArea?: boolean;
  actionAreaClick?: (item: any) => void;
  actionAreaImage?: string;
  actionAreaImageAlt?: string;
  content?: JSX.Element;
}

const AppCard: React.FunctionComponent<AppCardProps> = ({
  header,
  actionArea,
  actionAreaImage,
  actionAreaImageAlt,
  content,
  actionAreaClick,
}) => {
  const classes = AppCardStyles();

  return (
    <Card className={classes.root} elevation={3}>
      {header && <CardHeader className={classes.cardHeader} disableTypography={true} title={header} />}
      {actionArea ? (
        <CardActionArea className={classes.cardActionArea} onClick={actionAreaClick}>
          <img
            alt={actionAreaImageAlt ?? "default"}
            className={classes.cardActionAreaImage}
            src={actionAreaImage ?? defaultBackground}
          />
          <CardContent className={classes.cardContent}>{content}</CardContent>
        </CardActionArea>
      ) : (
        <CardContent className={classes.cardContent}>{content}</CardContent>
      )}
    </Card>
  );
};

export default AppCard;

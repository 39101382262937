import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppColors, FontWeight } from "../../../../utils/enums";

const TargetsSummaryStyles = makeStyles((theme: Theme) =>
  createStyles({
    targetsSummaryWrap: {
      flex: 1,
      height: "100%",
    },
    targetsSummaryHeaderTitle: {
      display: "flex",
    },
    targetsSummary: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    targetsSummaryChart: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      height: 400,
    },
    targetsSummaryLabelHeader: {
      fontSize: 12,
      fill: AppColors["grey-dark"],
    },
    targetsSummaryLabelBody: {
      fontWeight: FontWeight.bold,
      fontSize: 24,
      fill: AppColors["background-dark"],
    },
    targetsSummaryLabelPeriod: {
      fontWeight: FontWeight.bold,
      fontSize: 14,
      fill: AppColors["background-dark"],
    },
    targetsSummaryNoDataContainer: {
      display: "flex",
      height: "80%",
      justifyContent: "center",
      alignItems: "center",
    },
    targetsSummaryNoDataText: {
      flex: 1,
      textAlign: "center",
      fontSize: 14,
      color: AppColors["grey-dark"],
    },
    SummaryDisplayContainer: {
      padding: theme.spacing(1),
    },
    SummaryDisplayText: {},
  })
);

export default TargetsSummaryStyles;
